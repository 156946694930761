/* Inbox */

.inbox-grid {
    --inbox-top-height: 4.2rem;
    --inbox-details-bottom-height: 5.7333rem;
    position: relative;
    display: grid;
    grid-template-columns: 240px 380px 1fr;
    background-color: var(--background-secondary-color);

    @include media('<xl') {
        grid-template-columns: minmax(340px, 36%) 1fr;

        .sidebar-panel {
            @include sidebarPanelCollapse();
        }
    }

    @include media('<lg-tablet') {
        grid-template-columns: 1fr;
    }

    @include media('<sm-tablet') {
        --inbox-top-height: 3.2rem;
        --inbox-details-bottom-height: 54px;
    }
}

.inbox-mails {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-color: var(--background-primary-color);
    border-right: 1px solid var(--border-grey-color);

    @include media('<lg-tablet') {
        border-right: none;
    }

    .toggle-sidebar {
        align-self: center;
        height: calc(var(--inbox-top-height) - 4px);
        width: calc(var(--inbox-top-height) - 4px);
        margin: 0 10px 0 8px;

        @include media('>sm-tablet') {
            height: calc(var(--inbox-top-height) - 14px);
            width: calc(var(--inbox-top-height) - 14px);
            margin: 0 0 0 5px;
        }

        @include media('>xl') {
            display: none;
        }
    }

    .toggle-search {
        @include media('>sm') {
            display: none;
        }
    }

    &__top {
        position: relative;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        height: var(--inbox-top-height);
        padding-right: 1.1rem;
        border-bottom: 1px solid var(--border-grey-color);
    }

    .module-search {
        flex-grow: 1;
        display: flex;
        margin-right: 8px;

        @include media('>sm') {
            margin-left: 8px;
        }

        @include media('<sm') {
            position: absolute;
            top: 0;
            right: 0;
            left: 48px;
            z-index: 1;
            display: none;
            height: 100%;
            background-color: var(--background-secondary-color);

            &.show {
                display: block;
            }
        }

        .input-group__prepend {
            left: 12px;

            svg[class*="icon"] {
                width: 18px;
                height: 18px;
            }

            @include media('<sm') {
                display: none;
            }
        }

        .input {
            height: 100%;

            @include media('>sm') {
                padding-left: 2.4rem;
            }
        }
    }

    &__sort {
        align-self: center;
        position: relative;
        margin-left: auto;
        white-space: nowrap;

        .dropdown-menu {
            top: 100%!important;
            right: -14px;
            min-width: auto;
            transform: none!important;
        }
    }

    &__sort-arrow {
        display: inline-block;
        vertical-align: middle;
        width: 22px;
        height: 22px;

        svg {
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    &__content {
        height: calc(100vh - var(--header-height) - var(--inbox-top-height));
    }

    &__list {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    &__list-item {
        &:not(:last-child) {
            border-bottom: 1px solid var(--border-grey-color);
        }
    }

    &__item {
        display: flex;
        padding: 1.3333rem 1.6rem;

        @include media('<sm-tablet') {
            padding: 10px;
        }

        &.active {
            background-color: var(--background-secondary-color);
        }
    }

    &__item-avatar {
        flex-shrink: 0;
        position: relative;
        z-index: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.6667rem;
        height: 2.6667rem;
        border-radius: 50%;
        margin-right: 0.8rem;
        font-size: 14px;
        line-height: 1;
        color: var(--white-color);

        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
    }

    &__item-avatar-text {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: -1;
    }

    &__item-right {
        flex-grow: 1;
        display: grid;
        grid-template-columns: auto 1fr auto;
        align-items: center;
    }

    &__item-right-group {
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    &__item-attachment {
        vertical-align: middle;
        width: 20px;
        height: 20px;
        color: var(--text-secondary-color);

        svg[class*="icon"] {
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    &__item-name {
        display: grid;
        grid-template-columns: auto auto;
        margin: 0;
        font-weight: 500;

        span:first-child {
            @include text-ellipsis;
        }

        .marker-item {
            width: 8px;
            height: 8px;
            margin-left: 7px;
        }
    }

    &__item-time {
        margin-left: 0.6667rem;
        font-size: 0.8667rem;
        color: var(--text-secondary-color);
        white-space: nowrap;
    }

    &__item-message {
        grid-column: span 2;
        margin-top: 0.6667rem;
    }

    &__item-text {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin: 0;
        font-size: 13px;
        line-height: 20/13*1em;
        color: var(--text-secondary-color);
        overflow: hidden;
    }
}

.inbox-details {
    --inbox-details-gutter-x: 3.3333rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-color: var(--background-secondary-color);

    @include media('<sm-tablet') {
        --inbox-details-gutter-x: 1rem;
    }

    @include media('<lg-tablet') {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
        display: none;
        height: auto;
        width: 100%;

        .inbox-grid--show-details & {
            display: flex;
        }
    }

    &__dismiss {
        width: calc(var(--inbox-top-height) - 8px)!important;
        height: calc(var(--inbox-top-height) - 8px)!important;
        border-radius: var(--radius-base);

        @include media('>lg-tablet') {
            display: none;
        }

        svg[class*="icon"] {
            transform: rotate(-90deg);
        }
    }

    &__top {
        flex-shrink: 0;
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        justify-content: space-between;
        align-items: center;
        height: var(--inbox-top-height);
        padding: 0 calc(var(--inbox-details-gutter-x) - 0.6667rem);
        border-bottom: 1px solid var(--border-grey-color);

        @include media('<sm') {
            grid-template-columns: 1fr auto auto;

            .button-icon {
                width: 32px;
                height: 32px;
            }
        }
    }

    &__top-right {
        margin-left: auto;
    }

    &__pagination {
        display: flex;
        align-items: center;

        .button-icon {
            &__icon {
                width: 6px;
                height: 10px;
            }
        }
    }

    &__content {
        height: calc(100vh - var(--header-height) - var(--inbox-top-height) - var(--inbox-details-bottom-height));
        padding: 1.3333rem var(--inbox-details-gutter-x) 1.0667rem;
    }

    &__header {
        display: grid;
        grid-template-columns: auto auto;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2.6667rem;

        .media-item {
            &__icon {
                margin-right: 1.6rem;

                @include media('<sm-tablet') {
                    margin-right: 1rem;
                }
            }

            &__title {
                margin-bottom: 7px;
                font-weight: 500;
            }
        }
    }

    &__text-message {
        margin-bottom: 1.3333rem;
        font-size: inherit;
        line-height: 32/15*1em;
        font-family: inherit;
        white-space: pre-line;
        color: inherit;
    }

    &__files {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(16.6667rem, max-content));
        grid-gap: 1.0667rem;
        margin-bottom: 2rem;
    }

    &__bottom {
        height: var(--inbox-details-bottom-height);
        padding: 1.0667rem var(--inbox-details-gutter-x) 0 calc(var(--inbox-details-gutter-x) - 0.6667rem);

        @include media ('<sm-tablet') {
            display: flex;
            align-items: center;
            padding-top: 0;

            .row {
                flex-grow: 1;
            }
        }
    }
}