/* Widgets */
.widget {
    flex-grow: 1;
    position: relative;
    padding: 1.625rem 2.5rem;
    border-radius: var(--card-border-radius);
    box-shadow: var(--card-shadow);

    @include media('<xl') {
        padding: 1.5rem 1.25rem;
    }

    &::before {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        content: "";
        width: 100%;
        height: 100%;
        background-color: var(--background-secondary-color);
        border-radius: var(--card-border-radius);
    }

    &::after {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        width: 80%;
        height: 40px;
        margin: auto;
        box-shadow: var(--card-shadow-shape);
    }

    &__wrapper {
        position: relative;
        z-index: 2;
    }

    &__row {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__left {
        padding-right: 2.25rem;
    }

    &__title {
        margin-top: 0;
        margin-bottom: 15/20*1em;
        font-weight: 700;
        font-size: 20px;
        line-height: 23/20*1em;
    }

    &__status-title {
        margin-bottom: 12px;
    }

    &__trade {
        margin-bottom: 6px;

        > * {
            margin-bottom: 0.2667rem;
        }
    }

    &__trade-count {
        vertical-align: middle;
        margin-right: 4px;
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 28/24*1em;
    }

    &__chart {
        position: relative;
    }

    &__chart-inner {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    &__chart-percentage {
        position: relative;
        margin-bottom: 4px;
        font-weight: 500;
        font-size: 1.875rem;
        line-height: 1;
        white-space: nowrap;

        small {
            position: absolute;
            display: inline-block;
            vertical-align: middle;
            margin: 0.5em 0 0 0.25em;
            font-size: 16/30*1em;
        }
    }

    &__chart-caption {
        font-size: 13px;
        line-height: 15/13*1em;
    }

    &__chart-canvas {
        canvas {
            display: block;
        }
    }
}