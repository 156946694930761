/* -----------------------------------
 * TABLE OF CONTENTS:
 * Variables
 * Fonts
 * Icons
 * Base
 * Typography
 * Buttons
 * Labels
 * Badges
 * Dropdowns
 * Tooltips
 * Scrollbars
 * Widgets
 * Cards
 * Forms
 * Tags
 * Files Uploads
 * Charts
 * Page
 * Tables
 * Sidebar
 * Sidebar Panel
 * Header
 * Calendar
 * Breadcrumbs
 * Pagination
 * Products
 * Orders
 * Order Notes
 * Order Messages
 * Customer Account
 * Cart
 * Calendar Full
 * Inbox
 * Chat
 * Contacts
 * Todo
 * File Manager
 * Timeline
 * Authentication
 ------------------------------------- */
/* Variables */
:root {
  --black-color: #15232E;
  --black-color-2: #1F2935;
  --black-color-3: #2D3743;
  --black-color-4: #333E4C;
  --black-color-control-1: #141f27;
  --black-color-control-2: #1d2c38;
  --black-color-control-light: #333E4C;
  --grey-color-1: #8697A8;
  --grey-color-2: #A6B2BF;
  --grey-color-3: #44566C;
  --grey-color-4: #B3C0CE;
  --grey-color-5: #CFCFD1;
  --grey-color-6: #737781;
  --grey-color-7: #C4C4C4;
  --grey-light-color-1: #F8FAFB;
  --grey-light-color-2: #eef3f5;
  --grey-light-color-3: #EAEDF0;
  --grey-light-color-4: #EFF4F8;
  --grey-light-color-5: #F4F4F9;
  --grey-light-color-6: #E5E5E5;
  --white-color: #ffffff;
  --blue-color: #0081FF;
  --blue-color-a15: rgba(0, 129, 255, 0.15);
  --blue-color-2: #0037FA;
  --blue-color-control-1: #2c91f7;
  --blue-color-control-2: #0f3ad8;
  --teal-color: #22CCE2;
  --red-color: #FD594A;
  --red-color-2: #FF3D57;
  --green-color: #09B66D;
  --orange-color-1: #FF8A48;
  --orange-color-2: #FDBF5E;
  /* Transitions */
  --t-base: 0.18s ease-in-out;
  --t-smooth: cubic-bezier(.215, .61, .355, 1);
  --t-elastic: 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  /* Fonts */
  --font-size-base: 15px;
  --font-family-default: 'Roboto', sans-serif;
  --font-family-secondary: 'Comfortaa', sans-serif;
  --font-family-third: 'Rounded Mplus 1c';
  --font-icons: 'Material Icons';
  --sidebar-width: 15rem;
  --sidebar-full-width: 15rem;
  --sidebar-collapse-width: 64px;
  --header-height: 4.2667rem;
  --subheader-height: 4.2rem;
  --order-tabs-height: 4.3333rem;
  --order-tabs-offset-bottom: 2.6667rem;
  --container-width: 100%;
  --container-width-xl: 1200px;
  --container-gutter: 30px;
  --container-gutter-sm: 24px;
  --column-gutter: 15px;
  --column-gutter-md: 12px;
  --column-gutter-sm: 10px;
  --column-gutter-xs: 7px;
  --gutter-bottom-xl: 2rem;
  --gutter-bottom-lg: 1.6rem;
  --gutter-bottom-sm: 1.3333rem;
  --gutter-bottom-xs: 14px;
  --calendar-inline-width: 307px;
  --radius-base: 5px;
  --radius-lg: 10px;
  --tooltip-arrow-size: 12px;
  /* Cards */
  --card-border-radius: 10px;
  --card-gutter-x: 2.6667rem;
  /* Inputs */
  --input-gutter-x: 17px;
}

@media screen and (max-width: 1365px) {
  :root {
    --sidebar-width: 64px;
    --container-gutter: 1.6rem;
    --column-gutter: 10px;
    --column-gutter-md: 10px;
    --gutter-bottom-xl: 1.5rem;
    --card-gutter-x: 1.25rem;
  }
}

@media screen and (max-width: 991px) {
  :root {
    --order-tabs-offset-bottom: 16px;
  }
}

@media screen and (max-width: 767px) {
  :root {
    --subheader-height: 3.2rem;
    --order-tabs-height: 3.4rem;
    --container-gutter-sm: 15px;
  }
}

@media screen and (max-width: 575px) {
  :root {
    --gutter-bottom-xl: 1.1rem;
  }
}

@media screen and (max-width: 374px) {
  :root {
    --container-gutter: 10px;
    --column-gutter: 8px;
  }
}

/* Colors theme */
html[data-theme='light'] {
  --background-primary-color: var(--grey-light-color-1);
  --background-secondary-color: var(--white-color);
  --background-secondary-reverse: var(--grey-color-3);
  --primary-color: var(--blue-color);
  --secondary-color: var(--orange-color-2);
  --text-primary-color: var(--grey-color-3);
  --text-primary-reverse: var(--white-color);
  --text-secondary-color: var(--grey-color-1);
  --text-third-color: var(--grey-color-3);
  --border-grey-color: var(--grey-light-color-3);
  --scrollbar-color: var(--grey-light-color-3);
  --control-background-hover: var(--grey-light-color-2);
  --control-background-hover-light: var(--grey-light-color-3);
  --control-background-active: var(--grey-light-color-3);
  --control-shadow: 0px 4px 4px rgba(169, 194, 209, 0.05), 0px 8px 16px rgba(169, 194, 209, 0.1);
  --placeholder-color: var(--grey-color-1);
  --card-footer-background: var(--grey-light-color-1);
  --card-shadow: 0px 2px 4px rgba(169, 194, 209, 0.05), 0px 12px 16px rgba(169, 194, 209, 0.1);
  --card-shadow-shape: 0px 32px 32px rgba(169, 194, 209, 0.1), 0px 8px 16px rgba(169, 194, 209, 0.05);
  --dropdown-shadow: 0px 16px 24px rgba(169, 194, 209, 0.25), 0px 2px 4px rgba(169, 194, 209, 0.1);
  --dropdown-shadow-shape: 0px 4px 32px rgba(169, 194, 209, 0.05), 0px 2px 16px rgba(169, 194, 209, 0.05);
  --backdrop-color: var(--grey-color-3);
  --backdrop-opacity: 0.12;
}

html[data-theme='dark'] {
  --background-primary-color: var(--black-color);
  --background-secondary-color: var(--black-color-2);
  --background-secondary-reverse: var(--grey-color-3);
  --primary-color: var(--blue-color-2);
  --secondary-color: var(--orange-color-1);
  --text-primary-color: var(--white-color);
  --text-primary-reverse: var(--grey-color-3);
  --text-secondary-color: var(--grey-color-6);
  --text-third-color: var(--grey-color-6);
  --border-grey-color: var(--black-color-4);
  --scrollbar-color: var(--grey-color-6);
  --control-background-hover: var(--black-color-control-1);
  --control-background-hover-light: var(--black-color-control-light);
  --control-background-active: var(--black-color-control-2);
  --placeholder-color: var(--white-color);
  --card-footer-background: var(--background-secondary-color);
  --card-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 12px 16px rgba(0, 0, 0, 0.2);
  --card-shadow-shape: 0px 32px 32px rgba(0, 0, 0, 0.15), 0px 8px 16px rgba(0, 0, 0, 0.1);
  --dropdown-shadow: 0px 16px 24px rgba(0, 0, 0, 0.25), 0px 2px 4px rgba(0, 0, 0, 0.1);
  --dropdown-shadow-shape: 0px 4px 32px rgba(0, 0, 0, 0.05), 0px 2px 16px rgba(0, 0, 0, 0.05);
  --backdrop-color: var(--black-color);
  --backdrop-opacity: 0.4;
}

/* Fonts */
@font-face {
  font-family: 'Comfortaa';
  src: url("../fonts/Comfortaa-Bold.woff2") format("woff2"), url("../fonts/Comfortaa-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rounded Mplus 1c';
  src: url("../fonts/RoundedMplus1c-Black.woff2") format("woff2"), url("../fonts/RoundedMplus1c-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/Roboto-Bold.woff2") format("woff2"), url("../fonts/Roboto-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/Roboto-Medium.woff2") format("woff2"), url("../fonts/Roboto-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/Roboto-Regular.woff2") format("woff2"), url("../fonts/Roboto-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Material Icons';
  src: url("../fonts/MaterialIcons/MaterialIcons-Regular.eot");
  src: url("../fonts/MaterialIcons/MaterialIcons-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/MaterialIcons/MaterialIcons-Regular.woff2") format("woff2"), url("../fonts/MaterialIcons/MaterialIcons-Regular.woff") format("woff"), url("../fonts/MaterialIcons/MaterialIcons-Regular.ttf") format("truetype"), url("../fonts/MaterialIcons/MaterialIcons-Regular.svg#MaterialIcons-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Animations */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes rotate {
  from {
    transform-origin: center;
    transform: rotate(0deg);
  }
  to {
    transform-origin: center;
    transform: rotate(360deg);
  }
}

@keyframes selectAddTag {
  0% {
    transform: translateX(-5px) rotate(5deg);
    opacity: 0;
  }
  100% {
    transform: translateX(0) rotate(0deg);
    opacity: 1;
  }
}

/* Icons */
[class^="icon-"] {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.icon-arrow-down {
  flex-shrink: 0;
  width: 22px;
  height: 22px;
  color: var(--text-third-color);
}

.icon-arrow-down svg {
  width: 100%;
  height: 100%;
  fill: currentColor;
}

.trade-icon {
  display: inline-block;
  vertical-align: middle;
  width: 30px;
  height: 30px;
  margin-right: 3px;
  margin-left: 3px;
}

.trade-icon--up {
  color: var(--green-color);
}

.trade-icon--down {
  color: var(--red-color-2);
}

.trade-icon svg {
  vertical-align: top;
  width: 100%;
  height: 100%;
}

.quotation {
  display: inline-block;
  vertical-align: middle;
}

.quotation svg {
  vertical-align: top;
  width: 22px;
  height: 22px;
}

.quotation--up {
  color: var(--green-color);
}

.quotation--up svg {
  transform: rotate(180deg);
}

.quotation--down {
  color: var(--red-color-2);
}

.media-item {
  display: flex;
  align-items: center;
}

.media-item--medium {
  line-height: 1rem;
}

.media-item--file {
  display: inline-flex;
  vertical-align: middle;
  cursor: pointer;
}

.media-item__icon {
  flex-shrink: 0;
  position: relative;
  z-index: 0;
  min-width: 2rem;
  width: 2rem;
  height: 2rem;
  margin-right: 1.06667rem;
  font-weight: 500;
  font-size: 11px;
  line-height: 1;
  color: var(--white-color);
  text-transform: uppercase;
  border-radius: 50%;
}

.media-item__icon .badge-signal {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 8px;
  height: 8px;
  border-width: 2px;
  z-index: inherit;
}

.media-item--medium .media-item__icon {
  width: 2.6667rem;
  height: 2.6667rem;
  font-size: 0.9333rem;
}

.media-item--medium .media-item__icon .badge-signal {
  width: 10px;
  height: 10px;
}

.media-item__icon img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.media-item__icon-folder, .media-item__icon-file {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 41px;
  height: 42px;
  margin-right: 10px;
}

.media-item__icon-file svg {
  width: 31px;
  height: 100%;
}

.media-item__right {
  flex-grow: 1;
}

.media-item__icon-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
}

.media-item__title {
  margin-top: 0;
  margin-bottom: 2px;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.2em;
}

.media-item--file .media-item__title {
  margin-bottom: 0;
}

.marker-item {
  flex-shrink: 0;
  align-self: center;
  display: inline-block;
  vertical-align: middle;
  width: 6px;
  height: 6px;
  margin-right: 7px;
  border-radius: 50%;
}

.sort {
  flex-shrink: 0;
  vertical-align: middle;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 22px;
  height: 22px;
  color: var(--text-third-color);
}

.sort::before, .sort::after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  margin: 1px 3px;
  border-style: solid;
  border-color: transparent;
}

.sort::before {
  border-width: 0 4px 5px;
  border-bottom-color: currentColor;
}

.sort::after {
  border-width: 5px 4px 0;
  border-top-color: currentColor;
}

.sort--up::after {
  display: none;
}

.sort--down::before {
  display: none;
}

.sort svg {
  width: 100%;
  height: 100%;
}

/* Base */
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

*, *::before, *::after {
  box-sizing: border-box;
}

html,
body {
  -webkit-overflow-scrolling: touch;
}

html {
  font-size: var(--font-size-base);
}

html.rtl {
  direction: rtl;
  text-align: inherit;
}

@media (max-width: 1023px) {
  html {
    font-size: 92%;
  }
}

@media (max-width: 767px) {
  html {
    font-size: 86.5%;
  }
}

body {
  margin: 0;
  font-weight: 400;
  font-size: calc(var(--font-size-base) * 1rem);
  line-height: 1.6;
  font-family: var(--font-family-default);
  font-feature-settings: normal;
  font-variant: normal;
  color: var(--text-primary-color);
  background: var(--background-primary-color);
  opacity: 0;
  overflow: hidden;
}

body.load {
  opacity: 1;
  overflow: initial;
  transition: var(--t-base);
  transition-property: opacity;
}

@-ms-viewport {
  width: auto;
}

a,
button {
  cursor: pointer;
}

button,
input {
  -webkit-appearance: none;
  padding: 0;
  border: none;
  background-color: transparent;
  box-shadow: none;
  outline: none;
  color: inherit;
}

img,
embed,
video {
  display: inline-block;
  max-width: 100%;
  height: auto;
  line-height: 1;
  border: none;
}

svg {
  display: inline-block;
  line-height: 1;
  fill: currentColor;
}

/* Typography */
h1,
.h1 {
  margin-top: 0;
  font-weight: 700;
}

h2,
.h2 {
  margin-top: 0;
  font-weight: 700;
  font-size: 2rem;
}

@media (max-width: 767px) {
  h2,
  .h2 {
    font-size: 1.6rem;
  }
}

h3,
.h3 {
  margin-top: 0;
  margin-bottom: 0.75em;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.15em;
}

h4,
.h4 {
  margin-top: 0;
  font-weight: 500;
}

h5,
.h5 {
  margin-top: 0;
  margin-bottom: 0.66667em;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.2em;
}

p {
  margin-bottom: 1.25rem;
}

.text-large {
  font-size: 1.3333rem;
  line-height: 1.15em;
}

a {
  color: inherit;
  transition: var(--t-base);
}

a:hover {
  color: var(--blue-color);
  text-decoration: none;
}

a.text-blue:hover {
  text-decoration: underline;
}

a.link-under {
  border-bottom: 1px solid;
}

a.link-under:hover {
  border-bottom: 1px solid transparent;
}

.text-sm {
  font-size: 13px;
}

.font-weight-medium {
  font-weight: 500;
}

.text-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.text-clamp, .timeline-item__title a {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
  overflow: hidden;
}

.text-clamp--2, .timeline-item__title a {
  -webkit-line-clamp: 2;
}

.text-linethrough {
  text-decoration: line-through;
}

.color-theme {
  background-color: var(--primary-color);
}

.color-blue {
  background-color: var(--blue-color);
}

.color-teal {
  background-color: var(--teal-color);
}

.color-red {
  background-color: var(--red-color-2);
}

.color-green {
  background-color: var(--green-color);
}

.color-orange {
  background-color: var(--orange-color-2);
}

.color-orange-dark {
  background-color: var(--orange-color-1);
}

.color-grey-bg {
  background-color: var(--background-primary-color);
}

.color-grey {
  background-color: var(--text-secondary-color);
}

.color-grey-dark {
  background-color: var(--text-primary-color);
}

.color-grey-light {
  background-color: var(--border-grey-color);
}

.text-theme {
  color: var(--primary-color);
}

.text-blue {
  color: var(--blue-color);
}

.text-teal {
  color: var(--teal-color);
}

.text-red {
  color: var(--red-color-2);
}

.text-green {
  color: var(--green-color);
}

.text-orange {
  color: var(--orange-color-2);
}

.text-orange-dark {
  color: var(--orange-color-1);
}

.text-grey-bg {
  color: var(--background-primary-color);
}

.text-grey {
  color: var(--text-secondary-color);
}

.text-grey-dark {
  color: var(--text-primary-color);
}

.text-grey-light {
  color: var(--border-grey-color);
}

[data-theme='light'] .text-light-theme {
  color: var(--text-secondary-color);
}

[data-theme='dark'] .text-dark-theme {
  color: var(--text-secondary-color);
}

[data-theme='dark'] .text-light-dark-theme {
  color: var(--text-primary-color);
}

.page-header {
  margin-bottom: 1rem;
}

.page-header--grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, auto));
  align-items: center;
  justify-content: space-between;
}

.page-header--inline {
  margin-bottom: 0;
}

.page-header__title {
  margin-bottom: 0;
  font-size: 2rem;
  line-height: 1.16667em;
}

.page-header--inline .page-header__title {
  font-size: 1.3333rem;
  line-height: 1.15em;
}

.page-header__subtitle {
  margin-top: 0;
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 1.3333rem;
  line-height: 1.15em;
}

@media (max-width: 991px) {
  .page-header__subtitle {
    font-size: 15px;
  }
}

/* Buttons */
.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 2.6667rem;
  padding: 0 1.4667rem;
  font-weight: 500;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 5px;
  outline: none;
  white-space: nowrap;
  transition: var(--t-base);
}

.button--block {
  display: flex;
  width: 100%;
}

.button--m-auto {
  margin: auto;
}

.button--primary {
  border-color: var(--primary-color);
  background-color: var(--primary-color);
  color: var(--white-color);
  box-shadow: 0px 5px 15px rgba(0, 129, 255, 0.15), 0px 15px 40px rgba(0, 129, 255, 0.2);
}

.button--primary:hover {
  border-color: var(--blue-color-control-1);
  background-color: var(--blue-color-control-1);
  color: var(--white-color);
  box-shadow: 0px 5px 15px rgba(0, 129, 255, 0), 0px 15px 40px rgba(0, 129, 255, 0);
}

.button--primary:active {
  border-color: var(--primary-color);
  background-color: var(--primary-color);
}

.button--secondary {
  color: var(--primary-color);
  background-color: var(--blue-color-a15);
}

html[data-theme='light'] .button--secondary:hover {
  box-shadow: 0px 5px 15px rgba(0, 129, 255, 0.15), 0px 15px 40px rgba(0, 129, 255, 0.2);
}

.button--load.is-active svg[class*='icon'] {
  animation: infinite rotate 1s linear;
}

.button__icon {
  width: 16px;
  height: 16px;
}

.button__icon--left {
  margin-right: 4px;
}

.button__icon svg {
  vertical-align: top;
  width: 100%;
  height: 100%;
}

.switcher-button {
  display: inline-flex;
  padding: 3px;
  border-radius: var(--radius-base);
  background-color: var(--background-primary-color);
  pointer-events: none;
}

.switcher-button--light {
  background-color: var(--background-secondary-color);
  box-shadow: var(--control-shadow);
}

.switcher-button__items {
  flex-grow: 1;
  display: flex;
  position: relative;
  pointer-events: none;
}

.switcher-button__float {
  position: absolute;
  top: 0;
  z-index: 1;
  width: calc(50% - 1px);
  height: 100%;
  border-radius: var(--radius-base);
  background-color: var(--primary-color);
  box-shadow: 0px 8px 16px rgba(0, 129, 255, 0.2);
  transition: transform 200ms ease-out;
}

.switcher-button__item {
  flex-grow: 1;
  display: flex;
}

.switcher-button__item:not(:last-of-type)::after {
  position: relative;
  content: "";
  margin: 6px 0;
  border-right: 1px solid var(--grey-color-4);
}

.switcher-button--light .switcher-button__item:not(:last-of-type)::after {
  border-color: var(--border-grey-color);
}

.switcher-button__item::after {
  border-color: var(--grey-color-4);
}

.switcher-button__btn {
  flex-grow: 1;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.2667rem;
  min-width: 5.3333rem;
  white-space: nowrap;
  user-select: none;
  transition: var(--t-base);
  pointer-events: all;
}

.switcher-button__btn:hover {
  color: var(--grey-color-4);
}

.switcher-button__item.active .switcher-button__btn {
  color: var(--white-color);
}

.switcher-date {
  --switcher-button-width: 38px;
  position: relative;
  display: inline-flex;
  flex-direction: column;
}

.switcher-date__controls {
  display: inline-flex;
  min-height: 2.6667rem;
  border-radius: var(--radius-base);
  line-height: 1;
  background-color: var(--background-secondary-color);
  box-shadow: var(--control-shadow);
}

.switcher-date__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--switcher-button-width);
  transition: var(--t-base);
}

.switcher-date__btn:hover {
  background-color: var(--control-background-hover);
}

.switcher-date__btn:active {
  background-color: var(--control-background-active);
}

.switcher-date__btn svg[class*="icon"] {
  width: 6px;
  height: 10px;
}

.switcher-date__btn--prev {
  border-radius: var(--radius-base) 0 0 var(--radius-base);
}

.switcher-date__btn--next {
  border-radius: 0 var(--radius-base) var(--radius-base) 0;
}

.switcher-date__input-group {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid var(--border-grey-color);
  border-top: none;
  border-bottom: none;
}

.switcher-date__input-group svg[class*="icon"] {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 14px;
  width: 22px;
  height: 24px;
  margin: auto;
  padding-bottom: 2px;
}

.switcher-date__input {
  display: flex;
  align-items: center;
  padding: 9px 17px 9px 46px;
  min-width: 9.3333rem;
  min-height: 40px;
  cursor: pointer;
  transition: var(--t-base);
}

.switcher-date__input:hover {
  background-color: var(--control-background-hover);
}

.switcher-date .select2 {
  width: 0 !important;
  height: 0 !important;
  overflow: hidden !important;
}

.switcher-date .select2-dropdown {
  right: 0;
  left: auto;
  width: calc(100% - var(--switcher-button-width)) !important;
}

.button-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin: 0;
  border-radius: 50%;
  cursor: pointer;
  background-color: var(--grey-light-color-3);
  transition: var(--t-base);
}

.button-icon:hover {
  background-color: var(--control-background-hover);
  color: inherit;
}

.button-icon:active {
  background-color: var(--control-background-active);
}

.button-icon.active {
  color: var(--white-color);
  background-color: var(--primary-color);
}

.button-icon input {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.button-icon--small {
  width: 28px;
  height: 28px;
}

.button-icon--transparent {
  background-color: transparent;
}

.button-icon--grey {
  color: var(--text-secondary-color);
  background-color: transparent;
}

.button-icon--grey:hover {
  color: inherit;
}

.button-icon__icon {
  width: 22px;
  height: 22px;
}

.button-icon--small .button-icon__icon {
  width: 16px;
  height: 16px;
}

.button-icon__icon svg {
  vertical-align: top;
  width: 100%;
  height: 100%;
}

.button-add {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-weight: 500;
  font-size: 13px;
  line-height: 1;
  border-radius: 50%;
  transition: var(--t-base);
}

.button-add--sm {
  width: 34px;
  height: 34px;
}

.button-add--blue {
  background-color: var(--primary-color);
  color: var(--white-color);
  box-shadow: 0px 8px 16px rgba(0, 129, 255, 0.2);
}

.button-add--blue:hover {
  transform: scale(1.1);
}

.button-add--blue:active {
  background-color: var(--blue-color-control-1);
  box-shadow: 0px 8px 16px rgba(0, 129, 255, 0);
}

.button-add__icon {
  width: 24px;
  height: 24px;
}

.button-add__icon svg {
  vertical-align: top;
  width: 100%;
  height: 100%;
}

.btn-scroll-page {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: var(--text-secondary-color);
  background-color: var(--background-primary-color);
  transition: var(--t-base);
}

.btn-scroll-page.is-hidden {
  transform: translateY(-20px);
  opacity: 0;
  pointer-events: none;
}

.btn-scroll-page:hover {
  background-color: var(--control-background-hover);
  color: var(--text-primary-color);
}

.btn-scroll-page:active {
  background-color: var(--control-background-active);
}

.btn-scroll-page--down svg[class*="icon"] {
  transform: rotate(-180deg);
}

.btn-scroll-page svg[class*="icon"] {
  width: 55%;
  height: 55%;
}

.toggle-sidebar {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--header-height);
  width: var(--header-height);
  transition: var(--t-base);
}

.toggle-sidebar:hover {
  background-color: var(--control-background-hover);
}

.toggle-sidebar:active {
  background-color: var(--control-background-active);
}

.toggle-sidebar [class*="icon"] {
  width: 24px;
  height: 24px;
  fill: currentColor;
}

.toggle-sidebar--secondary {
  border-radius: var(--radius-lg);
}

.toggle-search {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  transition: var(--t-base);
}

.toggle-search--module {
  position: relative;
  z-index: 2;
  height: 100%;
}

.toggle-search--module [class*="icon-cross"] {
  display: none;
}

.toggle-search--module.active [class*="icon-search"] {
  display: none;
}

.toggle-search--module.active [class*="icon-cross"] {
  display: block;
}

.toggle-search.active {
  color: var(--blue-color);
}

.toggle-search [class*="icon"] {
  width: 27px;
  height: 27px;
  fill: currentColor;
}

/* Labels */
.label {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 3px 10px;
  font-size: 13px;
  line-height: 1.15385em;
  border-radius: var(--radius-base);
}

.label__icon {
  width: 22px;
  height: 22px;
  margin-right: 8px;
}

.label__icon svg {
  width: 100%;
  height: 100%;
}

.label--md {
  padding: 7px 1.6rem;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.2em;
}

.label--lg {
  padding: 9px 1rem;
  border-radius: 3.3333rem;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.2em;
}

.label--primary {
  color: var(--white-color);
  background-color: var(--primary-color);
}

/* Badges */
.badge-signal {
  flex-shrink: 0;
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 1px solid var(--background-secondary-color);
  background-color: var(--red-color);
  pointer-events: none;
}

.badge-signal--green {
  background-color: var(--green-color);
}

.badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  padding: 0 0.25rem;
  border-radius: 10px;
  font-weight: 400;
  font-size: 10px;
  line-height: 1.7em;
  letter-spacing: 0.2px;
}

.badge--sm {
  min-width: 30px;
  font-size: 12px;
  line-height: 1.5em;
}

.badge--small {
  min-width: 18px;
  min-height: 18px;
  padding: 0 4px;
  font-size: 10px;
}

.badge--theme {
  color: var(--white-color);
  background-color: var(--primary-color);
}

.badge--blue {
  color: var(--white-color);
  background-color: var(--blue-color);
}

.badge--teal {
  color: var(--white-color);
  background-color: var(--teal-color);
}

.badge--red {
  color: var(--white-color);
  background-color: var(--red-color-2);
}

.badge--green {
  color: var(--white-color);
  background-color: var(--green-color);
}

.badge--orange {
  color: var(--white-color);
  background-color: var(--orange-color-2);
}

.badge--orange-dark {
  color: var(--white-color);
  background-color: var(--orange-color-1);
}

.badge--grey-bg {
  color: var(--white-color);
  background-color: var(--background-primary-color);
}

.badge--grey {
  color: var(--white-color);
  background-color: var(--text-secondary-color);
}

.badge--grey-dark {
  color: var(--white-color);
  background-color: var(--text-primary-color);
}

.badge--grey-light {
  color: var(--white-color);
  background-color: var(--border-grey-color);
}

.badge-help {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  margin-top: -4px;
  cursor: pointer;
  color: var(--text-secondary-color);
}

.badge-help svg[class*="icon"] {
  height: 16px;
  width: 100%;
}

/* Dropdowns */
.dropdown-menu::before, .dropdown-items__container::before, .dropdown-menu::after, .dropdown-items__container::after {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  content: "";
  width: 0;
  height: 0;
  pointer-events: none;
}

.input-group .dropdown-menu::before, .input-group .dropdown-items__container::before, .input-group .dropdown-menu::after, .input-group .dropdown-items__container::after {
  display: none;
}

.dropdown-menu::before, .dropdown-items__container::before {
  border: 6px solid transparent;
  border-top-width: 0;
  border-bottom-color: var(--border-grey-color);
}

.dropdown-menu::after, .dropdown-items__container::after {
  z-index: 1;
  border: 4px solid transparent;
  border-top-width: 0;
  border-bottom-color: var(--background-secondary-color);
}

.dropdown-menu {
  padding: 6px 0;
  font-size: 15px;
  color: inherit;
  background-color: var(--background-secondary-color);
  border: 1px solid var(--border-grey-color);
  border-radius: var(--card-border-radius);
}

.dropdown-menu--right {
  right: 0;
  left: auto !important;
}

.dropdown-menu--right::before, .dropdown-menu--right::after {
  left: calc(100% - 22px);
}

.dropdown-menu--fluid {
  min-width: auto;
  width: 100%;
}

.dropdown-menu__item, .select2-container .select2-results__option {
  position: relative;
  display: block;
  width: 100%;
  padding: 8px 1.5rem;
  font-weight: normal;
  text-align: left;
  color: inherit;
  transition: var(--t-base);
  outline: none;
  background-color: var(--background-secondary-color);
}

.input-group .dropdown-menu__item, .input-group .select2-container .select2-results__option, .select2-container .input-group .select2-results__option {
  padding-right: 17px;
  padding-left: 17px;
}

.dropdown-menu__item:not(.dropdown-menu__top):not(.dropdown-menu__footer).active, .select2-container .select2-results__option:not(.dropdown-menu__top):not(.dropdown-menu__footer).active, .dropdown-menu__item:not(.dropdown-menu__top):not(.dropdown-menu__footer):hover, .select2-container .select2-results__option:not(.dropdown-menu__top):not(.dropdown-menu__footer):hover {
  color: inherit;
  background-color: var(--grey-light-color-1);
}

.dropdown-menu__item:not(.dropdown-menu__top):not(.dropdown-menu__footer):focus, .select2-container .select2-results__option:not(.dropdown-menu__top):not(.dropdown-menu__footer):focus {
  color: inherit;
  background-color: var(--background-secondary-color);
}

.dropdown-menu__item:not(.dropdown-menu__top):not(.dropdown-menu__footer):active, .select2-container .select2-results__option:not(.dropdown-menu__top):not(.dropdown-menu__footer):active {
  background-color: var(--grey-light-color-2);
  transition-duration: 0.1s;
}

.dropdown-menu__items {
  margin-bottom: -0.4rem;
}

.dropdown-menu__item-block {
  margin: -8px -1.5rem;
  padding: 0.8rem 1.5rem;
  border-top: 1px solid var(--border-grey-color);
}

.dropdown-menu__item-remove {
  position: absolute;
  top: 0;
  right: 16px;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  margin: auto;
  border-radius: 50%;
  background-color: var(--grey-color-3);
  color: var(--white-color);
  opacity: 0;
  pointer-events: none;
  transition-duration: 0s;
}

.dropdown-menu__item-remove:hover {
  color: var(--white-color);
}

.dropdown-menu__item-remove svg {
  width: 14px;
  height: 14px;
}

.dropdown-menu__item:hover .dropdown-menu__item-remove, .select2-container .select2-results__option:hover .dropdown-menu__item-remove {
  opacity: 1;
  pointer-events: all;
}

.dropdown-menu__item:hover .dropdown-menu__item-remove:hover, .select2-container .select2-results__option:hover .dropdown-menu__item-remove:hover {
  transition: var(--t-base);
  opacity: 0.6;
}

.dropdown-menu__divider {
  margin: 6px 0;
  border-top: 1px solid var(--border-grey-color);
}

.dropdown-menu__top {
  padding-top: 5px;
  padding-bottom: 11px;
  display: flex;
  align-items: center;
}

.dropdown-menu__title {
  font-weight: normal;
  font-size: 1rem;
  margin-right: 1rem;
}

.dropdown-menu__clear-all {
  margin-left: auto;
}

.dropdown-menu__link-all {
  text-align: center;
  color: var(--text-secondary-color);
}

.dropdown-menu__link-all [class*="icon"] {
  width: 6px;
  height: 11px;
  margin-left: 15px;
}

.dropdown-menu__item-block {
  display: flex;
  align-items: center;
}

.dropdown-menu__item-left {
  position: relative;
  margin-right: 1.0667rem;
}

.dropdown-menu__item-left .badge-signal {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 10px;
  height: 10px;
  border-width: 2px;
  z-index: inherit;
}

.dropdown-menu__item-right {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
}

.dropdown-menu__message .dropdown-menu__item-right {
  flex-wrap: nowrap;
}

.dropdown-menu__item-column {
  flex-grow: 1;
  display: grid;
  padding-right: 6px;
}

.dropdown-menu__item-icon {
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.6667rem;
  height: 2.6667rem;
  border-radius: 50%;
  color: var(--white-color);
}

.dropdown-menu__item-icon svg[class*="icon"] {
  width: 2rem;
  height: 2rem;
  fill: currentColor;
}

.dropdown-menu__item-icon img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.dropdown-menu__item-icon-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
}

.dropdown-menu__item-title {
  width: 100%;
  flex-grow: 1;
  margin: 0 1rem 2px 0;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.2em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: var(--text-primary-color);
}

.dropdown-menu__message .dropdown-menu__item-title {
  width: auto;
}

.dropdown-menu__text {
  margin-bottom: 0;
  font-size: 13px;
  line-height: 1.84615em;
  color: var(--text-secondary-color);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.dropdown-menu__item-time {
  margin-bottom: 5px;
  font-size: 13px;
  color: var(--text-secondary-color);
  white-space: nowrap;
}

.dropdown-menu__note .dropdown-menu__item-time {
  margin-bottom: 0;
}

.dropdown-menu__item:hover .dropdown-menu__message .dropdown-menu__item-time, .select2-container .select2-results__option:hover .dropdown-menu__message .dropdown-menu__item-time {
  opacity: 0;
}

.dropdown-items {
  position: absolute;
  top: calc(100% + 10px);
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transition: 0.1s ease-in-out;
}

.dropdown-items.show {
  opacity: 1;
  visibility: visible;
}

.dropdown-items--up {
  top: auto;
  bottom: calc(100% + 10px);
}

.dropdown-items--right {
  right: 0;
}

.dropdown-items--left {
  left: 0;
}

.dropdown-items--fluid {
  min-width: auto;
  width: 100%;
}

.dropdown-items__container {
  position: relative;
  min-width: 10rem;
  padding: 6px 0;
  background-color: var(--background-secondary-color);
  border: 1px solid var(--border-grey-color);
  border-radius: var(--card-border-radius);
}

.dropdown-items--fluid .dropdown-items__container {
  min-width: auto;
}

.dropdown-items--left .dropdown-items__container::before, .dropdown-items--left .dropdown-items__container::after {
  left: 15px;
}

.dropdown-items--right .dropdown-items__container::before, .dropdown-items--right .dropdown-items__container::after {
  left: calc(100% - 15px);
}

.dropdown-items__list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-items__link {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 17px;
  font-weight: normal;
  text-align: left;
  color: inherit;
  transition: var(--t-base);
  outline: none;
}

.input-group .dropdown-items__link {
  padding-right: var(--input-gutter-x);
  padding-left: var(--input-gutter-x);
}

.dropdown-items__link:hover {
  color: inherit;
  background-color: var(--grey-light-color-1);
}

.dropdown-items__link:active {
  background-color: var(--grey-light-color-2);
}

.dropdown-items__link-icon {
  display: flex;
  justify-content: center;
  width: 40px;
  height: 22px;
  margin-left: -0.55rem;
  line-height: 1;
}

.table__actions .dropdown-items__link-icon {
  height: 16px;
}

.dropdown-items__link-icon svg {
  width: 22px;
  height: 100%;
}

.dropdown-menu--up::before, .dropdown-menu--up::after,
.dropdown-items--up .dropdown-items__container::before,
.dropdown-items--up .dropdown-items__container::after {
  top: 100%;
  bottom: auto;
  border-bottom-width: 0;
}

.dropdown-menu--up::before,
.dropdown-items--up .dropdown-items__container::before {
  border-top-width: 6px;
  border-top-color: var(--border-grey-color);
}

.dropdown-menu--up::after,
.dropdown-items--up .dropdown-items__container::after {
  border-top-width: 4px;
  border-top-color: var(--background-secondary-color);
}

.items-more {
  position: relative;
}

.items-more__button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  border-radius: 50px;
  color: var(--grey-color-4);
  transition: var(--t-base);
}

.items-more__button:hover {
  background-color: var(--control-background-hover);
  color: var(--grey-color-3);
}

.items-more__button:focus ~ .dropdown-items {
  opacity: 1;
  visibility: visible;
}

.items-more__button:active {
  background-color: var(--control-background-active);
}

.items-more__button svg {
  width: 22px;
  height: 22px;
}

.dropdown-emoji::before, .dropdown-emoji::after {
  display: block !important;
}

.dropdown-emoji__content {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 6px 6px;
  padding: 10px 16px;
}

.dropdown-emoji__image {
  width: 22px;
  max-width: none;
  height: auto;
}

/* Tooltips */
.tooltip {
  top: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  font-family: var(--font-family-base);
  color: var(--text-primary-reverse);
}

.tooltip .arrow {
  width: var(--tooltip-arrow-size);
  height: 8px;
}

.tooltip.show {
  opacity: 1;
}

.tooltip--error.bs-tooltip-right {
  margin-left: 4px;
}

.tooltip--error.bs-tooltip-left {
  margin-right: 4px;
}

.tooltip--no-events {
  pointer-events: none !important;
}

.tooltip-inner {
  max-width: none;
  padding: 7px 12px 5px;
  background-color: var(--background-secondary-reverse);
  border-radius: var(--radius-base);
}

.bs-tooltip-top .arrow::before {
  border-width: var(--tooltip-arrow-size) var(--tooltip-arrow-size) 0 var(--tooltip-arrow-size);
  border-top-color: var(--background-secondary-reverse);
}

.bs-tooltip-bottom .arrow::before {
  border-width: 0 var(--tooltip-arrow-size) var(--tooltip-arrow-size) var(--tooltip-arrow-size);
  border-bottom-color: var(--background-secondary-reverse);
}

.bs-tooltip-right .arrow::before {
  border-width: var(--tooltip-arrow-size) var(--tooltip-arrow-size) var(--tooltip-arrow-size) 0;
  border-right-color: var(--background-secondary-reverse);
}

.bs-tooltip-left .arrow::before {
  border-width: var(--tooltip-arrow-size) 0 var(--tooltip-arrow-size) var(--tooltip-arrow-size);
  border-left-color: var(--background-secondary-reverse);
}

.bs-tooltip-top,
.bs-tooltip-bottom {
  padding: 8px 0;
  margin-top: 2px;
  margin-bottom: 2px;
}

.tippy-content {
  padding: 8px 12px;
  font-size: 12px;
  line-height: 120%;
  border-radius: var(--radius-base);
  color: var(--text-primary-reverse);
  background-color: var(--background-secondary-reverse);
  box-shadow: var(--control-shadow);
}

.tippy-arrow {
  width: calc(var(--tooltip-arrow-size));
  height: calc(var(--tooltip-arrow-size));
  z-index: 1;
}

.tippy-arrow::before, .tippy-arrow::after {
  content: "";
  width: 100%;
  height: 100%;
  transform-origin: center center !important;
  transform: rotate(-45deg);
  border: none;
  border-radius: 2px;
}

.tippy-arrow::before {
  z-index: 1;
  background-color: var(--background-secondary-reverse);
}

.tippy-arrow::after {
  position: absolute;
}

.tippy-box[data-placement^="top"] > .tippy-arrow:before {
  top: calc(calc(50% - 2px));
}

.tippy-box[data-placement^="top"] > .tippy-arrow:after {
  top: calc(calc(50% - 1px));
  transform: rotate(-135deg);
}

.tippy-box[data-placement^="bottom"] > .tippy-arrow:before {
  top: auto;
  bottom: calc(calc(50% - 2px));
}

.tippy-box[data-placement^="bottom"] > .tippy-arrow:after {
  bottom: calc(calc(50% - 1px));
  transform: rotate(45deg);
}

.tippy-box[data-placement^="left"] > .tippy-arrow:before {
  right: auto;
  left: calc(calc(50% - 2px));
}

.tippy-box[data-placement^="left"] > .tippy-arrow:after {
  left: calc(calc(50% - 1px));
  transform: rotate(135deg);
}

.tippy-box[data-placement^="right"] > .tippy-arrow:before {
  right: calc(calc(50% - 2px));
  left: auto;
}

.tippy-box[data-placement^="right"] > .tippy-arrow:after {
  right: calc(calc(50% - 1px));
  transform: rotate(-45deg);
}

[data-simplebar] {
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}

.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

.simplebar-content-wrapper {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%;
  /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
  width: auto;
  visibility: visible;
  overflow: auto;
  /* Scroll on this element otherwise element can't have a padding applied properly */
  max-width: 100%;
  /* Not required for horizontal scroll to trigger */
  max-height: 100%;
  /* Needed for vertical scroll to trigger */
}

.simplebar-content:before,
.simplebar-content:after {
  content: " ";
  display: table;
}

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}

.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0;
}

.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}

.simplebar-scrollbar {
  position: absolute;
  right: 2px;
  width: 7px;
  min-height: 10px;
}

.simplebar-scrollbar:before {
  position: absolute;
  content: "";
  background: var(--background-secondary-reverse);
  border-radius: 7px;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.2s linear;
}

.scrollbar-thin .simplebar-scrollbar:before {
  right: 2px;
  background-color: var(--scrollbar-color);
}

.scrollbar-visible > .simplebar-track .simplebar-scrollbar:before {
  opacity: 1;
}

.simplebar-track .simplebar-scrollbar.simplebar-visible:before {
  /* When hovered, remove all transitions from drag handle */
  opacity: 0.2;
  transition: opacity 0s linear;
}

.scrollbar-thin .simplebar-track .simplebar-scrollbar.simplebar-visible:before {
  opacity: 1;
}

.is-touch .scrollbar-hidden .simplebar-vertical .simplebar-scrollbar.simplebar-visible:before {
  opacity: 0;
}

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 11px;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
}

.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 11px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: calc(100% - 2px);
  top: 1px;
  left: 2px;
  right: 2px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto;
}

/* Rtl support */
[data-simplebar-direction="rtl"] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0;
}

.hs-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll;
}

.progressbar {
  --bar-radius: 3px;
  display: flex;
  flex-wrap: wrap;
}

.progressbar--lg {
  --bar-radius: 10px;
}

.progressbar--axis-top {
  padding-top: 2.2667rem;
}

@media (max-width: 575px) {
  .progressbar--axis-top {
    padding-top: 0;
  }
}

.progressbar__legend {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}

.progressbar__legend-marker {
  width: 12px;
  height: 12px;
  margin-right: 7px;
  border: 3px solid currentColor;
  border-radius: 50%;
}

.progressbar__items {
  flex-grow: 1;
  align-self: center;
  display: flex;
  min-width: 1px;
  height: 4px;
  background-color: var(--grey-light-color-4);
  border-radius: var(--bar-radius);
}

.progressbar--lg .progressbar__items {
  height: 10px;
}

.progressbar__bar {
  position: relative;
  width: 0;
  border-radius: var(--bar-radius);
  animation: progressbarStart 1.2s ease-out forwards;
}

.progressbar__bar--green {
  background-color: var(--green-color);
  box-shadow: 0px 8px 15px rgba(9, 182, 109, 0.3);
}

.progressbar__bar--orange {
  background-color: var(--orange-color-2);
  box-shadow: 0px 8px 15px rgba(253, 191, 94, 0.3);
}

.progressbar__bar--red {
  background-color: var(--red-color-2);
  box-shadow: 0px 8px 15px rgba(255, 61, 87, 0.3);
}

.progressbar__bar--orange-dark {
  background-color: var(--orange-color-1);
  box-shadow: 0px 8px 15px rgba(255, 138, 72, 0.3);
}

.progressbar__bar--teal {
  background-color: var(--teal-color);
  box-shadow: 0px 8px 15px rgba(34, 204, 226, 0.3);
}

.progressbar--red .progressbar__bar {
  background-color: var(--red-color-2);
}

.progressbar--teal .progressbar__bar {
  background-color: var(--teal-color);
}

.progressbar--orange .progressbar__bar {
  background-color: var(--orange-color-2);
}

.progressbar--stacked .progressbar__bar {
  flex-grow: 1;
  border-radius: 0;
  cursor: pointer;
  transition: var(--t-base);
  transition-property: box-shadow;
}

.progressbar--stacked .progressbar__bar:hover {
  box-shadow: 0 0 0 transparent;
}

.progressbar--stacked .progressbar__bar:first-child {
  border-radius: var(--bar-radius) 0 0 var(--bar-radius);
}

.progressbar--stacked .progressbar__bar:last-child {
  border-radius: 0 var(--bar-radius) var(--bar-radius) 0;
}

.progressbar__bar-count {
  position: absolute;
  left: 0;
  width: 100%;
  padding-left: 3px;
  color: var(--text-secondary-color);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.progressbar--axis-top .progressbar__bar-count {
  top: -2.6667rem;
  bottom: 0;
}

@media (max-width: 575px) {
  .progressbar--axis-top .progressbar__bar-count {
    opacity: 0;
  }
}

.progressbar__append {
  width: 2.9333rem;
  text-align: right;
}

.progressbar__percentage {
  color: var(--text-secondary-color);
}

.country-legend {
  --item-bottom-gutter: 1.6rem;
  --item-gutter-x: 2.6667rem;
}

@media (max-width: 575px) {
  .country-legend {
    --item-bottom-gutter: 1rem;
  }
}

.country-legend__row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 calc(var(--item-bottom-gutter) * -1) calc(var(--item-gutter-x) * -1);
}

@media (max-width: 575px) {
  .country-legend__row {
    flex-direction: column;
  }
}

.country-legend__item {
  display: flex;
  justify-content: space-between;
  margin: 0 0 var(--item-bottom-gutter) var(--item-gutter-x);
  white-space: nowrap;
}

.country-legend__left {
  margin-right: 1.6rem;
}

.country-legend__marker {
  display: inline-block;
  vertical-align: middle;
  width: 8px;
  height: 8px;
  margin-right: 8px;
  border-radius: 50%;
}

.country-legend__name {
  vertical-align: middle;
  font-weight: 500;
}

.country-legend__quot {
  display: inline-block;
  vertical-align: middle;
}

.country-legend__percentage {
  vertical-align: middle;
  color: var(--text-secondary-color);
}

.media-progress {
  display: grid;
  grid-template-columns: 1fr auto;
  line-height: 1.2em;
}

.media-progress__title {
  margin-bottom: 0;
}

.media-progress__progressbar {
  grid-column: span 1 / 2;
  margin-top: 12px;
  grid-column: span 2;
  height: 5px;
  border-radius: 5px;
  background-color: var(--border-grey-color);
}

.media-progress__bar {
  width: 0;
  height: 100%;
  border-radius: 5px;
  background-color: var(--primary-color);
  animation: progressbarStart 1.2s ease-out forwards;
  pointer-events: none;
}

@keyframes progressbarStart {
  from {
    width: 0;
  }
}

/* Widgets */
.widget {
  flex-grow: 1;
  position: relative;
  padding: 1.625rem 2.5rem;
  border-radius: var(--card-border-radius);
  box-shadow: var(--card-shadow);
}

@media (max-width: 1365px) {
  .widget {
    padding: 1.5rem 1.25rem;
  }
}

.widget::before {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  background-color: var(--background-secondary-color);
  border-radius: var(--card-border-radius);
}

.widget::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 80%;
  height: 40px;
  margin: auto;
  box-shadow: var(--card-shadow-shape);
}

.widget__wrapper {
  position: relative;
  z-index: 2;
}

.widget__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.widget__left {
  padding-right: 2.25rem;
}

.widget__title {
  margin-top: 0;
  margin-bottom: 0.75em;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.15em;
}

.widget__status-title {
  margin-bottom: 12px;
}

.widget__trade {
  margin-bottom: 6px;
}

.widget__trade > * {
  margin-bottom: 0.2667rem;
}

.widget__trade-count {
  vertical-align: middle;
  margin-right: 4px;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.16667em;
}

.widget__chart {
  position: relative;
}

.widget__chart-inner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.widget__chart-percentage {
  position: relative;
  margin-bottom: 4px;
  font-weight: 500;
  font-size: 1.875rem;
  line-height: 1;
  white-space: nowrap;
}

.widget__chart-percentage small {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  margin: 0.5em 0 0 0.25em;
  font-size: 0.53333em;
}

.widget__chart-caption {
  font-size: 13px;
  line-height: 1.15385em;
}

.widget__chart-canvas canvas {
  display: block;
}

/* Cards */
.card {
  --widget-gutter: 5.3333rem;
  --widget-gutter-2: 3.6rem;
  display: flex;
  flex-grow: 1;
  padding: 2.4rem 0 1.6rem;
  border: none;
  background-color: transparent;
  border-radius: var(--card-border-radius);
  box-shadow: var(--card-shadow);
}

@media (max-width: 1365px) {
  .card {
    padding: 1.5rem 0;
  }
}

@media (max-width: 1199px) {
  .card {
    --widget-gutter: 3.6rem;
    --widget-gutter-2: 2.4rem;
  }
}

@media (max-width: 575px) {
  .card {
    --widget-gutter-2: 1.5rem;
  }
}

.card::before {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  background-color: var(--background-secondary-color);
  border-radius: var(--card-border-radius);
}

.card::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 80%;
  height: 40px;
  margin: auto;
  box-shadow: var(--card-shadow-shape);
}

.card__wrapper {
  position: relative;
  z-index: 2;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.card__container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 var(--card-gutter-x);
}

.card--content-center .card__container {
  flex-grow: 0;
  margin: auto;
}

@media (min-width: 1024px) {
  .card--content-center .card__container {
    padding: 0 2rem;
  }
}

.card__container--gutter-sm {
  padding: 0 calc(var(--card-gutter-x) / 1.5);
}

@media (max-width: 575px) {
  .card__container--gutter-sm {
    padding-right: 0;
    padding-left: 0;
  }
}

.card__chart .card__container {
  justify-content: center;
}

.card__scrollbar {
  margin-right: calc(var(--card-gutter-x) * -1);
  margin-left: calc(var(--card-gutter-x) * -1);
}

.card__header {
  position: relative;
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-bottom: 2.4667rem;
}

@media (max-width: 1199px) {
  .card__header {
    flex-wrap: wrap;
  }
}

.card--lg .card__header {
  margin-bottom: 3.3333rem;
}

.card__header-left {
  order: -1;
  padding-right: 1rem;
}

.card__header-title {
  margin-bottom: 0;
  font-weight: 700;
}

.card__title {
  margin-bottom: 1.6rem;
}

.card__date {
  color: var(--text-secondary-color);
}

.card__tools {
  flex-grow: 1;
  position: static;
  margin-right: 6%;
}

@media (max-width: 1199px) {
  .card__tools {
    order: 1;
    width: 100%;
    padding-top: 1.6rem;
    margin-right: 0;
  }
}

.card__tools-row {
  justify-content: flex-end;
}

.card__tools-calendar {
  flex-grow: 0;
}

@media (max-width: 575px) {
  .card__tools-calendar {
    flex-grow: 1;
  }
}

.card__tools-calendar .input-group {
  min-width: 260px;
}

.card__tools-week {
  flex-grow: 0;
}

@media (max-width: 575px) {
  .card__tools-week {
    flex-grow: 1;
  }
}

.card__tools-week .input-group {
  min-width: 150px;
}

.card__tools-switcher {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 575px) {
  .card__tools-switcher .switcher-button {
    flex-grow: 1;
  }
}

.card__tools-more {
  position: relative;
  right: -6px;
  margin-left: auto;
}

.card__tools-more .dropdown-items {
  right: 100%;
  transform: translateX(50%) translateX(17px);
}

@media (max-width: 1365px) {
  .card__tools-more .dropdown-items {
    right: 0px;
    left: auto;
    transform: translateX(9px);
  }
  .card__tools-more .dropdown-items__container::before, .card__tools-more .dropdown-items__container::after {
    left: calc(100% - 1.6667rem);
  }
}

.card__body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.card__widgets {
  margin-bottom: 2rem;
}

@media (min-width: 768px) {
  .card--lg .card__widgets {
    margin-bottom: 2.6667rem;
  }
}

@media (max-width: 575px) {
  .card__widgets {
    margin-bottom: 1.5rem;
  }
}

.card__widgets-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: calc(var(--widget-gutter) * -1);
}

.card--overview .card__widgets-row {
  margin-left: calc(var(--widget-gutter-2) * -1);
}

.card__widgets-row .card-widget {
  padding-left: var(--widget-gutter);
}

.card--overview .card__widgets-row .card-widget--sm {
  padding-left: var(--widget-gutter-2);
}

.card__chart {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: calc(var(--card-gutter-x) * -1);
  margin-left: calc(var(--card-gutter-x) * -1);
  overflow: hidden;
}

.card__chart-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
}

.card__footer {
  margin: 0 calc(var(--card-gutter-x) * -1) -1.6rem calc(var(--card-gutter-x) * -1);
  padding: 1.4667rem 0 1.7333rem;
  border-top: 1px solid var(--border-grey-color);
  background-color: var(--card-footer-background);
  border-radius: 0 0 var(--card-border-radius) var(--card-border-radius);
}

@media (max-width: 1365px) {
  .card__footer {
    margin-bottom: -1.5rem;
  }
}

@media (min-width: 768px) {
  .card__footer--md {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .card__footer--lg {
    padding-top: 2.8rem;
    padding-bottom: 2.8rem;
  }
}

.card__chart-progress {
  width: 100%;
  flex-grow: 1;
}

.card__chart-progress-item {
  margin-bottom: 1.0667rem;
}

.card__chart-progress-item:last-child {
  margin-bottom: 0;
}

.card__stat {
  width: 100%;
}

.card__stat td {
  padding: 6px 0;
}

.card__stat td > * {
  vertical-align: middle;
}

.card__stat .signal {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 8px;
  background-color: currentColor;
  border-radius: 50%;
}

.card__credit-card .credit-card {
  margin: 0 auto 1.4667rem;
}

.card__credit-list {
  display: flex;
  justify-content: center;
}

.card__credit-list .card-list {
  width: 100%;
  max-width: 280px;
}

@media (min-width: 1200px) {
  .card__new-credits {
    height: 340px;
  }
}

.card__credit-button {
  width: 100%;
  max-width: 18.6667rem;
  margin: auto;
}

.card__credits-items {
  align-items: center;
  justify-content: center;
}

@media (min-width: 1200px) {
  .card__credits-items {
    flex-direction: column;
  }
}

.card__credits-item {
  margin-bottom: 10px;
}

.card__countries-progressbar {
  margin-bottom: 2.6667rem;
}

@media (max-width: 575px) {
  .card__countries-progressbar {
    margin-bottom: 1.8rem;
  }
}

@media (min-width: 992px) {
  .card__transactions {
    height: 27.9333rem;
  }
}

.card__table {
  overflow: hidden;
  border-radius: 0 0 var(--card-border-radius) var(--card-border-radius);
}

.card__table .table {
  margin-bottom: 0;
}

.card__table .table th:first-child,
.card__table .table td:first-child {
  padding-left: var(--card-gutter-x);
}

.card__table .table th:last-child,
.card__table .table td:last-child {
  padding-right: var(--card-gutter-x);
}

.card__table .table th.items-more,
.card__table .table td.items-more {
  max-width: calc(29px + var(--card-gutter-x));
  padding-left: 0;
}

.card__table .table tr:last-child td {
  border-bottom: none;
}

.card__table-transactions {
  height: 430px;
}

@media (max-width: 767px) {
  .card__table-transactions {
    height: auto;
  }
}

.card__table-transactions .colgroup-1 {
  width: 120px;
}

.card__table-transactions .colgroup-2 {
  width: 190px;
}

.card__table-transactions .colgroup-3 {
  width: 15%;
}

.card__table-transactions .colgroup-4 {
  width: 12%;
}

.card__table-transactions .colgroup-5 {
  width: 290px;
}

.card__table-transactions .colgroup-6 {
  width: 124px;
}

.card__table-transactions .table th:nth-child(2), .card__table-transactions .table th:nth-child(5) {
  min-width: 190px;
}

@media (min-width: 992px) {
  .card__review-list {
    height: 25.3333rem;
  }
}

@media (max-width: 575px) {
  .card-widget--xl {
    width: 100%;
    text-align: center;
  }
}

.card-widget__title {
  margin-top: 0;
  margin-bottom: 1.13333em;
  font-size: 1rem;
  line-height: 1.2em;
}

.card-widget--xl .card-widget__title {
  margin-bottom: 1.33333em;
  font-size: 1.2rem;
  line-height: 1.16667em;
}

@media (max-width: 575px) {
  .card-widget--xl .card-widget__title {
    margin-bottom: 1rem;
  }
}

.card-widget__count {
  display: inline-block;
  vertical-align: middle;
  margin-right: 6px;
  font-size: 2.4rem;
  line-height: 1.16667em;
}

@media (max-width: 1199px) {
  .card-widget__count {
    font-size: 1.8667rem;
  }
}

.card-widget--sm .card-widget__count {
  font-size: 1.8667rem;
}

@media (max-width: 1199px) {
  .card-widget--sm .card-widget__count {
    font-size: 1.2rem;
  }
}

.card-widget--xl .card-widget__count {
  margin-right: 12px;
  font-weight: 500;
  font-size: 3.3333rem;
  line-height: 1.18em;
}

.card-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-gap: 1rem;
}

.card-list__item {
  display: flex;
  justify-content: space-between;
}

.card-list__title {
  color: var(--text-secondary-color);
}

.card-list__value {
  font-weight: 500;
  text-align: right;
}

.credit-card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 18.6667rem;
  height: 12rem;
  padding: 4.8rem 1.3333rem 2.2667rem;
  text-align: center;
  color: var(--white-color);
}

@media (max-width: 374px) {
  .credit-card {
    width: 246px;
  }
}

.credit-card--light {
  color: var(--grey-color-3);
}

.credit-card__image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.credit-card__content {
  position: relative;
  z-index: 1;
  flex-grow: 1;
}

.credit-card__number {
  display: block;
  margin: auto;
  font-size: 1.3333rem;
  line-height: 1.15em;
  letter-spacing: -0.015rem;
  color: inherit;
  background-color: transparent;
  border: 1px solid transparent;
  outline: none;
  text-align: center;
}

.credit-card__name {
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 11px;
  line-height: 1.18182em;
  text-transform: uppercase;
}

.credit-card__date {
  position: absolute;
  right: 0.2667rem;
  bottom: 0;
  font-size: 11px;
  text-transform: uppercase;
}

.add-card .form-group {
  margin-bottom: 2.6667rem;
}

.add-card__submit {
  padding-top: 0.4rem;
}

.card-transactions {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
}

.card-transactions__item {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4.6667rem;
  padding: 0 var(--card-gutter-x);
}

.card-transactions__item:not(:last-child) {
  border-bottom: 1px solid var(--border-grey-color);
}

.card-transactions__left {
  display: flex;
  margin-right: 1rem;
  font-weight: 500;
}

.card-transactions__icon {
  width: 2.8rem;
  height: 2.8rem;
  margin-right: 4px;
  color: var(--orange-color-2);
}

.card-transactions__icon svg {
  vertical-align: top;
  width: 100%;
  height: 100%;
}

.card-transactions__title {
  margin-bottom: 0.2667rem;
}

.card-transactions__details {
  text-align: right;
  font-weight: 500;
}

.card-transactions__time {
  color: var(--text-secondary-color);
}

.card-transactions__cost {
  margin-bottom: 0.2667rem;
}

.card-country .colgroup-1 {
  width: 70%;
}

.card-country .colgroup-2 {
  width: 30%;
}

.card-country__map {
  width: 100%;
  max-width: 370px;
  min-height: 200px;
  margin: auto;
}

@media (max-width: 575px) {
  .card-country__map {
    max-width: 300px;
  }
}

.card-country__flag {
  width: 28px;
  height: 20px;
  margin-right: 1rem;
  object-fit: contain;
}

.card-country__name {
  padding-right: 1.6rem;
  vertical-align: middle;
}

.card-country__sales {
  display: inline-block;
  vertical-align: middle;
  min-width: 6.2667rem;
}

.review-list__item {
  display: flex;
  margin-bottom: 1.3333rem;
}

@media (min-width: 1200px) {
  .review-list__item:last-of-type {
    margin-bottom: 4px;
  }
}

.review-list__avatar {
  flex-shrink: 0;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.3333rem;
  height: 3.3333rem;
  border-radius: 50%;
  margin-right: 1.6rem;
  font-size: 1rem;
  color: var(--white-color);
}

.review-list__avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

@media (max-width: 575px) {
  .review-list__avatar {
    margin-right: 1.2rem;
  }
}

.review-list__avatar-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
}

.review-list__right {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.review-list__name {
  flex-grow: 1;
  margin: 0 1rem 0 0;
  font-weight: 500;
}

.review-list__time {
  display: inline-block;
  font-size: 0.8667rem;
  color: var(--text-secondary-color);
}

.review-list .rating {
  margin-top: 0.6667rem;
  width: 100%;
}

@media (max-width: 575px) {
  .review-list .rating {
    margin-top: 0.25rem;
  }
}

.review-list__message {
  width: 100%;
  margin-top: 0.6667rem;
}

.review-list__text {
  font-size: 13px;
  line-height: 1.38462em;
  color: var(--text-secondary-color);
}

.review-list__text:last-child {
  margin-bottom: 0;
}

/* Forms */
.input, .input-group .select2-container .select2-selection--single, .input-group--white .select2-container .select2-selection--single, .input-group--append-lg .select2-container .select2-selection--single {
  display: block;
  width: 100%;
  height: 42px;
  padding: 11px var(--input-gutter-x);
  font-weight: 400;
  font-size: 16px;
  line-height: 1.125em;
  font-family: var(--font-family-default);
  color: inherit;
  background-color: var(--background-primary-color);
  border-radius: 5px;
  border: 1px solid transparent;
  outline: none;
  transition: var(--t-base);
  transition-property: border-color, transform, background-color, opacity;
}

.input[type="time"], .input-group .select2-container .select2-selection--single[type="time"], .input-group--white .select2-container .select2-selection--single[type="time"], .input-group--append-lg .select2-container .select2-selection--single[type="time"] {
  padding-right: 0;
}

.input[type="time"]::-webkit-calendar-picker-indicator, .input-group .select2-container .select2-selection--single[type="time"]::-webkit-calendar-picker-indicator, .input-group--white .select2-container .select2-selection--single[type="time"]::-webkit-calendar-picker-indicator, .input-group--append-lg .select2-container .select2-selection--single[type="time"]::-webkit-calendar-picker-indicator {
  width: 32px;
  height: 36px;
  background: none;
  cursor: pointer;
}

.input[type='password'], .input-group .select2-container .select2-selection--single[type='password'], .input-group--white .select2-container .select2-selection--single[type='password'], .input-group--append-lg .select2-container .select2-selection--single[type='password'] {
  letter-spacing: 0.15rem;
}

.input[type='password']::placeholder, .input-group .select2-container .select2-selection--single[type='password']::placeholder, .input-group--white .select2-container .select2-selection--single[type='password']::placeholder, .input-group--append-lg .select2-container .select2-selection--single[type='password']::placeholder {
  letter-spacing: normal;
}

.input::placeholder, .input-group .select2-container .select2-selection--single::placeholder, .input-group--white .select2-container .select2-selection--single::placeholder, .input-group--append-lg .select2-container .select2-selection--single::placeholder {
  color: var(--placeholder-color);
}

.input:hover, .input-group .select2-container .select2-selection--single:hover, .input-group--white .select2-container .select2-selection--single:hover, .input-group--append-lg .select2-container .select2-selection--single:hover {
  background-color: var(--control-background-hover);
}

.input[readonly], .input-group .select2-container .select2-selection--single[readonly], .input-group--white .select2-container .select2-selection--single[readonly], .input-group--append-lg .select2-container .select2-selection--single[readonly] {
  user-select: none;
}

.input--edit {
  display: inline-block;
  width: auto;
  background-color: transparent;
}

.input--edit:focus {
  background-color: var(--control-background-hover);
}

.input-group--white .input, .input-group .select2-container .input-group--white .select2-selection--single, .input-group--white .select2-container .select2-selection--single, .input-group--append-lg .select2-container .input-group--white .select2-selection--single {
  background-color: var(--background-secondary-color);
  filter: drop-shadow(0px 4px 4px rgba(169, 194, 209, 0.05)) drop-shadow(0px 8px 16px rgba(169, 194, 209, 0.1));
}

.input-group--white .input:hover, .input-group .select2-container .input-group--white .select2-selection--single:hover, .input-group--white .select2-container .select2-selection--single:hover, .input-group--append-lg .select2-container .input-group--white .select2-selection--single:hover {
  background-color: var(--control-background-hover);
}

.input--fluid {
  width: 100%;
}

.input--fluid ~ .select2 {
  width: 100% !important;
}

.input--select {
  cursor: pointer;
}

.input--select:focus ~ .dropdown-items {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.input-group--white .input--select {
  background-color: var(--background-secondary-color);
}

.input-group--prepend .input, .input-group--prepend .input-group .select2-container .select2-selection--single, .input-group .select2-container .input-group--prepend .select2-selection--single, .input-group--prepend .input-group--white .select2-container .select2-selection--single, .input-group--white .select2-container .input-group--prepend .select2-selection--single, .input-group--prepend .input-group--append-lg .select2-container .select2-selection--single, .input-group--append-lg .select2-container .input-group--prepend .select2-selection--single {
  padding-left: 3.1333rem;
}

.input-group--prepend-xs .input, .input-group--prepend-xs .input-group .select2-container .select2-selection--single, .input-group .select2-container .input-group--prepend-xs .select2-selection--single, .input-group--prepend-xs .input-group--white .select2-container .select2-selection--single, .input-group--white .select2-container .input-group--prepend-xs .select2-selection--single, .input-group--prepend-xs .input-group--append-lg .select2-container .select2-selection--single, .input-group--append-lg .select2-container .input-group--prepend-xs .select2-selection--single {
  padding-left: 1.8rem;
}

.input-group--append .input, .input-group--append .input-group .select2-container .select2-selection--single, .input-group .select2-container .input-group--append .select2-selection--single, .input-group--append .input-group--white .select2-container .select2-selection--single, .input-group--white .select2-container .input-group--append .select2-selection--single, .input-group--append .input-group--append-lg .select2-container .select2-selection--single, .input-group--append-lg .select2-container .input-group--append .select2-selection--single {
  padding-right: 3.1333rem;
}

.input-group--append-lg .input, .input-group .select2-container .input-group--append-lg .select2-selection--single, .input-group--white .select2-container .input-group--append-lg .select2-selection--single, .input-group--append-lg .select2-container .select2-selection--single {
  padding-right: 3.8667rem;
}

.select2-container {
  width: 100%;
  font-size: inherit;
}

.select2-container .select2-selection--single {
  border: none;
  background-color: transparent;
  border-radius: 0;
  outline: none;
}

.input-group .select2-container .select2-selection--single {
  display: flex;
  align-items: center;
  border-radius: var(--radius-base) !important;
}

.select2-container .select2-selection {
  display: flex;
  align-items: center;
  color: inherit;
}

.select2-container .select2-selection .select2-selection__rendered {
  padding: 0;
  line-height: inherit;
  color: var(--text-primary-color);
  text-overflow: clip;
}

.select2-container .select2-selection .select2-search__field {
  color: inherit;
}

.select2-container .select2-selection .select2-selection__placeholder {
  color: var(--placeholder-color);
}

.select2-container .select2-selection .select2-selection__arrow {
  display: none;
}

.select2-container .select2-selection .select2-selection__arrow [role="presentation"] {
  display: none;
}

.select2-container .select2-selection--multiple {
  --labels-gutter: 8px;
  min-height: 40px;
  padding: var(--labels-gutter);
  border: none !important;
  border-radius: var(--radius-base);
  background-color: var(--background-primary-color);
  transition: var(--t-base);
  transition-property: background-color;
}

.select2-container .select2-selection--multiple:hover {
  background-color: var(--control-background-hover);
}

.select2-container .select2-selection--multiple .select2-search__field {
  margin-top: var(--labels-gutter);
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  width: auto;
  padding: 0;
  margin: calc(var(--labels-gutter) * -1) calc(var(--labels-gutter) * -1) 0 0;
}

.select2-container .select2-selection--multiple .select2-selection__choice {
  position: relative;
  min-height: 22px;
  padding: 0 26px 0 8px;
  margin-right: var(--labels-gutter);
  margin-top: var(--labels-gutter);
  border: none;
  border-radius: var(--radius-base);
  font-size: 13px;
  line-height: 24px;
  background-color: var(--grey-light-color-3);
  animation: selectAddTag 0.3s linear forwards;
}

.select2-container .select2-selection--multiple .select2-selection__choice > .select2-selection__choice__remove {
  text-indent: -9999px;
  pointer-events: all;
}

.select2-container .select2-selection--multiple .select2-selection__choice__remove {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 22px;
  padding: 4px;
  margin: 0;
  border-radius: 0 var(--radius-base) var(--radius-base) 0;
  color: inherit !important;
  transition: var(--t-base);
  pointer-events: none;
  opacity: 0.6;
}

.select2-container .select2-selection--multiple .select2-selection__choice__remove:hover {
  background-color: var(--control-background-hover);
  opacity: 1;
}

.select2-container .select2-selection--multiple .select2-selection__choice__remove svg[class*="icon"] {
  width: 14px;
  height: 14px;
}

.select2-container--open .select2-selection__arrow {
  transform: rotate(-180deg);
}

.select2-container .select2-dropdown {
  padding: 6px 0;
  background-color: var(--background-secondary-color);
  border: 1px solid var(--border-grey-color);
  border-radius: var(--card-border-radius);
}

.select2-container .select2-dropdown--below {
  margin-top: 2px;
}

.select2-container .select2-dropdown--above {
  margin-top: -2px;
}

.select2-container .select2-results .select2-results__options {
  max-height: 228px;
}

.select2-container .select2-results__option {
  padding-right: 3.1333rem;
  white-space: nowrap;
}

.select2-container .select2-results__option[aria-selected="true"]::after {
  position: absolute;
  right: var(--input-gutter-x);
  top: 50%;
  transform: translateY(-50%);
  margin-top: -1px;
  content: '\E5CA';
  font-family: var(--font-icons);
}

.select2-container .select2-results__option[aria-selected="true"], .select2-container .select2-results__option--highlighted[aria-selected] {
  color: var(--text-primary-color);
  background-color: var(--grey-light-color-1);
}

textarea.input, .input-group .select2-container textarea.select2-selection--single, .input-group--white .select2-container textarea.select2-selection--single, .input-group--append-lg .select2-container textarea.select2-selection--single {
  height: auto;
  font-size: inherit;
  line-height: inherit;
}

.form-group--lg {
  margin-bottom: 2rem;
}

@media (max-width: 575px) {
  .form-group--lg {
    margin-bottom: 1.5rem;
  }
}

.form-group--inline {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.form-group--label {
  margin-bottom: 0.8rem;
}

.input-group {
  position: relative;
}

.input-group__prepend, .input-group__append {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  color: var(--text-secondary-color);
  pointer-events: none;
}

.input-group__prepend--default, .input-group__append--default {
  color: inherit;
}

.input-group__prepend svg, .input-group__append svg {
  width: 22px;
  height: 22px;
  color: currentColor;
}

.input-group__prepend {
  left: 14px;
}

.input-group__append {
  right: 14px;
}

.input-group__symbol {
  padding-left: 6px;
  font-weight: 700;
  font-size: 16px;
}

.input-group__arrow {
  position: absolute;
  top: 0;
  right: var(--input-gutter-x);
  bottom: 0;
  width: 11px;
  height: 6px;
  margin: auto;
  color: var(--text-secondary-color);
  pointer-events: none;
}

.input-group--white .input-group__arrow {
  color: var(--grey-color-4);
}

.input-group__arrow svg {
  vertical-align: top;
  width: 100%;
  height: 100%;
  color: currentColor;
}

.input-group__arrows {
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  width: 22px;
  height: 36px;
  margin: auto;
  background: url(__static__content/arrow-double.svg) no-repeat center center;
  cursor: pointer;
  pointer-events: none;
}

html[data-theme="dark"] .input-group__arrows {
  background-image: url(__static__content/arrow-double-dark.svg);
}

.form-label {
  margin-top: 0;
  margin-bottom: 1.0667rem;
  font-weight: 400;
  font-size: inherit;
  color: var(--text-secondary-color);
}

.form-label--sm {
  margin-bottom: 0.8rem;
  line-height: 1.2em;
}

.form-group--inline .form-label {
  margin: 0 8px 0 0;
  white-space: nowrap;
}

.form-group--inline .form-label--append {
  order: 1;
  margin-right: 0;
  margin-left: 1rem;
  color: inherit;
}

.rating {
  display: flex;
  align-items: center;
}

.rating[data-readonly] {
  pointer-events: none;
}

.rating .jq-star {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.rating--md .jq-star {
  width: 25px !important;
  height: 25px !important;
}

.rating .jq-star-svg {
  vertical-align: top;
  padding-right: 4px;
  width: 100%;
  height: 100%;
  color: var(--orange-color-2);
}

.rating .jq-star-svg [class*="svg"] {
  transition: var(--t-base);
  transition-property: fill;
  transition-duration: 80ms;
}

.rating .jq-star-svg [class*="svg-empty"] {
  fill: var(--grey-color-4);
}

.rating .jq-star-svg [class*="svg-hovered"],
.rating .jq-star-svg [class*="svg-active"],
.rating .jq-star-svg [class*="svg-rated"] {
  fill: currentColor;
}

.checkbox,
.radio {
  --transition-duration: 110ms;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding-left: 16px;
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: 13px;
  line-height: 1.15385em;
  transition: var(--t-base);
  transition-duration: var(--transition-duration);
}

.checkbox.disabled,
.radio.disabled {
  pointer-events: none;
}

.checkbox input[type="checkbox"], .checkbox input[type="radio"],
.radio input[type="checkbox"],
.radio input[type="radio"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  cursor: pointer;
}

.checkbox input[type="checkbox"][disabled] ~ .checkbox__marker,
.checkbox input[type="checkbox"][disabled] ~ .radio__marker,
.checkbox input[type="checkbox"].disabled ~ .checkbox__marker,
.checkbox input[type="checkbox"].disabled ~ .radio__marker, .checkbox input[type="radio"][disabled] ~ .checkbox__marker,
.checkbox input[type="radio"][disabled] ~ .radio__marker,
.checkbox input[type="radio"].disabled ~ .checkbox__marker,
.checkbox input[type="radio"].disabled ~ .radio__marker,
.radio input[type="checkbox"][disabled] ~ .checkbox__marker,
.radio input[type="checkbox"][disabled] ~ .radio__marker,
.radio input[type="checkbox"].disabled ~ .checkbox__marker,
.radio input[type="checkbox"].disabled ~ .radio__marker,
.radio input[type="radio"][disabled] ~ .checkbox__marker,
.radio input[type="radio"][disabled] ~ .radio__marker,
.radio input[type="radio"].disabled ~ .checkbox__marker,
.radio input[type="radio"].disabled ~ .radio__marker {
  color: var(--text-secondary-color);
  fill: var(--text-secondary-color);
  border-color: var(--background-secondary-color);
  background-color: var(--background-secondary-color);
  pointer-events: none;
}

.checkbox__label,
.radio__label {
  display: inline-block;
  width: 100%;
  padding-top: 1px;
  margin-left: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
}

.checkbox__marker,
.radio__marker {
  position: absolute;
  top: 50%;
  left: 0;
  display: flex;
  width: 16px;
  height: 16px;
  line-height: 0;
  border-radius: 4px;
  color: var(--white-color);
  border: 1px solid var(--grey-light-color-3);
  transform: translateY(-50%);
  background-color: var(--grey-light-color-1);
  cursor: pointer;
  transition: var(--t-base);
  transition-duration: var(--transition-duration);
}

.checkbox--circle .checkbox__marker, .checkbox--circle
.radio__marker {
  width: 18px;
  height: 18px;
  border: none;
  background-color: transparent;
}

.checkbox--circle .checkbox__marker::before, .checkbox--circle
.radio__marker::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  opacity: 1;
  background-image: url(__static__/content/checkbox.svg);
  transition: var(--t-base);
  transition-property: opacity;
  transition-duration: var(--transition-duration);
}

.checkbox__marker-icon,
.radio__marker-icon {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 100%;
  padding-top: 2px;
  margin: auto;
  opacity: 0;
  transition: var(--t-base);
  transition-property: opacity;
  transition-duration: var(--transition-duration);
}

.checkbox--circle .checkbox__marker-icon, .checkbox--circle
.radio__marker-icon {
  width: 100%;
  height: 100%;
  background-image: url("__static__content/checkbox-checked.svg");
}

.checkbox__marker-icon svg,
.radio__marker-icon svg {
  vertical-align: top;
  width: 100%;
  height: 100%;
}

.checkbox--circle .checkbox__marker-icon svg, .checkbox--circle
.radio__marker-icon svg {
  display: none;
}

.checkbox:not(.checkbox--circle) input:checked:not(.disabled):not(:disabled) ~ .checkbox__marker {
  border-color: var(--primary-color);
  background-color: var(--primary-color);
}

.checkbox--circle input:checked:not(.disabled):not(:disabled) ~ .checkbox__marker::before {
  opacity: 0;
}

.checkbox input:checked:not(.disabled):not(:disabled) ~ .checkbox__marker .checkbox__marker-icon {
  opacity: 1;
}

.radio input:checked:not(.disabled):not(:disabled) ~ .radio__marker {
  border-color: var(--primary-color);
  background-color: var(--primary-color);
}

.radio input:checked:not(.disabled):not(:disabled) ~ .radio__marker .radio__marker-icon {
  opacity: 1;
}

.checkbox-toggle {
  display: inline-block;
  width: 40px;
  height: 24px;
  padding: 0;
  margin: 0;
  background-color: var(--grey-light-color-4);
  border-radius: 50px;
  line-height: 1;
  cursor: pointer;
  transition: var(--t-base);
}

.checkbox-toggle.is-active {
  background-color: var(--primary-color);
}

.checkbox-toggle input[type='checkbox'] {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.checkbox-toggle__range {
  display: block;
  width: 18px;
  height: 18px;
  margin: 3px;
  border-radius: 50%;
  box-shadow: 0px 2px 2px rgba(68, 86, 108, 0.5);
  background-color: var(--white-color);
  transition: var(--t-base);
}

.checkbox-toggle.is-active .checkbox-toggle__range {
  transform: translateX(16px);
}

.input-editor {
  border-radius: var(--radius-base);
  background-color: var(--background-primary-color);
}

.input-editor .ql-snow.ql-toolbar {
  font-family: inherit;
  border: none;
  border-bottom: 1px solid var(--border-grey-color);
  color: var(--text-secondary-color);
}

.input-editor .ql-snow.ql-toolbar .ql-picker-options {
  padding: 6px;
  background-color: var(--background-secondary-color);
  border: 1px solid var(--border-grey-color) !important;
  border-radius: var(--card-border-radius);
  box-shadow: none;
}

.input-editor .ql-snow.ql-container {
  border: none;
}

.input-editor .ql-snow .ql-picker {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
}

.input-editor .ql-snow .ql-stroke {
  stroke: currentColor;
}

.input-editor .ql-snow .ql-picker.ql-header {
  width: auto;
  margin-right: 10px;
}

@media (max-width: 575px) {
  .input-editor .ql-snow .ql-picker.ql-header {
    margin-right: 0;
  }
}

.input-editor .ql-snow .ql-picker-label {
  padding-right: 16px;
}

.input-editor .ql-snow .ql-picker-label::before {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@media (max-width: 575px) {
  .input-editor .ql-snow .ql-picker-label::before {
    width: 54px;
  }
}

.input-editor .ql-toolbar.ql-snow .ql-formats {
  margin-right: 0;
  border-right: 1px solid var(--border-grey-color);
}

@media (max-width: 575px) {
  .input-editor .ql-toolbar.ql-snow .ql-formats {
    border-right-width: 0;
  }
}

.input-editor .ql-toolbar.ql-snow .ql-formats:last-child {
  border: none;
}

.input-editor .ql-snow.ql-toolbar button,
.input-editor .ql-snow .ql-toolbar button {
  width: 32px;
  height: 26px;
  padding: 2px 8px;
}

@media (max-width: 575px) {
  .input-editor .ql-snow.ql-toolbar button,
  .input-editor .ql-snow .ql-toolbar button {
    width: 24px;
    padding: 2px;
  }
}

.input-editor .ql-snow.ql-toolbar button svg,
.input-editor .ql-snow .ql-toolbar button svg {
  vertical-align: top;
  width: 100%;
  height: 100%;
}

.input-editor .ql-editor {
  min-height: 9.3333rem;
  padding: 13px 18px 20px;
}

.input-editor .ql-container {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.input-editor .ql-container p {
  font-size: inherit;
  line-height: 1.6em;
  color: inherit;
}

.input-editor .ql-snow.ql-toolbar button:hover,
.input-editor .ql-snow .ql-toolbar button:hover,
.input-editor .ql-snow.ql-toolbar button:focus,
.input-editor .ql-snow .ql-toolbar button:focus,
.input-editor .ql-snow.ql-toolbar button.ql-active,
.input-editor .ql-snow .ql-toolbar button.ql-active,
.input-editor .ql-snow.ql-toolbar .ql-picker-label:hover,
.input-editor .ql-snow .ql-toolbar .ql-picker-label:hover,
.input-editor .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.input-editor .ql-snow .ql-toolbar .ql-picker-label.ql-active,
.input-editor .ql-snow.ql-toolbar .ql-picker-item:hover,
.input-editor .ql-snow .ql-toolbar .ql-picker-item:hover,
.input-editor .ql-snow.ql-toolbar .ql-picker-item.ql-selected, .input-editor .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: var(--blue-color);
}

.input-editor .ql-snow.ql-toolbar button:hover .ql-stroke,
.input-editor .ql-snow .ql-toolbar button:hover .ql-stroke,
.input-editor .ql-snow.ql-toolbar button:focus .ql-stroke,
.input-editor .ql-snow .ql-toolbar button:focus .ql-stroke,
.input-editor .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.input-editor .ql-snow .ql-toolbar button.ql-active .ql-stroke,
.input-editor .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.input-editor .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.input-editor .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.input-editor .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.input-editor .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.input-editor .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.input-editor .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.input-editor .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.input-editor .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.input-editor .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.input-editor .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.input-editor .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.input-editor .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.input-editor .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.input-editor .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.input-editor .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.input-editor .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.input-editor .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.input-editor .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.input-editor .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.input-editor .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.input-editor .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: var(--blue-color);
}

/* Tags */
.tags {
  --labels-gutter: 8px;
  min-height: 40px;
  padding: var(--labels-gutter);
  border-radius: var(--radius-base);
  background-color: var(--background-primary-color);
}

.tags__container {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 calc(var(--labels-gutter) * -1) calc(var(--labels-gutter) * -1) 0;
}

.tags__item {
  margin: 0 var(--labels-gutter) var(--labels-gutter) 0;
}

.tag {
  position: relative;
  min-height: 24px;
  display: inline-flex;
  align-items: center;
  padding: 0 7px 0 8px;
  border-radius: var(--radius-base);
  font-size: 13px;
  line-height: 24px;
}

.tag.is-animating {
  animation: selectTagRemove 300ms linear forwards;
  pointer-events: none;
}

.tag--append {
  padding-right: 26px;
}

.tag__marker {
  width: 8px;
  height: 8px;
  margin-right: 7px;
  border-radius: 50%;
}

.tag__append {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 0 var(--radius-base) var(--radius-base) 0;
  transition: var(--t-base);
}

.tag__append svg[class*="icon"] {
  width: 14px;
  height: 14px;
}

.tag--default {
  background-color: var(--grey-light-color-3);
}

.tag--default .tag__append:hover {
  background-color: var(--control-background-hover);
}

@keyframes selectTagRemove {
  0% {
    transform: translateX(0) rotate(0deg);
  }
  100% {
    transform: translateX(-5px) rotate(5deg);
    opacity: 0;
  }
}

/* Files Upload */
.image-upload {
  --progress-indicator-size: 32px;
}

.image-upload__drop {
  position: relative;
  min-height: 6.6667rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-radius: var(--radius-base);
  border: 1px dashed var(--border-grey-color);
  background-color: var(--background-primary-color);
  color: inherit;
  transition: var(--t-base);
}

.image-upload__drop:hover,
.image-upload.highlight .image-upload__drop {
  border-color: var(--blue-color);
}

.image-upload__input {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: var(--radius-base);
  opacity: 0;
  z-index: 1;
  pointer-events: none;
}

.image-upload__drop-text svg {
  width: 2rem;
  height: 2rem;
}

.image-upload__drop-text span,
.image-upload__drop-text label {
  display: inline;
  vertical-align: middle;
  margin: 0;
}

.image-upload__drop-action {
  position: relative;
  cursor: pointer;
  color: var(--blue-color);
  z-index: 2;
}

.image-upload__list {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 1em;
  display: grid;
  grid-gap: 1.1333rem;
  grid-template-columns: repeat(5, 1fr);
}

@media (max-width: 575px) {
  .image-upload__list {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 8px;
  }
}

.image-upload__item {
  position: relative;
  min-height: 4.6667rem;
  border: 1px solid var(--border-grey-color);
  border-radius: var(--radius-base);
  background-image: url(__static__content/upload-placeholder.svg);
  background-size: cover;
  background-position: center center;
  animation: fileItemAdded 0.3s linear forwards;
  overflow: hidden;
}

.image-upload__preview-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: 50% 50%;
  cursor: pointer;
  animation: fileItemAdded 0.3s linear forwards;
}

.image-upload__progress {
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: var(--background-primary-color);
  opacity: 0;
  pointer-events: none;
}

.image-upload__progress::before {
  content: " ";
  height: var(--progress-indicator-size);
  width: var(--progress-indicator-size);
  background-image: url(__static__content/upload-progress.svg);
  background-size: 100% 100%;
  animation: infinite rotate 1s linear;
}

.image-upload__action-remove {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: var(--background-primary-color);
  transition: var(--t-base);
  pointer-events: none;
  opacity: 0;
}

.image-upload__item.is-active:hover .image-upload__action-remove {
  opacity: 1;
}

.image-upload__action-remove::before {
  position: absolute;
  top: 50%;
  left: 50%;
  content: " ";
  width: 2.1333rem;
  height: 2.1333rem;
  background-color: var(--border-grey-color);
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.image-upload__action-remove svg {
  position: relative;
  display: block;
  width: 1.6667rem;
  height: 1.6667rem;
  margin: auto;
  z-index: 1;
}

.image-upload .filepond--root {
  margin-bottom: 0;
  height: auto !important;
  contain: layout style;
}

.image-upload [data-hopper-state="drag-over"] .filepond--drop-label {
  border-color: var(--blue-color);
}

.image-upload .filepond--drop-label {
  position: relative;
  min-height: 6.6667rem;
  border-radius: var(--radius-base);
  border: 1px dashed var(--border-grey-color);
  background-color: var(--background-primary-color);
  color: inherit;
  transition: var(--t-base);
}

.image-upload .filepond--drop-label:hover {
  border-color: var(--blue-color);
}

.image-upload .filepond--drop-label label {
  font-weight: inherit;
  font-size: inherit;
  line-height: 1;
}

.image-upload .filepond--drop-label svg {
  width: 2rem;
  height: 2rem;
}

.image-upload .filepond--drop-label span {
  vertical-align: middle;
}

.image-upload .filepond--label-action {
  text-decoration: none;
}

.image-upload .filepond--list-scroller {
  position: static;
  margin-bottom: 0;
  transform: none !important;
}

.image-upload .filepond--list {
  position: static;
  right: auto;
  left: auto;
  display: grid;
  grid-gap: 17px;
  grid-template-columns: repeat(5, 1fr);
}

.image-upload .filepond--item {
  position: relative;
  height: 70px !important;
  margin: 0;
  border: 1px dashed var(--border-grey-color);
  border-radius: var(--radius-base);
  transform: none !important;
  background-image: url(__static__content/upload-placeholder.svg);
  background-size: cover;
  background-position: center center;
  overflow: hidden;
  animation: fileItemAdded 600ms ease-out forwards;
}

.image-upload .filepond--item:hover .filepond--file-action-button {
  opacity: 1 !important;
}

.image-upload .filepond--file {
  color: inherit;
}

.image-upload .filepond--image-clip {
  width: 100% !important;
  height: 100% !important;
}

.image-upload .filepond--image-preview-overlay {
  display: none;
}

.image-upload .filepond--image-canvas-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: 50% 50% !important;
  transform: translate(-50%, -50%) !important;
}

.image-upload .filepond--image-preview-wrapper {
  background-color: transparent;
}

.image-upload .filepond--progress-indicator {
  top: 0;
  left: 0;
  display: flex;
  display: none;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: var(--background-primary-color);
  opacity: 1 !important;
  visibility: visible !important;
}

.image-upload .filepond--progress-indicator::before {
  content: " ";
  height: var(--progress-indicator-size);
  width: var(--progress-indicator-size);
  background-image: url(__static__content/upload-progress.svg);
  background-size: 100% 100%;
  animation: infinite rotate 1s linear;
}

.image-upload .filepond--progress-indicator svg {
  display: none;
}

.image-upload .filepond--file-action-button {
  opacity: 0 !important;
  color: inherit;
  box-shadow: none;
  border-radius: var(--radius-base);
  background-color: var(--background-primary-color);
  transition: var(--t-base);
}

.image-upload .filepond--file-action-button.filepond--file-action-button {
  top: 0;
  left: 0 !important;
  width: 100%;
  height: 100%;
}

.image-upload .filepond--file-action-button.filepond--file-action-button .icon-wrapper {
  position: absolute;
  display: flex;
  top: 50%;
  left: 50%;
  width: 2.1333rem;
  height: 2.1333rem;
  border-radius: 50%;
  background-color: var(--border-grey-color);
  transform: translate(-50%, -50%);
}

.image-upload .filepond--file-action-button.filepond--file-action-button .icon-wrapper svg {
  display: block;
  width: 1.6667rem;
  height: 1.6667rem;
  margin: auto;
}

.image-upload .filepond--panel {
  opacity: 0;
}

.profile-upload__input {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.profile-upload__overlay {
  transition: var(--t-base);
}

.profile-upload:hover .profile-upload__overlay {
  opacity: 0.4;
}

.profile-upload.highlight .profile-upload__overlay {
  opacity: 0.6;
}

.profile-upload.is-animate .profile-upload__image {
  animation: fileProfileImage 0.3s linear forwards;
}

.profile-upload__label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
}

.files-upload__drop {
  position: relative;
  min-height: 6.6667rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-radius: var(--radius-base);
  border: 1px dashed var(--border-grey-color);
  background-color: var(--background-primary-color);
  color: inherit;
  transition: var(--t-base);
}

.files-upload__drop:hover,
.image-upload.highlight .files-upload__drop {
  border-color: var(--blue-color);
}

.files-upload__input {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: var(--radius-base);
  opacity: 0;
  z-index: 1;
  pointer-events: none;
}

.files-upload__drop-text svg {
  width: 2rem;
  height: 2rem;
}

.files-upload__drop-text span,
.files-upload__drop-text label {
  display: inline;
  vertical-align: middle;
  margin: 0;
}

.files-upload__drop-action {
  position: relative;
  cursor: pointer;
  color: var(--blue-color);
  z-index: 2;
}

.files-upload__items {
  height: 16rem;
  margin-top: 2.3333rem;
}

.files-upload__items .simplebar-track.simplebar-vertical {
  right: -13px;
}

.files-upload__list {
  list-style: none;
  display: grid;
  grid-gap: 8px;
  padding: 0;
  margin: 0;
}

.files-upload__item {
  animation: fileItemAdded 0.3s linear forwards;
}

.files-upload__item .media-file__upload {
  opacity: 0;
  pointer-events: none;
}

.files-upload__item--progress .media-file__upload {
  opacity: 1;
  pointer-events: all;
}

.media-file {
  display: grid;
  grid-template-columns: 32px 1fr;
  grid-gap: 1rem;
  align-items: center;
  min-width: 16.6667rem;
  padding: 16px;
  border-radius: var(--radius-base);
  background-color: var(--background-primary-color);
}

.media-file--full {
  min-width: auto;
}

.media-file__thumb {
  width: 100%;
  height: auto;
}

.media-file__right {
  position: relative;
  display: flex;
  align-items: center;
}

.media-file__prepend {
  flex-grow: 1;
}

.media-file__append .button-icon {
  margin-right: -11px;
}

.media-file__title {
  margin-bottom: 2px;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.15385em;
}

.media-file__upload {
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: 1fr auto auto;
  width: 100%;
  height: 100%;
  background-color: var(--background-primary-color);
}

.media-file__upload-text {
  flex-grow: 1;
  font-size: 13px;
  line-height: 1.15385em;
}

.media-file__upload-dismiss {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  padding: 0;
  border-radius: 50%;
  background-color: var(--text-primary-color);
  color: var(--white-color);
  transition: var(--t-base);
}

.media-file__upload-dismiss:hover, .media-file__upload-dismiss:active {
  opacity: 0.6;
}

.media-file__upload-dismiss svg {
  width: 100%;
  height: 100%;
}

.media-file__progressbar {
  grid-column: span 2;
  height: 5px;
  border-radius: 5px;
  background-color: var(--border-grey-color);
}

.media-file__progressbar-item {
  height: 100%;
  border-radius: 5px;
  background-color: var(--primary-color);
}

@keyframes fileItemAdded {
  0% {
    opacity: 0;
    transform: translateY(-1em);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes fileProfileImage {
  0% {
    opacity: 0;
    transform: translateY(-33%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Charts */
.chart-revenue,
.chart-activity,
.chart-overview,
.chart-statistics {
  min-height: 400px;
}

.chart-activity {
  min-height: 530px;
}

@media (max-width: 575px) {
  .chart-revenue,
  .chart-activity,
  .chart-overview,
  .chart-statistics,
  .chart-activity {
    min-height: 260px;
    height: 38vh;
  }
}

.chart-profit {
  width: 400px;
  margin: 2.6667rem 0;
}

@media (max-width: 575px) {
  .chart-profit {
    width: 340px;
  }
}

.chart-task {
  width: 470px;
}

@media (max-width: 575px) {
  .chart-task {
    width: 410px;
  }
}

.chart-legend {
  position: relative;
  z-index: 2;
  display: flex;
}

.chart-legend__button {
  display: inline-flex;
  align-items: center;
  margin-left: 46px;
}

.chart-legend__marker {
  display: inline-block;
  vertical-align: middle;
  width: 8px;
  height: 8px;
  margin-right: 8px;
  border-radius: 50%;
  background-color: currentColor;
}

.apexcharts-tooltip {
  padding: 0;
  background-color: transparent !important;
  border-width: 0 !important;
  box-shadow: none !important;
  overflow: initial !important;
}

.chart-tooltip-custom {
  position: relative;
  padding: 12px 17px;
  font-size: 15px;
  font-family: var(--font-family-default);
  line-height: 1.2em;
  border-radius: 4px;
  background-color: var(--background-secondary-color);
  color: var(--text-primary-color);
  box-shadow: 0 10px 60px rgba(166, 178, 191, 0.4);
  border: 1px solid var(--border-grey-color);
}

.chart-tooltip-custom::before, .chart-tooltip-custom::after {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  content: "";
  width: 0;
  height: 0;
  pointer-events: none;
}

.chart-tooltip-custom::before {
  border: 6px solid transparent;
  border-bottom-width: 0;
  border-top-color: var(--border-grey-color);
}

.chart-tooltip-custom::after {
  z-index: 1;
  border: 4px solid transparent;
  border-bottom-width: 0;
  border-top-color: var(--background-secondary-color);
}

.chart-tooltip-custom__items {
  display: flex;
  justify-content: center;
  align-items: center;
}

.chart-tooltip-custom__item {
  display: flex;
  align-items: center;
}

.chart-tooltip-custom__separate-item {
  align-self: center;
  height: 1.2em;
  margin: 0 8px;
  border-right: 1px solid var(--border-grey-color);
}

.chart-tooltip-custom__separate-item:last-of-type {
  display: none;
}

.chart-tooltip-custom__value {
  font-weight: 500;
}

.chart-tooltip-custom__title {
  margin-top: 8px;
  font-size: 13px;
  line-height: 0.86667em;
  text-align: center;
  color: var(--text-secondary-color);
}

.chart-tooltip-custom__marker {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  width: 8px;
  height: 8px;
  margin-right: 8px;
}

.chart-tooltip-custom__marker > * {
  flex-shrink: 0;
  width: 100% !important;
  height: 100% !important;
}

/* Page */
.page-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-height: 100vh;
  overflow: hidden;
}

.page-content {
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: calc(100% - var(--sidebar-full-width));
  padding-top: calc(var(--header-height) + 2.875rem);
  padding-bottom: 2.5333rem;
  transition: var(--t-base);
  transition-timing-function: linear;
}

.page-content--full {
  padding-top: var(--header-height);
  padding-bottom: 0;
}

.page-content--order-header {
  padding-top: calc(var(--header-height) + var(--order-tabs-height) + var(--order-tabs-offset-bottom));
}

@media (min-width: 1366px) {
  .sidebar-collapse .page-content {
    max-width: calc(100% - var(--sidebar-collapse-width));
  }
}

@media (max-width: 1365px) and (min-width: 768px) {
  .page-content {
    max-width: calc(100% - var(--sidebar-collapse-width));
  }
  .sidebar-show .page-content {
    transform: translateX(calc(var(--sidebar-full-width) - var(--sidebar-collapse-width)));
  }
}

@media (max-width: 767px) {
  .page-content {
    max-width: 100%;
    padding-bottom: 15px;
  }
  .page-content--full {
    padding-bottom: 0;
  }
  .sidebar-show .page-content {
    max-width: 100%;
    transform: translateX(calc(var(--sidebar-full-width)));
  }
}

@media (max-width: 374px) {
  .page-content {
    padding-bottom: 10px;
  }
  .page-content--full {
    padding-bottom: 0;
  }
}

.container {
  width: 100%;
  max-width: var(--container-width);
  margin-right: auto;
  margin-left: auto;
}

.container--fixed {
  max-width: 1200px;
}

.container,
.container-fluid {
  padding-right: var(--container-gutter);
  padding-left: var(--container-gutter);
}

.container--sm,
.container-fluid--sm {
  padding-right: var(--container-gutter-sm);
  padding-left: var(--container-gutter-sm);
}

.mw-140 {
  min-width: 140px;
}

.mw-160 {
  min-width: 160px;
}

.mw-180 {
  min-width: 180px;
}

.mw-200 {
  min-width: 200px;
}

.mw-240 {
  min-width: 240px;
}

.mw-260 {
  min-width: 260px;
}

.mw-280 {
  min-width: 280px;
}

.maxw-200 {
  max-width: 200px;
}

.maxw-240 {
  max-width: 240px;
}

.maxw-260 {
  max-width: 260px;
}

.maxw-280 {
  max-width: 280px;
}

.row:not(.no-gutters) {
  margin-right: calc(var(--column-gutter) * -1);
  margin-left: calc(var(--column-gutter) * -1);
}

.row:not(.no-gutters) > * {
  padding-right: var(--column-gutter);
  padding-left: var(--column-gutter);
}

.row--md:not(.no-gutters) {
  margin-right: calc(var(--column-gutter-md) * -1);
  margin-left: calc(var(--column-gutter-md) * -1);
}

.row--md:not(.no-gutters) > * {
  padding-right: var(--column-gutter-md);
  padding-left: var(--column-gutter-md);
}

.row--sm:not(.no-gutters) {
  margin-right: calc(var(--column-gutter-sm) * -1);
  margin-left: calc(var(--column-gutter-sm) * -1);
}

.row--sm:not(.no-gutters) > * {
  padding-right: var(--column-gutter-sm);
  padding-left: var(--column-gutter-sm);
}

.row--xs:not(.no-gutters) {
  margin-right: calc(var(--column-gutter-xs) * -1);
  margin-left: calc(var(--column-gutter-xs) * -1);
}

.row--xs:not(.no-gutters) > * {
  padding-right: var(--column-gutter-xs);
  padding-left: var(--column-gutter-xs);
}

.gutter-bottom-xl {
  margin-bottom: calc(var(--gutter-bottom-xl) * -1);
}

.gutter-bottom-xl > * {
  padding-bottom: var(--gutter-bottom-xl);
}

.gutter-bottom-lg {
  margin-bottom: calc(var(--gutter-bottom-lg) * -1);
}

.gutter-bottom-lg > * {
  padding-bottom: var(--gutter-bottom-lg);
}

.gutter-bottom-sm {
  margin-bottom: calc(var(--gutter-bottom-sm) * -1);
}

.gutter-bottom-sm > * {
  padding-bottom: var(--gutter-bottom-sm);
}

.gutter-bottom-xs {
  margin-bottom: calc(var(--gutter-bottom-xs) * -1);
}

.gutter-bottom-xs > * {
  padding-bottom: var(--gutter-bottom-xs);
}

.section {
  margin-top: calc(var(--column-gutter) * 2);
  margin-bottom: calc(var(--column-gutter) * 2);
}

.page-tools {
  display: flex;
  align-items: center;
  margin-bottom: 2.1333rem;
}

@media (max-width: 575px) {
  .page-tools {
    margin-bottom: 1.5rem;
  }
}

.page-tools__breadcrumbs {
  align-self: flex-end;
}

.page-tools__right {
  display: flex;
  margin-left: auto;
}

.page-tools__right-row {
  display: flex;
  margin-left: calc(var(--container-gutter-sm) * -1);
}

.page-tools__right-item {
  margin-left: var(--container-gutter-sm);
}

.toolbox {
  margin-bottom: 1.6rem;
}

@media (max-width: 575px) {
  .toolbox .select2 {
    width: 100% !important;
  }
}

@media (max-width: 991px) {
  .toolbox__left {
    order: 1;
  }
}

.toolbox__status {
  width: 150px;
}

@media (max-width: 575px) {
  .toolbox__status {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .toolbox__search {
    min-width: 290px;
  }
}

.page-error {
  position: relative;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 30px 15px;
  text-align: center;
  overflow: hidden;
  background: radial-gradient(circle at 50%, rgba(0, 129, 255, 0.05), rgba(0, 129, 255, 0.05) 50%, rgba(0, 129, 255, 0.04) 50%, rgba(0, 129, 255, 0.04) 66%, rgba(0, 129, 255, 0.03) 66%, rgba(0, 129, 255, 0.03) 82%, rgba(0, 129, 255, 0.01) 82%, rgba(0, 129, 255, 0.01) 100%);
}

.page-error__type {
  font-family: var(--font-family-third);
  font-weight: 900;
  font-size: 250px;
  line-height: 1.484em;
  color: var(--primary-color);
  text-shadow: 0px 8px 16px rgba(0, 129, 255, 0.2);
}

@media (max-width: 767px) {
  .page-error__type {
    font-size: 32vw;
  }
}

.page-error__title {
  margin-bottom: 1.8rem;
  font-size: 3.3333rem;
  line-height: 1.18em;
}

@media (max-width: 767px) {
  .page-error__title {
    font-size: 8vw;
  }
}

.page-error__bottom {
  margin-top: 2.6667rem;
}

/* Tables */
.table-wrapper__content {
  margin-bottom: 1.6rem;
}

@media (max-width: 575px) {
  .table-wrapper__content {
    margin-bottom: 1rem;
  }
}

.table-wrapper__content.table-collapse {
  position: relative;
  border-radius: var(--card-border-radius);
  filter: drop-shadow(0px 2px 4px rgba(169, 194, 209, 0.05)) drop-shadow(0px 12px 16px rgba(169, 194, 209, 0.1));
}

.table-wrapper__content.table-collapse::before {
  content: "";
  position: absolute;
  right: 10%;
  bottom: 0;
  left: 10%;
  height: 2.6667rem;
  z-index: -1;
  box-shadow: 0px 4px 32px rgba(169, 194, 209, 0.05), 0px 2px 16px rgba(169, 194, 209, 0.05);
  pointer-events: none;
}

.table-wrapper__content .table {
  margin-bottom: 0;
}

@media (max-width: 575px) {
  .table-wrapper__show-result {
    text-align: center;
    width: 100%;
    flex-basis: 100%;
    margin-top: -0.5rem;
  }
}

@media (max-width: 575px) {
  .table-wrapper__pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 0.5rem;
  }
}

.table {
  --height-row: 3.3333rem;
  --height-row-md: 4rem;
  color: inherit;
}

.table:not(.table--groups) > thead > tr > th:first-child {
  border-top-left-radius: var(--card-border-radius);
}

.table:not(.table--groups) > thead > tr > th:last-child {
  border-top-right-radius: var(--card-border-radius);
}

.table:not(.table--groups) > tbody > tr:last-child > td:first-child {
  border-bottom-left-radius: var(--card-border-radius);
}

.table:not(.table--groups) > tbody > tr:last-child > td:last-child {
  border-bottom-right-radius: var(--card-border-radius);
}

.table th,
.table td {
  padding: 4px 0.75rem;
  border: none;
  vertical-align: middle;
  background-color: var(--background-secondary-color);
}

.table th:first-child,
.table td:first-child {
  padding-left: 2.6667rem;
}

.table th:last-child,
.table td:last-child {
  padding-right: 2.6667rem;
}

@media (max-width: 575px) {
  .table th:first-child,
  .table td:first-child {
    padding-left: 1.6rem;
  }
  .table th:last-child,
  .table td:last-child {
    padding-right: 1.6rem;
  }
}

.table th {
  padding-bottom: 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 2em;
  text-transform: uppercase;
  border: none;
}

.table--spaces th {
  background-color: transparent;
}

.table--spaces thead th {
  border-bottom: none;
}

.table--lines thead th {
  border-bottom: 1px solid var(--border-grey-color);
}

.table--striped > tbody > tr:nth-child(2n - 1) > td {
  background-color: var(--background-primary-color);
}

.table__header--sticky th {
  position: sticky;
  top: 0;
  z-index: 1;
  border-radius: 0 !important;
}

.table__header-row {
  height: var(--height-row);
}

.table__header-row th {
  padding-bottom: 4px;
}

.table__header .table__header-row th {
  vertical-align: middle;
}

.table--groups .table__header-row {
  height: var(--height-row-md);
}

.table--groups .table__header-row th {
  padding-bottom: 4px;
  vertical-align: middle;
  text-transform: uppercase;
}

.table__row {
  height: var(--height-row);
}

.table--groups .table__row {
  height: var(--height-row-md);
}

.table--spaces .table__row {
  height: var(--height-row-md);
}

.table__space {
  height: 8px;
}

.table__space td {
  display: none;
}

.table__th-sort {
  cursor: pointer;
  user-select: none;
  transition: var(--t-base);
  transition-property: color;
  white-space: nowrap;
}

.table__th-sort:hover {
  color: var(--text-secondary-color);
}

.table--lines > tbody > tr:not(:last-child) .table__td {
  border-bottom: 1px solid var(--border-grey-color);
}

.table--groups > tbody > tr:last-child .table__td {
  border-bottom: 1px solid var(--border-grey-color);
}

.table--spaces > tbody > tr .table__td:first-child {
  border-radius: var(--radius-lg) 0 0 var(--radius-lg);
}

.table--spaces > tbody > tr .table__td:last-child {
  border-radius: 0 var(--radius-lg) var(--radius-lg) 0;
}

.table__status {
  display: inline-flex;
  align-items: center;
}

.table__status-icon {
  width: 6px;
  min-width: 6px;
  height: 6px;
  margin-right: 7px;
  border-radius: 50%;
}

.table__actions {
  width: 34px;
  min-width: 34px;
  text-align: right;
}

.table__actions .dropdown-items {
  right: -4px;
}

.table__remove {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  transition: var(--t-base);
}

.table__remove:hover {
  color: var(--text-primary-color);
  background-color: var(--control-background-hover);
}

.table__remove svg {
  width: 22px;
  height: 22px;
}

.table__icon {
  flex-shrink: 0;
  margin-right: 6px;
  width: 22px;
  height: 22px;
  fill: var(--text-secondary-color);
}

/* Sidebar */
.sidebar-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1010;
  display: none;
  width: 100%;
  height: 100%;
  background: var(--backdrop-color);
  opacity: 0;
  pointer-events: none;
  transition: var(--t-base);
  transition-timing-function: linear;
}

.sidebar-show .sidebar-backdrop {
  opacity: var(--backdrop-opacity);
  pointer-events: all;
}

@media (max-width: 1365px) and (min-width: 768px) {
  .sidebar-backdrop {
    display: block;
  }
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1010;
  height: 100%;
  display: flex;
  width: var(--sidebar-full-width);
  transition: var(--t-base);
  transition-property: width, opacity;
  transition-timing-function: linear;
}

@media (max-width: 1365px) {
  .sidebar {
    width: 100%;
  }
}

@media (min-width: 1366px) {
  .sidebar-collapse .sidebar {
    width: var(--sidebar-collapse-width);
  }
}

@media (max-width: 1365px) and (min-width: 768px) {
  .sidebar {
    width: var(--sidebar-collapse-width);
  }
  .sidebar:hover, .sidebar--show {
    width: var(--sidebar-full-width);
  }
}

@media (max-width: 767px) {
  .sidebar {
    width: 100%;
    transform: translateX(-100%);
    opacity: 0;
  }
  .sidebar--show {
    transform: translateX(0px);
    opacity: 1;
  }
}

.sidebar__backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--backdrop-color);
  opacity: 0;
  transition: var(--t-base);
}

.sidebar-show .sidebar__backdrop {
  opacity: var(--backdrop-opacity);
}

@media (min-width: 768px) {
  .sidebar__backdrop {
    display: none;
  }
}

.sidebar__container {
  flex-shrink: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--background-secondary-color);
  border-right: 1px solid var(--border-grey-color);
  z-index: 1;
  transition: var(--t-base);
  transition-property: width, transform;
  transition-timing-function: linear;
}

.sidebar__container,
.sidebar:hover .sidebar__container,
.sidebar--show .sidebar__container {
  width: var(--sidebar-full-width);
  pointer-events: all;
}

@media (max-width: 1365px) and (min-width: 768px) {
  .sidebar__container {
    width: 100%;
    pointer-events: none;
  }
}

@media (min-width: 1366px) {
  .sidebar-collapse .sidebar__container {
    width: 100%;
    pointer-events: none;
  }
}

@media (max-width: 767px) {
  .sidebar__container {
    width: var(--sidebar-full-width);
    transform: translateX(-100%);
  }
  .sidebar--translate .sidebar__container {
    transform: translateX(0);
  }
}

.sidebar__top {
  flex-shrink: 0;
  height: var(--header-height);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sidebar__logo {
  display: flex;
  align-items: center;
  font-family: var(--font-family-secondary);
  font-weight: 700;
  font-size: 1.875rem;
  line-height: 1;
  letter-spacing: 0.1em;
  white-space: nowrap;
  transition: var(--t-base);
  transition-property: transform;
}

.sidebar__logo,
.sidebar:hover .sidebar__logo,
.sidebar--show .sidebar__logo {
  transform: translateX(0px);
}

@media (max-width: 1365px) and (min-width: 768px) {
  .sidebar__logo {
    transform: translateX(-10px);
  }
}

@media (min-width: 1366px) {
  .sidebar-collapse .sidebar__logo {
    transform: translateX(-10px);
  }
}

.sidebar__logo-icon {
  flex-shrink: 0;
  min-width: 43px;
  width: 43px;
  margin-right: 5px;
}

.sidebar__logo-text {
  transition: var(--t-base);
}

.sidebar__logo-text,
.sidebar:hover .sidebar__logo-text,
.sidebar--show .sidebar__logo-text {
  opacity: 1;
}

@media (max-width: 1365px) and (min-width: 768px) {
  .sidebar__logo-text {
    opacity: 0;
  }
}

@media (min-width: 1366px) {
  .sidebar-collapse .sidebar__logo-text {
    opacity: 0;
  }
}

.sidebar__content {
  flex-grow: 1;
  height: calc(100% - var(--header-height));
}

.sidebar__section {
  margin: 2rem 0;
  padding: 0 1.6rem;
}

.sidebar__section,
.sidebar:hover .sidebar__section,
.sidebar--show .sidebar__section {
  display: block;
}

@media (max-width: 1365px) and (min-width: 768px) {
  .sidebar__section {
    display: none;
  }
}

@media (min-width: 1366px) {
  .sidebar-collapse .sidebar__section {
    display: none;
  }
}

.sidebar__section-title {
  margin-bottom: 1.6em;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.2em;
  text-transform: uppercase;
  color: var(--text-secondary-color);
  white-space: nowrap;
}

.sidebar__events {
  display: grid;
  grid-gap: 2rem 0;
}

.sidebar__events-item {
  font-weight: 500;
  line-height: 1.2em;
  white-space: nowrap;
}

.sidebar__events-item .marker-item {
  width: 12px;
  height: 12px;
  margin-right: 0.9333rem;
}

.sidebar__menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

@media (min-width: 768px) {
  .sidebar:hover .sidebar__menu .sidebar__collapse-menu,
  .sidebar--show .sidebar__menu .sidebar__collapse-menu {
    display: block;
  }
}

@media (max-width: 1365px) and (min-width: 768px) {
  .sidebar__menu .sidebar__collapse-menu {
    display: none;
  }
}

@media (min-width: 1366px) {
  .sidebar-collapse .sidebar__menu .sidebar__collapse-menu {
    display: none;
  }
}

.sidebar__link {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 13px 16px 13px 1.3125rem;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.2em;
  color: inherit;
  transition: var(--t-base);
}

.sidebar__link::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  border-radius: 0 5px 5px 0;
  background-color: var(--primary-color);
  opacity: 0;
}

.sidebar__collapse-menu .sidebar__link::before {
  display: none;
}

.sidebar__link::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: -2px;
  width: 48px;
  height: 48px;
  margin: auto;
  border-radius: var(--radius-lg);
  background-color: var(--background-primary-color);
  z-index: -1;
  opacity: 0;
}

.sidebar__link .badge {
  margin-left: auto;
}

.sidebar__link:hover {
  background-color: var(--background-primary-color);
  color: inherit;
}

.sidebar__collapse-menu .sidebar__link:hover {
  background-color: var(--control-background-active);
}

.sidebar__link:active {
  background-color: var(--control-background-active);
  transition-duration: 0.1s;
}

.sidebar__link.active {
  background-color: var(--background-primary-color);
}

.sidebar__link.active::before, .sidebar__link.active::after {
  opacity: 1;
}

.sidebar__collapse-menu .sidebar__link.active::before {
  opacity: 0;
}

.sidebar__collapse-menu .sidebar__link {
  color: var(--text-secondary-color);
}

.sidebar__link.active {
  color: var(--text-primary-color);
}

.sidebar__link.active::before,
.sidebar:hover .sidebar__link.active::before,
.sidebar--show .sidebar__link.active::before {
  opacity: 1;
}

@media (max-width: 1365px) and (min-width: 768px) {
  .sidebar__link.active {
    background-color: transparent;
  }
  .sidebar__link.active::before {
    opacity: 0;
  }
  .sidebar:hover .sidebar__link.active,
  .sidebar--show .sidebar__link.active {
    background-color: var(--background-primary-color);
  }
}

@media (min-width: 1366px) {
  .sidebar-collapse .sidebar__link.active {
    background-color: transparent;
  }
  .sidebar-collapse .sidebar__link.active::before {
    opacity: 0;
  }
  .sidebar:hover .sidebar__link.active {
    background-color: var(--background-primary-color);
  }
}

.sidebar__link-icon {
  flex-shrink: 0;
  width: 22px;
  height: 22px;
  margin-right: 10px;
}

.sidebar__link-icon svg {
  width: 100%;
  height: 100%;
  color: var(--text-secondary-color);
}

.sidebar__link.active .sidebar__link-icon svg {
  color: currentColor;
}

.sidebar__link-text {
  white-space: nowrap;
  transition: var(--t-base);
  transition-property: opacity;
}

.sidebar__link-text,
.sidebar:hover .sidebar__link-text,
.sidebar--show .sidebar__link-text {
  opacity: 1;
}

@media (max-width: 1365px) and (min-width: 768px) {
  .sidebar__link-text {
    opacity: 0;
  }
}

@media (min-width: 1366px) {
  .sidebar-collapse .sidebar__link-text {
    opacity: 0;
  }
}

.sidebar__link-arrow {
  margin-left: auto;
  transform: rotate(-90deg);
  transition: var(--t-base);
  transition-timing-function: linear;
}

.sidebar__link-arrow,
.sidebar:hover .sidebar__link-arrow,
.sidebar--show .sidebar__link-arrow {
  opacity: 1;
}

@media (max-width: 1365px) and (min-width: 768px) {
  .sidebar__link-arrow {
    opacity: 0;
  }
}

@media (min-width: 1366px) {
  .sidebar-collapse .sidebar__link-arrow {
    opacity: 0;
  }
}

.sidebar__link[aria-expanded="true"] .sidebar__link-arrow {
  transform: rotate(0deg);
}

.sidebar__link-arrow svg {
  width: 11px;
  height: 6px;
  color: var(--text-third-color);
}

.sidebar__collapse-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: var(--background-primary-color);
}

.sidebar__link-signal {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  margin-right: 10px;
  pointer-events: none;
}

.sidebar__link-signal::before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--grey-light-color-4);
  border: 2px solid var(--text-secondary-color);
}

.sidebar__link.active .sidebar__link-signal::before {
  background-color: var(--grey-color-3);
  border-color: var(--grey-color-3);
}

/* Sidebar Panel */
.sidebar-panel {
  --sidebar-top-height: 4.2rem;
  --sidebar-gutter-x: 1.6rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 240px;
  height: calc(100vh - var(--header-height));
  border-right: 1px solid var(--border-grey-color);
  background-color: var(--background-secondary-color);
  overflow-x: hidden;
}

.sidebar-panel[data-simplebar] > .simplebar-wrapper > .simplebar-mask > .simplebar-offset > .simplebar-content-wrapper {
  display: flex;
}

.sidebar-panel[data-simplebar] > .simplebar-wrapper > .simplebar-mask > .simplebar-offset > .simplebar-content-wrapper > .simplebar-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.sidebar-panel__top {
  position: sticky;
  top: 0;
  z-index: 1;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: var(--sidebar-top-height);
  padding: 10px;
  background-color: var(--background-secondary-color);
}

.sidebar-panel__top .button {
  box-shadow: none;
}

.sidebar-panel__content {
  flex-grow: 1;
}

.sidebar-panel__section {
  margin: 2rem 0;
  padding: 0 var(--sidebar-gutter-x);
}

@media (max-width: 575px) {
  .sidebar-panel__section {
    margin: 1.6rem 0;
  }
}

.sidebar-panel__nav-title, .sidebar-panel__section-title {
  margin: 2em 0 1.6em;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.2em;
  text-transform: uppercase;
  color: var(--text-secondary-color);
  white-space: nowrap;
}

@media (max-width: 575px) {
  .sidebar-panel__nav-title, .sidebar-panel__section-title {
    margin: 1rem 0;
  }
}

.sidebar-panel__nav-title {
  margin-bottom: 0.7333rem;
  padding: 0 var(--sidebar-gutter-x);
}

.sidebar-panel__labels {
  display: grid;
  grid-gap: 2rem 0;
}

.sidebar-panel__labels-item {
  font-weight: 500;
  line-height: 1.2em;
  white-space: nowrap;
}

.sidebar-panel__labels-item .marker-item {
  width: 12px;
  height: 12px;
  margin-right: 0.9333rem;
}

.sidebar-panel__nav:not(:last-of-type) {
  margin-bottom: 2rem;
}

.sidebar-panel__menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar-panel__menu-link {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 13px 1.6rem 13px 1.3125rem;
  font-weight: 500;
  line-height: 1.2em;
  color: inherit;
  transition: var(--t-base);
}

.sidebar-panel__menu-link::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  border-radius: 0 5px 5px 0;
  background-color: var(--primary-color);
  opacity: 0;
}

.sidebar-panel__menu-link svg[class*="icon"] {
  flex-shrink: 0;
  width: 22px;
  height: 22px;
  margin-right: 10px;
}

.sidebar-panel__menu:not(.sidebar-panel__menu--colors) .sidebar-panel__menu-link svg[class*="icon"] {
  color: var(--text-secondary-color);
}

.sidebar-panel__menu-link .badge {
  margin-left: auto;
}

.sidebar-panel__menu-link:hover {
  background-color: var(--background-primary-color);
  color: inherit;
}

.sidebar-panel__menu-link:active {
  background-color: var(--control-background-active);
  transition-duration: 0.1s;
}

.sidebar-panel__menu-link.active {
  color: var(--text-primary-color);
  background-color: var(--background-primary-color);
}

.sidebar-panel__menu-link.active::before {
  opacity: 1;
}

.sidebar-panel__menu:not(.sidebar-panel__menu--colors) .sidebar-panel__menu-link.active svg[class*="icon"] {
  color: currentColor;
}

.sidebar-panel__bottom {
  padding-bottom: 2rem;
  margin-bottom: 0;
}

@media (max-width: 575px) {
  .sidebar-panel__bottom {
    padding-bottom: 1rem;
  }
}

.sidebar-panel__bottom .media-progress {
  margin-bottom: 1.6rem;
}

@media (max-width: 575px) {
  .sidebar-panel__bottom .media-progress {
    margin-bottom: 1rem;
  }
}

.backdrop-sidebar-panel {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: var(--backdrop-color);
  opacity: 0;
  pointer-events: none;
  transition: var(--t-base);
  transition-timing-function: linear;
}

.backdrop-sidebar-panel.active {
  opacity: var(--backdrop-opacity);
  pointer-events: all;
}

@media (min-width: 1366px) {
  .backdrop-sidebar-panel {
    display: none;
  }
}

/* Header */
.header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1005;
  width: calc(100% - var(--sidebar-full-width));
  height: var(--header-height);
  border-bottom: 1px solid var(--border-grey-color);
  background-color: var(--background-secondary-color);
  transition: var(--t-base);
  transition-timing-function: linear;
  transition-property: width transform;
}

@media (min-width: 1366px) {
  .sidebar-collapse .header {
    width: calc(100% - var(--sidebar-collapse-width));
  }
}

@media (max-width: 1365px) {
  .header {
    transition-property: transform;
  }
}

@media (max-width: 1365px) and (min-width: 768px) {
  .header {
    width: calc(100% - var(--sidebar-collapse-width));
  }
  .sidebar-show .header {
    transform: translateX(calc(var(--sidebar-full-width) - var(--sidebar-collapse-width)));
  }
}

@media (max-width: 767px) {
  .header {
    width: 100%;
  }
  .sidebar-show .header {
    width: 100%;
    transform: translateX(var(--sidebar-full-width));
  }
}

@media (hover: none) {
  .header [data-tippy-root] {
    display: none;
  }
}

.header__inner {
  display: flex;
  align-items: center;
  height: var(--header-height);
}

.header__row {
  flex-wrap: nowrap;
}

.header__col-left {
  position: static;
  padding-left: 0 !important;
}

@media (max-width: 575px) {
  .header__col-left {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
    padding-right: 0 !important;
  }
}

.header__col-right {
  position: static;
}

@media (min-width: 576px) {
  .header__col-right {
    justify-content: flex-end;
  }
}

.header__left-toggle {
  display: flex;
  align-items: center;
}

.header__search {
  max-width: 290px;
}

@media (max-width: 767px) {
  .header__search {
    position: absolute;
    top: calc(var(--header-height) + 5px);
    left: var(--column-gutter);
    z-index: 100;
    width: calc(100% - var(--column-gutter) * 2);
    max-width: none;
    box-shadow: 0 10px 16px rgba(166, 178, 191, 0.4);
    border-radius: var(--radius-lg);
    opacity: 0;
    transform: translateY(10px);
    pointer-events: none;
    transition: var(--t-base);
  }
  .header__search.show {
    opacity: 1;
    transform: translateY(0);
    pointer-events: all;
  }
}

.header__toggle-menu {
  margin-left: -4px;
}

@media (min-width: 375px) {
  .header__toggle-menu {
    margin-left: -12px;
  }
}

@media (min-width: 768px) {
  .header__toggle-menu {
    margin: 0 30px 0 -15px;
  }
}

@media (max-width: 1365px) {
  .header__toggle-menu.active {
    color: var(--blue-color);
  }
}

@media (min-width: 768px) {
  .header__toggle-search {
    display: none;
  }
}

@media (min-width: 576px) {
  .header__language {
    padding-right: 1.0625rem;
    border-right: 1px solid var(--border-grey-color);
  }
}

.header__toggle-language, .header__tools {
  min-height: 32px;
}

.header__toggle-language {
  white-space: nowrap;
  transition: var(--t-base);
}

.header__toggle-language:hover {
  color: var(--grey-color-2);
}

.header__toggle-language svg[class*="language"] {
  width: 27px;
  height: 27px;
  fill: currentColor;
}

.header__tools {
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-right: 1px solid var(--border-grey-color);
}

@media (max-width: 575px) {
  .header__tools {
    flex-grow: 1;
    padding-left: 0;
    padding-right: 8px;
  }
}

@media (min-width: 576px) {
  .header__tools-item {
    position: relative;
  }
}

.header__tools-toggle {
  position: relative;
  display: flex;
  width: 32px;
  height: 32px;
  padding: 5px;
  margin: 0 4px;
  white-space: nowrap;
  transition: var(--t-base);
}

@media (max-width: 575px) {
  .header__tools-toggle {
    width: 36px;
    height: 36px;
    margin: 0;
  }
}

.header__tools-toggle:hover {
  color: var(--grey-color-2);
}

.header__tools-toggle svg[class*="icon"] {
  width: 100%;
  height: 100%;
  fill: currentColor;
}

.header__tools-toggle .badge-signal {
  position: absolute;
  top: 6px;
}

.header__tools-toggle--message .badge-signal {
  right: 3px;
}

.header__tools-toggle--bell .badge-signal {
  right: 6px;
}

.header__profile {
  display: flex;
  align-self: stretch;
  margin-right: calc(var(--container-gutter) * -1);
}

.header__profile-toggle {
  display: inline-flex;
  align-items: center;
  padding: 0 var(--container-gutter);
}

.header__profile-toggle:hover {
  color: var(--grey-color-2);
}

.header__profile-image {
  flex-shrink: 0;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.6667rem;
  height: 2.6667rem;
  border-radius: 50%;
  font-size: 0.9333rem;
  line-height: 1.14286em;
  background-color: var(--orange-color-1);
  color: var(--white-color);
}

.header__profile-image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

@media (min-width: 576px) {
  .header__profile-image {
    margin-right: 15px;
  }
}

.header__profile-image-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
}

.header__profile-text {
  display: grid;
  font-size: 15px;
  line-height: 1.13333em;
  overflow: hidden;
  white-space: nowrap;
}

@media (max-width: 575px) {
  .header__profile-text {
    display: none;
  }
}

.header__profile-text span {
  text-overflow: ellipsis;
}

.header__notes .dropdown-menu {
  min-width: 300px;
  transform: translateX(-133px) translateY(40px) !important;
}

.header__notes .dropdown-menu__items {
  max-height: 13.4rem;
}

@media (max-width: 575px) and (orientation: landscape) {
  .header__notes .dropdown-menu__items {
    max-height: 126px;
  }
}

.header__messages .dropdown-menu {
  min-width: 352px;
  transform: translateX(-160px) translateY(40px) !important;
}

.header__messages .dropdown-menu__items {
  max-height: 13.2rem;
}

@media (max-width: 575px) and (orientation: landscape) {
  .header__messages .dropdown-menu__items {
    max-height: 124px;
  }
}

@media (max-width: 575px) {
  .header__notes .dropdown-menu, .header__messages .dropdown-menu {
    width: calc(100% - var(--column-gutter) * 2);
    right: 0;
    min-width: auto;
    margin: auto;
    transform: translateX(0) translateY(70px) !important;
  }
}

.form-search__container {
  position: relative;
}

.form-search__icon-left {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 14px;
  width: 18px;
  height: 18px;
  line-height: 1;
  margin: auto;
  pointer-events: none;
  color: var(--text-primary-color);
}

.form-search__icon-left [class*="icon"] {
  width: 100%;
  height: 100%;
  fill: currentColor;
}

.form-search__input {
  width: 100%;
  padding: 11px 15px 11px 2.375em;
  border: none;
  border-radius: 5px;
  background-color: var(--background-primary-color);
  outline: none;
  font-size: 16px;
  line-height: 1.1875em;
  transition: var(--t-base);
  color: inherit;
}

@media (max-width: 575px) {
  .form-search__input {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: var(--radius-lg);
  }
}

.form-search__input:hover, .form-search__input:focus {
  background-color: var(--control-background-hover);
}

.form-search__input::placeholder {
  color: var(--placeholder-color);
}

.lang-menu {
  min-width: auto;
}

.header__language .lang-menu {
  min-width: 86px;
  margin-left: -1px;
}

.lang-menu__button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lang-menu__button--all {
  text-align: center;
  margin-bottom: -5px;
  border-radius: 0 0 var(--card-border-radius) var(--card-border-radius);
}

.lang-menu__separate {
  border-top: 1px solid var(--border-grey-color);
}

.lang-menu__icon {
  vertical-align: middle;
  height: 20px;
  width: 20px;
  margin-right: 10px;
  object-fit: contain;
}

.lang-menu__text {
  display: inline-block;
  vertical-align: middle;
  text-transform: uppercase;
}

.profile-dropdown {
  white-space: nowrap;
}

.header__profile .profile-dropdown {
  right: 11px;
  left: auto !important;
  transform: translate3d(0px, 44px, 0) !important;
}

@media (max-width: 575px) {
  .header__profile .profile-dropdown {
    right: 6px;
  }
  .header__profile .profile-dropdown::before, .header__profile .profile-dropdown::after {
    left: calc(100% - 14px);
  }
}

.profile-dropdown__item {
  display: flex;
  align-items: center;
}

.profile-dropdown__icon {
  display: flex;
  justify-content: center;
  width: 42px;
  margin-left: -0.55rem;
  height: 22px;
  line-height: 1;
  transition: var(--t-base);
}

.profile-dropdown__icon svg {
  width: 22px;
  height: 100%;
}

.subheader {
  position: fixed;
  top: var(--header-height);
  right: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  width: calc(100% - var(--sidebar-full-width));
  height: var(--subheader-height);
  border-bottom: 1px solid var(--border-grey-color);
  background-color: var(--background-secondary-color);
  transition: var(--t-base);
  transition-timing-function: linear;
  transition-property: width transform;
}

@media (min-width: 1366px) {
  .sidebar-collapse .subheader {
    width: calc(100% - var(--sidebar-collapse-width));
  }
}

@media (max-width: 1365px) {
  .subheader {
    transition-property: transform;
  }
}

@media (max-width: 1365px) and (min-width: 768px) {
  .subheader {
    width: calc(100% - var(--sidebar-collapse-width));
  }
  .sidebar-show .subheader {
    transform: translateX(calc(var(--sidebar-full-width) - var(--sidebar-collapse-width)));
  }
}

@media (max-width: 767px) {
  .subheader {
    width: 100%;
  }
  .sidebar-show .subheader {
    width: 100%;
    transform: translateX(var(--sidebar-full-width));
  }
}

.subheader .toggle-sidebar {
  align-self: center;
  height: calc(var(--subheader-height) - 4px);
  width: calc(var(--subheader-height) - 4px);
  margin: 0 18px 0 8px;
}

@media (min-width: 768px) {
  .subheader .toggle-sidebar {
    height: calc(var(--subheader-height) - 14px);
    width: calc(var(--subheader-height) - 14px);
    margin: 0 52px 0 5px;
  }
}

.subheader__title {
  margin-bottom: 0;
}

.flatpickr-calendar {
  font-size: 1rem;
  line-height: 1.2em;
  border-radius: 5px;
  width: var(--calendar-inline-width);
  background: var(--background-secondary-color);
  color: var(--text-primary-color);
  box-shadow: none;
}

.flatpickr-rContainer {
  width: 100%;
}

.flatpickr-months {
  position: relative;
  margin-bottom: 1rem;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  top: 50%;
  width: 14%;
  transform: translateY(-50%);
  color: var(--text-primary-color);
  transition: var(--t-base);
}

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  vertical-align: top;
  fill: currentColor;
}

.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: var(--secondary-color);
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: currentColor;
}

.flatpickr-months .flatpickr-month {
  position: relative;
  height: auto;
  font-size: 1.2rem;
  line-height: 1.16667em;
  color: var(--text-primary-color);
  overflow: initial;
}

.flatpickr-current-month {
  position: static;
  left: auto;
  width: auto;
  height: auto;
  padding: 0;
  font-weight: 500;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}

.flatpickr-current-month span.cur-month {
  font-weight: 500;
  color: inherit;
  display: inline-block;
  margin-left: 0;
}

.flatpickr-weekdays {
  height: auto;
  margin-bottom: 1rem;
  overflow: initial;
}

span.flatpickr-weekday {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.14286em;
  text-transform: uppercase;
  color: var(--text-secondary-color);
}

.flatpickr-days {
  width: 100%;
}

.dayContainer {
  flex-grow: 1;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(6, 39px);
  justify-content: space-around;
  align-items: center;
  width: auto;
  min-width: auto;
  max-width: 100%;
}

.flatpickr-day {
  flex-basis: auto;
  width: 100%;
  max-width: 32px;
  height: 32px;
  margin: auto;
  line-height: 32px;
  font-weight: 500;
  color: inherit;
  border: none;
  filter: drop-shadow(0px 8px 16px rgba(0, 129, 255, 0.2));
  user-select: none;
}

[data-theme="dark"] .flatpickr-day {
  filter: drop-shadow(0px 8px 16px rgba(0, 55, 250, 0.3));
}

.flatpickr-day .event {
  position: absolute;
  bottom: 2px;
  left: calc(50% - 2px);
  width: 4px;
  height: 4px;
  background-color: var(--red-color);
  border-radius: 50%;
}

.flatpickr-day .event--upcoming {
  background-color: var(--green-color);
}

.flatpickr-day.today {
  background-color: var(--primary-color);
  color: var(--white-color);
}

.flatpickr-day.today.prevMonthDay, .flatpickr-day.today:hover, .flatpickr-day.today:focus {
  background-color: var(--primary-color);
  color: var(--white-color);
}

.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
  background-color: var(--control-background-hover);
  color: var(--text-primary-color);
}

.flatpickr-day.flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus {
  background-color: var(--control-background-hover);
}

.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover, .flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay, .flatpickr-day.notAllowed, .flatpickr-day.notAllowed.prevMonthDay, .flatpickr-day.notAllowed.nextMonthDay {
  color: var(--text-secondary-color);
}

.calendar-inline {
  width: 100%;
  margin: 0 auto 26px;
}

.calendar-inline .flatpickr-calendar {
  width: auto;
  margin: 0 -11px;
}

.calendar-inline .flatpickr-months {
  margin-bottom: 2.2667rem;
}

.calendar-inline .flatpickr-current-month {
  pointer-events: none;
}

.calendar-inline .cur-year {
  display: none !important;
}

.calendar-inline .flatpickr-weekdays {
  margin-bottom: 18px;
}

.calendar-widget {
  width: 100%;
  margin: auto;
}

.calendar-widget__row {
  display: flex;
  justify-content: space-between;
  margin-left: -1rem;
}

.calendar-widget__item {
  display: flex;
  flex-flow: column;
  padding-left: 1rem;
}

.calendar-widget__item--left {
  text-align: center;
}

.calendar-widget__day {
  flex-grow: 1;
  font-weight: 500;
  font-size: 2rem;
  line-height: 1.16667em;
  text-transform: uppercase;
}

.calendar-widget__weekday {
  vertical-align: top;
  position: absolute;
  top: auto;
  margin-top: -0.7em;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
}

.calendar-widget__status {
  flex-grow: 1;
  white-space: nowrap;
}

.calendar-widget__status .circle {
  display: inline-block;
  flex-shrink: 0;
  width: 8px;
  height: 8px;
  margin-right: 8px;
  border-radius: 50%;
}

/* Breadcrumbs */
.breadcrumbs__list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin: 0;
}

.breadcrumbs__item {
  display: flex;
  align-items: center;
  margin-right: 12px;
}

.breadcrumbs__item.disabled {
  pointer-events: none;
}

.breadcrumbs__link {
  font-size: 0.8667rem;
  line-height: 1.84615em;
  color: var(--text-secondary-color);
  white-space: nowrap;
}

.breadcrumbs__item.active .breadcrumbs__link {
  color: var(--text-primary-color);
}

.breadcrumbs__link > * {
  vertical-align: middle;
}

.breadcrumbs__icon {
  width: 18px;
  height: 18px;
  line-height: 1.15385em;
}

.breadcrumbs__arrow {
  width: 5px;
  height: 8px;
  margin-left: 10px;
}

/* Pagination */
.pagination {
  list-style: none;
  display: inline-flex;
  min-height: 2.6667rem;
  padding: 0;
  margin: 0;
  border-radius: var(--radius-base);
  background-color: var(--background-secondary-color);
  filter: drop-shadow(0px 4px 4px rgba(169, 194, 209, 0.05)) drop-shadow(0px 8px 16px rgba(169, 194, 209, 0.1));
}

.pagination__item {
  display: flex;
}

.pagination__item.disabled {
  pointer-events: none;
}

.pagination__item--dots {
  align-items: center;
  margin: 0 6px;
}

.pagination__arrow, .pagination__link {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination__arrow {
  width: 2.6667rem;
  color: var(--grey-color-4);
  transition: var(--t-base);
}

.pagination__arrow--prev {
  border-right: 1px solid var(--background-secondary-color);
  border-radius: var(--radius-base) 0 0 var(--radius-base);
}

.pagination__arrow--next {
  border-left: 1px solid var(--background-secondary-color);
  border-radius: 0 var(--radius-base) var(--radius-base) 0;
}

.pagination__arrow:hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: var(--white-color);
}

.pagination__arrow:active {
  background-color: var(--blue-color-control-1);
  border-color: var(--blue-color-control-1);
}

.pagination__arrow [class*="icon"] {
  width: 6px;
  height: 10px;
}

.pagination__link {
  width: 2.2667rem;
  margin: 3px 2px;
  border-radius: var(--radius-base);
  transition: none;
}

.pagination__link:hover {
  color: inherit;
}

.pagination__item.active .pagination__link {
  background-color: var(--primary-color);
  color: var(--white-color);
  filter: drop-shadow(0px 8px 16px rgba(0, 129, 255, 0.2));
}

/* Modals */
.modal {
  --modal-container-gutter: 2.6667rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1050;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

.modal[data-simplebar] {
  overflow: initial;
}

.modal[data-simplebar] > .simplebar-wrapper > .simplebar-mask > .simplebar-offset > .simplebar-content-wrapper {
  display: flex;
}

.modal[data-simplebar] > .simplebar-wrapper > .simplebar-mask > .simplebar-offset > .simplebar-content-wrapper > .simplebar-content {
  flex-grow: 1;
  margin: auto;
}

@media (max-width: 575px) {
  .modal {
    --modal-container-gutter: 14px;
  }
}

.modal.is-active {
  visibility: visible;
  opacity: 1;
}

.modal.is-animate {
  pointer-events: all;
}

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--backdrop-color);
  opacity: 0;
  transition: var(--t-base);
}

.modal.is-animate .modal__overlay {
  opacity: var(--backdrop-opacity);
}

.modal__close {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: var(--border-grey-color);
  cursor: pointer;
  transition: var(--t-base);
}

.modal__close:hover {
  background-color: var(--control-background-hover);
}

.modal__close svg[class*="icon"] {
  width: 22px;
  height: 22px;
}

.modal__wrap {
  position: relative;
  width: 0;
  pointer-events: none;
  overflow: hidden;
}

.modal.is-active .modal__wrap {
  width: 100%;
  overflow: initial;
}

.modal--panel .modal__wrap {
  display: flex;
  justify-content: flex-end;
  height: 100%;
  margin-right: 0;
  margin-left: auto;
}

.modal-compact .modal__wrap {
  max-width: 580px;
  margin: auto;
}

.modal-success .modal__wrap {
  max-width: 420px;
}

.modal-add-review .modal__wrap,
.modal-add-event .modal__wrap {
  max-width: 500px;
}

.modal__window {
  position: relative;
  z-index: 2;
  width: 100%;
  max-height: 100%;
  transition: transform 0.3s ease-in-out;
}

.modal--panel .modal__window {
  max-width: 580px;
  pointer-events: all;
}

.modal--panel.modal--sm .modal__window {
  max-width: 500px;
}

.modal--right .modal__window {
  transform: translateX(100%);
}

.modal-compact .modal__window {
  position: relative;
  z-index: 2;
  width: 100%;
  transform: translateY(-40%);
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.modal.is-animate .modal__window {
  transform: translate(0);
}

.modal-compact.is-animate .modal__window {
  transform: translateY(0);
  opacity: 1;
}

.modal__content {
  position: relative;
  display: flex;
  flex-direction: column;
  pointer-events: all;
  background-color: var(--background-secondary-color);
}

.modal--panel .modal__content {
  min-height: 100vh;
  box-shadow: -4px 0px 8px rgba(169, 194, 209, 0.1), -16px 0px 32px rgba(169, 194, 209, 0.15);
}

.modal-compact .modal__content {
  margin: 10px auto;
  max-width: calc(100% - 20px);
  border-radius: var(--card-border-radius);
  box-shadow: 0px 4px 8px rgba(169, 194, 209, 0.25), 0px 16px 32px rgba(169, 194, 209, 0.25);
}

.modal__content-wrap {
  flex-grow: 1;
}

.modal__container {
  padding-right: var(--modal-container-gutter);
  padding-left: var(--modal-container-gutter);
}

.modal-compact .modal__container {
  padding-right: 2.6667rem;
  padding-left: 2.6667rem;
}

@media (max-width: 575px) {
  .modal-compact .modal__container {
    padding-right: var(--modal-container-gutter);
    padding-left: var(--modal-container-gutter);
  }
}

.modal-success .modal__container {
  padding-right: 1.6rem;
  padding-left: 1.6rem;
}

@media (max-width: 575px) {
  .modal-success .modal__container {
    padding-right: var(--modal-container-gutter);
    padding-left: var(--modal-container-gutter);
  }
}

.modal__header {
  padding: 1.6667rem 0 2.2667rem;
  font-size: 14px;
  line-height: 1.5;
}

.modal-compact .modal__header {
  padding-bottom: 1.2rem;
}

@media (max-width: 575px) {
  .modal-compact .modal__header {
    padding: 1.3rem 0 0;
  }
}

@media (max-width: 575px) {
  .modal__header {
    padding-bottom: 15px;
  }
}

.modal__title {
  margin-bottom: 0;
  font-size: 2rem;
  line-height: 1.16667em;
}

@media (max-width: 575px) {
  .modal__title {
    font-size: 1.5rem;
  }
}

.modal__body {
  flex-grow: 1;
  max-height: 100%;
}

.modal__body[data-simplebar] {
  overflow-x: hidden;
}

.modal-compact .modal__body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  padding: 1.2rem 0 1rem;
}

.modal-success .modal__body {
  padding: 4rem 0;
}

@media (max-width: 575px) {
  .modal-success .modal__body {
    padding: 3rem 0 2.75rem;
  }
}

.modal__footer {
  padding-top: 20px;
  padding-bottom: 2rem;
}

.modal--panel .modal__footer {
  position: sticky;
  bottom: 0;
  z-index: 1;
  background-color: var(--background-secondary-color);
}

.modal-compact .modal__footer {
  padding-top: 1rem;
}

@media (max-width: 575px) {
  .modal__footer {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.modal__footer-buttons {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: var(--container-gutter-sm);
  justify-content: flex-end;
}

@media (max-width: 575px) {
  .modal__footer-buttons {
    grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
  }
}

.modal__footer-buttons .button {
  min-width: 110px;
}

.modal-compact__buttons {
  position: relative;
  display: grid;
  grid-template-columns: 50% 50%;
}

.modal-compact__buttons::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  z-index: 1;
  transform: translateX(-50%);
  content: '';
  border-right: 1px solid var(--background-secondary-color);
  pointer-events: none;
}

.modal-compact__button-item {
  display: flex;
}

.modal-compact__button {
  flex-grow: 1;
  height: auto;
  padding: 1.2667rem 0.8rem;
  border-radius: 0;
  font-weight: 400;
  font-size: inherit;
  background-color: var(--background-primary-color);
}

@media (max-width: 575px) {
  .modal-compact__button {
    padding: 1rem 0.5rem;
  }
}

.modal-compact__button-item:first-child .modal-compact__button {
  border-bottom-left-radius: var(--card-border-radius);
}

.modal-compact__button-item:last-child .modal-compact__button {
  border-bottom-right-radius: var(--card-border-radius);
  color: var(--text-secondary-color);
}

.modal-compact__button:hover {
  background-color: var(--control-background-hover);
}

.modal-compact__button:active {
  background-color: var(--control-background-active);
}

.modal-success {
  text-align: center;
}

.modal-success__icon {
  margin-bottom: 1.7333rem;
}

.modal-success__title {
  margin: 0;
  font-weight: normal;
  font-size: 1.3333rem;
  line-height: 1.15em;
}

/* Products */
.add-product {
  min-height: calc(100vh - 260px);
  padding: 2.8rem 0 2rem;
}

.add-product__row {
  display: grid;
  grid-gap: 2.75rem;
  justify-content: center;
  max-width: var(--container-width-xl);
  margin: auto;
}

@media (min-width: 576px) {
  .add-product__row {
    grid-template-columns: repeat(1, minmax(460px, 600px));
    grid-row-gap: 4rem;
  }
}

@media (min-width: 1024px) {
  .add-product__row {
    grid-template-columns: 1fr minmax(380px, 28%);
    grid-column-gap: 2.5rem;
  }
}

@media (min-width: 1366px) {
  .add-product__row {
    grid-column-gap: 6.49123%;
    grid-template-columns: 1fr minmax(380px, 36.84211%);
  }
}

.add-product__slider {
  display: flex;
  align-self: flex-start;
}

@media (min-width: 768px) {
  .add-product__slider {
    margin-top: calc(22px + 1.0667rem);
  }
}

.add-product__thumbs {
  flex-shrink: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  overflow: hidden;
}

@media (min-width: 576px) {
  .add-product__thumbs {
    margin-right: 24px;
  }
}

@media (min-width: 1440px) {
  .add-product__thumbs {
    margin-right: 11.66667%;
  }
}

.add-product__thumbs-slider {
  height: 248px;
  margin: 8px 0;
}

@media (min-width: 375px) {
  .add-product__thumbs-slider {
    height: 304px;
  }
}

@media (min-width: 576px) {
  .add-product__thumbs-slider {
    height: 472px;
  }
}

.add-product__thumb, .add-product__gallery-slide {
  position: relative;
  background-image: url(__static__content/product/placeholder-thumbnail.jpg);
  background-size: cover;
  background-position: center center;
  border: 1px solid var(--border-grey-color);
  overflow: hidden;
}

.add-product__gallery-image, .add-product__thumb-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: var(--white-color);
  object-position: 50% 50%;
}

.add-product__thumb {
  width: 56px;
  height: 56px;
  margin-bottom: 8px;
  cursor: pointer;
}

@media (min-width: 375px) {
  .add-product__thumb {
    width: 64px;
    height: 64px;
    margin-bottom: 14px;
  }
}

@media (min-width: 576px) {
  .add-product__thumb {
    height: 100px;
    width: 100px;
    margin-bottom: 24px;
  }
}

.add-product__thumb:last-child {
  margin-bottom: 0;
}

.add-product__thumb.swiper-slide-thumb-active {
  border-color: var(--blue-color);
}

.add-product__thumbs-prev {
  order: -1;
}

.add-product__thumbs-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 2.1333rem;
}

@media (min-width: 576px) {
  .add-product__thumbs-arrow {
    height: 50px;
  }
}

.add-product__thumbs-arrow svg {
  width: 24px;
  height: 14px;
}

.add-product__thumbs-arrow--prev {
  top: 0;
}

.add-product__thumbs-arrow--next {
  bottom: 0;
}

.add-product__thumbs-arrow--next svg {
  transform: rotate(180deg);
}

.add-product__gallery {
  flex-grow: 1;
}

.add-product__gallery-slider {
  height: 100%;
}

.add-product__gallery-slide {
  width: 100% !important;
}

.add-product__lazy-preloader {
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 0;
  border-radius: 0;
  border: none;
  background-color: var(--background-primary-color);
  animation: none;
  opacity: 0;
  pointer-events: none;
}

.add-product__lazy-preloader::before {
  content: " ";
  height: 44px;
  width: 44px;
  background-image: url(__static__content/upload-progress.svg);
  background-size: 100% 100%;
  animation: infinite rotate 1s linear;
}

.add-product__submit {
  display: grid;
  grid-template-columns: repeat(2, 110px);
  grid-gap: var(--container-gutter-sm);
  justify-content: flex-end;
  margin-top: 1rem;
}

@media (max-width: 575px) {
  .add-product__submit {
    grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
  }
}

/* Orders */
.order-tabs {
  position: fixed;
  top: var(--header-height);
  z-index: 1000;
  display: flex;
  width: calc(100% - var(--sidebar-full-width));
  margin-bottom: var(--order-tabs-offset-bottom);
  border-bottom: 1px solid var(--border-grey-color);
  background-color: var(--background-secondary-color);
  transition: var(--t-base);
  transition-timing-function: linear;
  transition-property: width transform;
}

@media (min-width: 1366px) {
  .sidebar-collapse .order-tabs {
    width: calc(100% - var(--sidebar-collapse-width));
  }
}

@media (max-width: 1365px) {
  .order-tabs {
    transition-property: transform;
  }
}

@media (max-width: 1365px) and (min-width: 768px) {
  .order-tabs {
    width: calc(100% - var(--sidebar-collapse-width));
  }
  .sidebar-show .order-tabs {
    transform: translateX(calc(var(--sidebar-full-width) - var(--sidebar-collapse-width)));
  }
}

@media (max-width: 767px) {
  .order-tabs {
    width: 100%;
  }
  .sidebar-show .order-tabs {
    width: 100%;
    transform: translateX(var(--sidebar-full-width));
  }
}

.order-tabs__container {
  flex-grow: 1;
  display: grid;
  grid-template-columns: repeat(1, 9rem auto 9rem);
  justify-content: space-between;
  padding: 0 var(--container-gutter);
}

@media (max-width: 991px) {
  .order-tabs__container {
    grid-template-columns: repeat(1, auto);
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .order-tabs__container {
    grid-template-columns: repeat(1, 1fr);
  }
}

.order-tabs__title {
  align-self: center;
  margin: 0;
  font-weight: 700;
  font-size: 1.3333rem;
  line-height: 1.15em;
}

@media (max-width: 991px) {
  .order-tabs__title {
    display: none;
  }
}

.order-tabs__nav {
  position: relative;
  display: grid;
}

.order-tabs__nav-prev, .order-tabs__nav-next {
  position: absolute;
  top: 0;
  bottom: 0;
  width: var(--container-gutter);
  min-width: 24px;
  z-index: 2;
}

@media (min-width: 768px) {
  .order-tabs__nav-prev, .order-tabs__nav-next {
    opacity: 0;
    pointer-events: none;
  }
}

.order-tabs__nav-prev {
  left: calc(var(--container-gutter) * -1);
}

.order-tabs__nav-next {
  right: calc(var(--container-gutter) * -1);
}

.order-tabs__arrow {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 4px;
}

.order-tabs__arrow svg {
  width: 10px;
  height: 10px;
}

.order-tabs__arrow--prev svg {
  transform: rotate(-90deg);
}

.order-tabs__arrow--next {
  justify-content: flex-end;
}

.order-tabs__arrow--next svg {
  transform: rotate(90deg);
}

.order-tabs__list-wrapper {
  width: 100%;
  max-width: 100%;
  min-width: 0;
}

@media (min-width: 768px) {
  .order-tabs__list {
    transform: none !important;
  }
}

.order-tabs__item {
  display: flex;
  width: 33.33333%;
}

@media (min-width: 480px) {
  .order-tabs__item {
    width: 25%;
  }
}

@media (min-width: 576px) {
  .order-tabs__item {
    width: 20%;
  }
}

@media (min-width: 650px) {
  .order-tabs__item {
    width: 16.66667%;
  }
}

@media (min-width: 768px) {
  .order-tabs__item {
    width: auto !important;
  }
  .order-tabs__item:not(:last-child) {
    margin-right: 35px;
  }
}

.order-tabs__link {
  position: relative;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--order-tabs-height);
  font-weight: 500;
}

@media (max-width: 767px) {
  .order-tabs__link {
    font-size: 13px;
  }
}

.order-tabs__link svg {
  width: 22px;
  height: 22px;
  margin-right: 4px;
  fill: var(--text-secondary-color);
}

.order-tabs__link--active::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--blue-color);
}

.order-tabs__link--active svg {
  fill: currentColor;
}

.order-tabs__link:hover svg {
  fill: currentColor;
}

.card-order {
  padding: 2rem 0;
}

@media (min-width: 768px) {
  .card-order {
    --card-gutter-x: 2rem;
  }
}

@media (min-width: 1200px) {
  .card-order {
    --card-gutter-x: 2.6667rem;
  }
}

.card-order--messages {
  padding: 0;
}

.card-order .card__header {
  margin-bottom: 1.6rem;
}

.card-order .card__header .card__title {
  margin: 0;
}

.card-order__section {
  padding: 2rem 0;
  border-bottom: 1px solid var(--border-grey-color);
}

.card-order__customer-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0 0 -1rem -2.1333rem;
}

.card-order__customer-list svg[class*="icon"] {
  width: 1.4667rem;
  height: 1.4667rem;
  margin-right: 4px;
}

.card-order__customer-item {
  margin: 0 0 1rem 2.1333rem;
}

.card-order__customer-item > * {
  vertical-align: middle;
}

.card-order__list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.card-order__method .card-order__list li {
  margin-bottom: 1rem;
}

.card-order__payment .input-group, .card-order__shipping .input-group {
  display: inline-block;
  width: auto;
  min-width: 200px;
}

@media (max-width: 575px) {
  .card-order__payment .input-group, .card-order__shipping .input-group {
    display: block;
  }
}

.card-order__status-panel {
  padding: 11px 15px;
  border-radius: var(--card-border-radius);
  background-color: var(--background-primary-color);
}

@media (min-width: 1200px) {
  .card-order__status-panel {
    margin-top: 10px;
  }
}

.card-order__status-panel .form-group:not(:last-child) {
  margin-bottom: 5px;
}

@media (max-width: 575px) {
  .card-order__status-panel .form-group {
    flex-direction: column;
    align-items: flex-start;
  }
  .card-order__status-panel .form-group:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.card-order__status-panel .input-group {
  min-width: 170px;
}

.card-order__status-panel .form-label {
  flex-shrink: 0;
  min-width: 144px;
}

@media (max-width: 575px) {
  .card-order__status-panel .form-label {
    margin-bottom: 6px;
  }
}

.card-order__address {
  margin-bottom: 0;
  min-width: 200px;
}

.card-order__address .card-order__list {
  line-height: 1.6em;
}

.card-order__address .card-order__list:not(:last-child) {
  margin-bottom: 1.6rem;
}

.card-order .table {
  margin-bottom: 0;
}

.card-order .table th:first-child,
.card-order .table td:first-child {
  padding-left: var(--card-gutter-x);
}

.card-order .table th:last-child,
.card-order .table td:last-child {
  padding-right: var(--card-gutter-x);
}

.card-order .table th {
  white-space: nowrap;
}

.card-order__footer-total {
  padding-top: 2.6667rem;
}

@media (max-width: 767px) {
  .card-order__footer-total {
    padding-top: 1.6rem;
  }
}

@media (max-width: 575px) {
  .card-order__footer-submit {
    order: 1;
    display: flex;
    justify-content: center;
  }
  .card-order__footer-submit button {
    width: 100%;
  }
}

.card-order__total {
  list-style: none;
  display: table;
  padding: 0;
  margin-bottom: 0;
  margin-left: auto;
}

.card-order__total-item {
  display: table-row;
}

.card-order__total-item > * {
  display: table-cell;
}

.card-order__total-item:nth-last-of-type(2) > * {
  padding-bottom: 2.1333rem;
}

.card-order__total-item:nth-last-of-type(n+3) > * {
  padding-bottom: 1.6rem;
}

.card-order__total-footer {
  font-weight: 700;
  font-size: 1.3333rem;
  line-height: 1.15em;
}

.card-order__total-title {
  width: 90px;
  padding-right: 3.3333rem;
  text-align: right;
  text-transform: uppercase;
  color: var(--text-secondary-color);
}

.card-order__total-footer .card-order__total-title {
  color: inherit;
}

.card-order__product .colgroup-1 {
  width: 40%;
}

.card-order__product .colgroup-2 {
  width: 240px;
}

.card-order__invoice-table .colgroup-1 {
  width: 50%;
}

.card-order__invoice-table .colgroup-2 {
  width: 20%;
}

.card-order__invoice-table .colgroup-3 {
  width: 20%;
}

.order-invoice .card__header {
  margin-bottom: 6rem;
}

.order-invoice__title {
  display: inline-block;
  padding: 5.3333rem 2.4rem 1.6rem;
  margin-top: -2rem;
  margin-right: 3.3333rem;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.15em;
  color: var(--white-color);
  background-color: var(--red-color-2);
}

.order-invoice__title b {
  margin-bottom: 8px;
}

.order-invoice__address {
  display: inline-block;
  vertical-align: top;
  margin-top: 10px;
  line-height: 1.6em;
  color: var(--text-secondary-color);
}

.order-invoice__logo {
  width: 100%;
  max-width: 10.0667rem;
  height: 2.3333rem;
  margin-top: 1.6rem;
  margin-left: auto;
  background: url("__static__content/logo-light.svg") no-repeat 50% 50%/contain;
}

@media (max-width: 767px) {
  .order-invoice__logo {
    margin-top: 0;
  }
}

.order-invoice__header-right {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 767px) {
  .order-invoice__header-right {
    flex-direction: row;
  }
}

@media (max-width: 767px) {
  .order-invoice__header-right .card__date {
    margin-right: 1rem;
  }
}

.order-invoice .table__header > tr {
  height: 32px;
}

.order-invoice .table__header > tr th {
  vertical-align: top;
  padding-top: 0;
}

.order-status {
  padding-top: 1rem;
}

.order-status .card-order__product {
  margin-bottom: 3.3333rem;
}

.order-status__form {
  max-width: 980px;
  margin: auto;
}

@media (min-width: 576px) {
  .order-status__form .form-label {
    min-width: 150px;
    padding-right: 2rem;
    margin-right: 0;
    text-align: right;
  }
}

@media (max-width: 575px) {
  .order-status__form-group {
    flex-direction: column;
    align-items: flex-start;
  }
  .order-status__form-group .form-label {
    margin-bottom: 0.5rem;
    text-align: left;
  }
}

.order-status__checkboxes {
  margin-left: -3.8667rem !important;
}

.order-status__checkbox-column {
  padding-left: 3.8667rem !important;
}

.order-status__checkbox-column .form-group {
  flex-grow: 1;
  justify-content: flex-end;
}

.order-status__checkbox-column .input-group {
  width: auto;
}

.order-status__submit {
  margin-top: 2.1333rem;
}

.order-timeline {
  --item-bottom-gutter: 3.2rem;
  --group-item-gutter: 1rem 1.4rem;
}

@media (max-width: 575px) {
  .order-timeline {
    --item-bottom-gutter: 1rem;
    --group-item-gutter: 11px 14px;
  }
}

.order-timeline__item {
  position: relative;
  display: flex;
}

@media (max-width: 575px) {
  .order-timeline__item {
    flex-direction: column;
  }
}

.order-timeline__item:not(:last-child) {
  margin-bottom: var(--item-bottom-gutter);
}

.order-timeline__left {
  position: relative;
  flex: 0 0 auto;
  width: auto;
  max-width: none;
  display: flex;
  align-items: center;
  margin-right: 1.3333rem;
  white-space: nowrap;
}

.order-timeline__left::before {
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translate(-50%, -50%);
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--border-grey-color);
}

@media (max-width: 575px) {
  .order-timeline__left {
    margin-right: 0;
    padding-bottom: 0.5rem;
    padding-left: calc(68px + 1rem);
  }
  .order-timeline__left::before {
    left: 68px;
    transform: translate(-50%, -100%);
  }
}

.order-timeline__right {
  flex-grow: 1;
  position: relative;
}

.order-timeline__right::before {
  position: absolute;
  top: 0;
  bottom: calc(var(--item-bottom-gutter) * -1);
  left: calc(-1.3333rem);
  transform: translateX(-50%);
  content: "";
  border-left: 1px solid var(--border-grey-color);
}

.order-timeline__item:first-child .order-timeline__right::before {
  top: 50%;
}

.order-timeline__item:last-child .order-timeline__right::before {
  bottom: 50%;
}

@media (max-width: 575px) {
  .order-timeline__right::before {
    top: -100%;
    left: 68px;
  }
  .order-timeline__item:first-child .order-timeline__right::before {
    top: -16px;
  }
  .order-timeline__item:last-child .order-timeline__right::before {
    bottom: 0;
  }
}

.order-timeline__date {
  font-weight: 500;
  line-height: 1.6em;
  min-width: 7.0667rem;
}

.order-timeline__group {
  list-style: none;
  padding: 0;
  margin: 0;
}

.order-timeline__group-item {
  display: flex;
  padding: var(--group-item-gutter);
  background-color: var(--background-primary-color);
}

.order-timeline__group-item:not(:last-child) {
  border-bottom: 1px solid var(--border-grey-color);
}

.order-timeline__group-item:first-child {
  border-radius: var(--card-border-radius) var(--card-border-radius) 0 0;
}

.order-timeline__group-item:nth-last-child(1) {
  border-radius: 0 0 var(--card-border-radius) var(--card-border-radius);
}

.order-timeline__group-item:first-child:last-child {
  border-radius: var(--card-border-radius);
}

.order-timeline__group-left {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
  position: relative;
  display: flex;
  align-items: center;
  padding-right: 1.6rem;
  margin-right: 1.6rem;
  border-right: 1px solid var(--border-grey-color);
}

.order-timeline__group-left svg[class*="icon"] {
  width: 22px;
  height: 22px;
  margin-right: 5px;
}

@media (max-width: 575px) {
  .order-timeline__group-left {
    flex-direction: column;
    justify-content: center;
    min-width: 54px;
    padding-right: 10px;
    margin-right: 1rem;
    border-right: 0;
  }
  .order-timeline__group-left svg[class*="icon"] {
    margin: 0 0 4px;
  }
}

.order-timeline__group-right {
  display: flex;
  align-items: center;
  line-height: 1.6em;
}

.order-timeline__group-right p {
  font-size: inherit;
  margin-bottom: 0;
  color: inherit;
}

.order-history .colgroup-1 {
  width: 16%;
}

.order-history .colgroup-2 {
  width: 24%;
}

.order-history .colgroup-3 {
  width: 60%;
}

.order-history .table {
  margin-bottom: 0;
}

/* Order Notes */
.order-notes {
  --gutter: 1.6rem;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: var(--gutter) var(--gutter);
  max-width: 750px;
  margin: auto;
}

@media (min-width: 1024px) {
  .order-notes {
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  .order-notes {
    --gutter: 2rem;
  }
}

@media (min-width: 2540px) {
  .order-notes {
    grid-template-columns: repeat(15, 1fr);
  }
}

.order-notes__top {
  grid-column: span 12;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: var(--gutter) var(--gutter);
  border-bottom: 1px solid var(--border-grey-color);
  padding-bottom: var(--gutter);
}

@media (min-width: 2540px) {
  .order-notes__top {
    grid-column: span 15;
    grid-template-columns: repeat(15, 1fr);
  }
}

.order-notes__item {
  display: flex;
  grid-column: span 12;
}

@media (min-width: 576px) {
  .order-notes__item {
    grid-column: span 6;
  }
}

@media (min-width: 1024px) {
  .order-notes__item {
    grid-column: span 4;
  }
}

@media (min-width: 1920px) {
  .order-notes__item {
    grid-column: span 3;
  }
}

.order-note {
  --gutter-x: 1.3333rem;
  flex-grow: 1;
  position: relative;
  display: flex;
}

.order-note::before {
  content: "";
  position: absolute;
  z-index: -1;
  right: 7%;
  bottom: 0;
  left: 7%;
  content: "";
  height: 22px;
  box-shadow: var(--dropdown-shadow-shape);
}

.order-note__container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: var(--card-border-radius);
  background-color: var(--background-secondary-color);
  border-bottom: 5px solid var(--orange-color-2);
  border-radius: var(--card-border-radius);
  box-shadow: var(--card-shadow);
}

.order-note__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px var(--gutter-x);
  border-bottom: 1px dashed var(--border-grey-color);
}

.order-note__date {
  font-size: 13px;
  line-height: 1.84615em;
  color: var(--text-secondary-color);
}

.order-note__button-push {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: var(--t-base);
  color: var(--text-secondary-color);
}

.order-notes__top .order-note__button-push {
  color: inherit;
}

.order-note__button-push:hover {
  color: var(--text-secondary-color);
  background-color: var(--control-background-hover-light);
}

.order-note__button-push svg[class*="icon"] {
  width: 22px;
  height: 22px;
}

.order-note__content {
  flex-grow: 1;
  padding: 17px var(--gutter-x) 15px;
}

.order-note__title {
  display: grid;
  margin-bottom: 1.0667rem;
  font-weight: 500;
  font-size: 1.6667rem;
  line-height: 1.16em;
}

.order-note__title span {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@media (max-width: 575px) {
  .order-note__title {
    margin-bottom: 10px;
    font-size: 1.2rem;
  }
}

.order-note__description p {
  margin-bottom: 1.4rem;
  font-size: inherit;
  line-height: inherit;
}

@media (max-width: 575px) {
  .order-note__description p {
    margin-bottom: 0.8rem;
  }
}

/* Order Messages */
.order-messages {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: auto;
}

.order-messages--list {
  display: grid;
  grid-template-columns: repeat(1, 1fr 250px);
  max-width: 100%;
  padding: 0;
}

@media (max-width: 991px) {
  .order-messages--list {
    grid-template-columns: repeat(1, 1fr);
  }
}

.order-messages__items {
  display: flex;
  flex-direction: column;
}

.order-messages__content {
  flex-grow: 1;
  margin-bottom: 2rem;
}

@media (min-width: 768px) {
  .order-messages__content {
    min-height: 440px;
    height: calc(100vh - 26rem);
  }
}

.order-messages--list .order-messages__content {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .order-messages--list .order-messages__content {
    min-height: 440px;
    height: calc(100vh - 26rem);
  }
}

.order-messages__divider {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -5px;
  margin-bottom: 1.6667rem;
  font-weight: normal;
  font-size: 13px;
  line-height: 1;
  color: var(--text-secondary-color);
}

.order-messages__divider::before, .order-messages__divider::after {
  flex-grow: 1;
  content: "";
  border-top: 1px solid var(--border-grey-color);
}

.order-messages__divider::before {
  margin-right: 16px;
}

.order-messages__divider::after {
  margin-left: 16px;
}

.order-messages--list .order-messages__input-group {
  border-top: 1px solid var(--border-grey-color);
}

.order-messages__input {
  height: 3.7333rem;
  padding: 1.2rem 106px 1.1333rem 2.6667rem;
}

.order-messages__input::placeholder {
  color: var(--text-secondary-color);
}

.order-messages--list .order-messages__input {
  height: 4rem;
  padding-top: 1.3333rem;
  padding-bottom: 1.2667rem;
  border-radius: 0;
  border-bottom-left-radius: var(--card-border-radius);
}

@media (max-width: 991px) {
  .order-messages--list .order-messages__input {
    border-bottom-right-radius: var(--card-border-radius);
  }
}

@media (max-width: 575px) {
  .order-messages__input {
    height: 46px;
    padding: 13px 60px 12px 12px;
  }
}

.order-messages__input-append {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding-right: 37px;
}

@media (max-width: 575px) {
  .order-messages__input-append {
    padding-right: 0;
  }
}

.order-messages__input-button {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-secondary-color);
  transition: var(--t-base);
}

.order-messages__input-button:hover {
  color: var(--text-primary-color);
}

.order-messages__input-button svg[class*="icon"] {
  width: 22px;
  height: 22px;
}

.order-messages__sidebar {
  display: flex;
  flex-direction: column;
  padding: 30px 25px;
  border-left: 1px solid var(--border-grey-color);
}

@media (max-width: 991px) {
  .order-messages__sidebar {
    order: -1;
    border-left: none;
    border-bottom: 1px solid var(--border-grey-color);
  }
}

.order-messages__sidebar-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.order-messages__sidebar-divider {
  align-self: stretch;
  margin: 1.6rem 0;
  border-top: 1px solid var(--border-grey-color);
}

.order-messages__sidebar-bottom {
  margin-top: 2rem;
}

.order-messages__sidebar-submit {
  display: flex;
  justify-content: center;
}

.order-messages__sidebar-submit .button {
  min-width: 7.3333rem;
}

.order-messages__user-avatar {
  position: relative;
  z-index: 0;
  width: 150px;
  height: 150px;
  margin-bottom: 1.8rem;
  font-weight: 500;
  font-size: 3.3333rem;
  line-height: 1;
  text-transform: uppercase;
  border-radius: 50%;
  color: var(--white-color);
}

.order-messages__user-avatar .badge-signal {
  position: absolute;
  right: 17px;
  bottom: 6px;
  width: 15px;
  height: 15px;
  border-width: 3px;
}

.order-messages__user-avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.order-messages__avatar-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.order-messages__user-title {
  margin-bottom: 8px;
}

.order-messages__user-address {
  display: grid;
  grid-gap: 16px;
  list-style: none;
  padding: 0;
  margin: 0;
  color: var(--text-secondary-color);
}

.order-messages__user-address > * {
  display: grid;
  align-items: center;
  grid-gap: 8px;
  grid-template-columns: repeat(1, 22px 1fr);
}

.order-messages__user-address svg[class*="icon"] {
  width: 22px;
  height: 22px;
  fill: var(--text-secondary-color);
}

.order-message {
  --column-avatar: 65px;
  display: grid;
  grid-template-columns: repeat(1, var(--column-avatar) 1fr);
  padding: 1.2rem 1.0667rem 1.0667rem;
  margin-bottom: 2rem;
  border-radius: var(--card-border-radius);
  background-color: var(--background-primary-color);
}

.order-messages--list .order-message {
  margin-bottom: 0;
  border-radius: 0;
  background-color: var(--background-secondary-color);
}

@media (min-width: 768px) {
  .order-messages--list .order-message {
    padding: 1.6667rem 2.6667rem;
  }
}

@media (max-width: 575px) {
  .order-message {
    --column-avatar: 44px;
    padding: 1rem 10px;
  }
  .order-messages--list .order-message {
    padding: 1.5rem 1.2rem;
  }
}

.order-message:last-of-type {
  margin-bottom: 0;
}

.order-message--outline {
  background-color: var(--background-secondary-color);
  border: 1px solid var(--border-grey-color);
}

.order-messages--list .order-message--outline {
  background-color: var(--background-primary-color);
  border: none;
}

.order-message__avatar {
  position: relative;
  z-index: 1;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(var(--column-avatar) - 15px);
  height: calc(var(--column-avatar) - 15px);
  border-radius: 50%;
  font-size: 14px;
  line-height: 1.14286em;
  color: var(--white-color);
}

@media (max-width: 575px) {
  .order-message__avatar {
    width: calc(var(--column-avatar) - 10px);
    height: calc(var(--column-avatar) - 10px);
    font-size: 10px;
  }
}

.order-message__avatar .badge-signal {
  position: absolute;
  right: 3px;
  bottom: 1px;
}

.order-message__avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.order-message__avatar-text {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.order-message__right {
  flex-grow: 1;
}

.order-message__header {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}

.order-message__title {
  display: grid;
  margin: 0 1rem 6px 0;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.2em;
}

@media (max-width: 575px) {
  .order-message__title {
    margin-bottom: 4px;
    font-size: 13px;
  }
}

@media (max-width: 374px) {
  .order-message__title {
    font-size: 12px;
  }
}

.order-message__title span {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.order-message__date {
  margin-bottom: 6px;
  font-size: 13px;
  line-height: 1.38462em;
  color: var(--text-secondary-color);
  white-space: nowrap;
}

@media (max-width: 575px) {
  .order-message__date {
    margin-bottom: 4px;
    font-size: 12px;
  }
}

.order-message__label {
  grid-column: span 2;
}

.order-message__text-wrapper {
  grid-column: span 2;
  margin-top: 1.3333rem;
}

@media (min-width: 576px) {
  .order-message__text-wrapper {
    margin-left: var(--column-avatar);
  }
}

@media (max-width: 575px) {
  .order-message__text-wrapper {
    margin-top: 1rem;
  }
}

.order-message__text {
  width: fit-content;
  padding: 10px 1rem;
  margin-bottom: 8px;
  border-radius: 0 var(--radius-lg) var(--radius-lg) var(--radius-lg);
  background-color: var(--background-secondary-color);
}

@media (max-width: 575px) {
  .order-message__text {
    padding: 10px 8px;
  }
}

.order-message__text img {
  max-width: 22px;
}

.order-message__text:last-child {
  margin-bottom: 0;
}

.order-message--outline .order-message__text {
  background-color: var(--background-primary-color);
}

.order-messages--list .order-message__text {
  background-color: var(--background-primary-color);
}

.order-messages--list .order-message--outline .order-message__text {
  background-color: var(--background-secondary-color);
}

/* Customer Account */
.customer-account {
  --account-gutter: 1.6rem 1.6rem;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: var(--account-gutter);
  width: 100%;
  max-width: 1170px;
  margin: auto;
}

.customer-account__item-1, .customer-account__item-2, .customer-account__item-3, .customer-account__item-4, .customer-account__item-5 {
  grid-column: span 12;
}

@media (max-width: 767px) {
  .customer-account__item-1, .customer-account__item-3 {
    order: -1;
  }
}

@media (min-width: 768px) {
  .customer-account {
    --account-gutter: 2rem 2rem;
  }
  .customer-account__item-1, .customer-account__item-3 {
    grid-row-start: 1;
    grid-column: span 6;
  }
}

@media (min-width: 992px) {
  .customer-account__item-2, .customer-account__item-4 {
    grid-column: span 6;
  }
}

@media (min-width: 1200px) {
  .customer-account {
    grid-template-columns: 280px 1fr 1fr;
  }
  .customer-account__item-1, .customer-account__item-2, .customer-account__item-3, .customer-account__item-4 {
    grid-column: auto;
  }
  .customer-account__item-1, .customer-account__item-3 {
    grid-row-start: 1;
    grid-row-end: 3;
  }
  .customer-account__item-2 {
    grid-row-start: 1;
  }
  .customer-account__item-4 {
    grid-row-start: 2;
  }
  .customer-account__item-5 {
    grid-column: span 3;
  }
}

.customer-card {
  padding-top: 2rem;
}

@media (min-width: 1200px) {
  .customer-card {
    --card-gutter-x: 2rem;
  }
}

.customer-card .card__header {
  margin-bottom: 2rem;
}

.customer-card__header-right {
  margin-left: auto;
}

.customer-card__btn-task {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: -13px;
  border-radius: 50%;
  transition: var(--t-base);
}

.customer-card__btn-task:hover {
  background-color: var(--control-background-hover-light);
}

.customer-card__btn-task svg {
  width: 22px;
  height: 22px;
}

.customer-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.customer-profile .card__body,
.customer-profile .card__footer .card__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.customer-profile .card__body {
  padding-bottom: 1.6rem;
}

.customer-profile__avatar {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: flex-start;
  width: 250px;
  height: 220px;
  margin-bottom: 1.6rem;
}

.customer-profile__avatar svg {
  width: 100%;
  height: 100%;
  min-height: 270px;
  margin-top: -4px;
  pointer-events: none;
}

.customer-profile__title {
  margin-bottom: 0.53333em;
  font-weight: 500;
  font-size: 2rem;
  line-height: 1.16667em;
}

.customer-profile__address {
  display: grid;
  grid-gap: 16px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.customer-profile__address > * {
  display: grid;
  align-items: center;
  grid-gap: 8px;
  grid-template-columns: repeat(1, 22px 1fr);
}

.customer-profile__address svg[class*="icon"] {
  width: 22px;
  height: 22px;
  fill: var(--text-secondary-color);
}

.customer-details__list,
.customer-shipping__list {
  display: grid;
  grid-gap: 1rem 0;
  list-style: none;
  padding: 0;
  margin: 0;
}

.customer-wish-list__view a {
  color: var(--primary-color);
}

.customer-wish-list__products {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: var(--account-gutter);
}

.customer-wish-list__product {
  display: grid;
  grid-column: span 4;
  grid-template-columns: repeat(1, 148px 1fr);
  background-color: var(--background-primary-color);
  border-radius: var(--card-border-radius);
}

@media (max-width: 1199px) {
  .customer-wish-list__products .customer-wish-list__product {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 991px) {
  .customer-wish-list__products .customer-wish-list__product {
    grid-column: span 6;
  }
  .customer-wish-list__products .customer-wish-list__product:nth-child(n+3) {
    display: none;
  }
}

@media (max-width: 575px) {
  .customer-wish-list__product {
    grid-template-columns: repeat(1, 1fr);
  }
  .customer-wish-list__products .customer-wish-list__product {
    grid-column: span 12;
  }
  .customer-wish-list__products .customer-wish-list__product:nth-child(n+2) {
    display: none;
  }
}

.customer-wish-list__product-image {
  position: relative;
  background-image: url(../img/content/product/placeholder-thumbnail.jpg);
  background-size: cover;
  background-position: center center;
  overflow: hidden;
  border-radius: var(--card-border-radius) 0 0 var(--card-border-radius);
}

@media (max-width: 1199px) {
  .customer-wish-list__products .customer-wish-list__product-image {
    min-height: 170px;
    border-radius: var(--card-border-radius) var(--card-border-radius) 0 0;
  }
}

@media (max-width: 575px) {
  .customer-wish-list__product-image {
    min-height: 170px;
    border-radius: var(--card-border-radius) var(--card-border-radius) 0 0;
  }
}

.customer-wish-list__product-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: var(--white-color);
  object-position: 50% 50%;
}

.customer-wish-list__product-right {
  padding: 1.25rem 1.6rem;
  text-align: center;
}

@media (min-width: 576px) {
  .customer-wish-list__product-right {
    padding: 0 1.0667rem 0 1.6rem;
    margin: 1.1333rem 0;
    border-left: 1px solid var(--border-grey-color);
    text-align: left;
  }
}

@media (max-width: 1199px) {
  .customer-wish-list__products .customer-wish-list__product-right {
    padding: 1.25rem 1.6rem;
    margin: 0;
    border-left: 0;
    text-align: center;
  }
}

.customer-wish-list__params {
  list-style: none;
  padding: 0;
  margin-bottom: 7px;
}

.customer-wish-list__params b {
  font-weight: 500;
}

.modal-account {
  --gutter-content-y: 2rem;
}

.modal-account .modal__wrap {
  max-width: 840px;
}

.modal-account .modal__body {
  padding: 0;
}

.modal-account__content {
  display: grid;
  grid-template-columns: 300px 1fr;
}

@media (max-width: 767px) {
  .modal-account__content {
    grid-template-columns: 1fr;
  }
}

.modal-account__left {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--gutter-content-y) 0;
  background-color: var(--background-primary-color);
  border-radius: var(--card-border-radius) 0 0 var(--card-border-radius);
}

@media (max-width: 767px) {
  .modal-account__left {
    margin-bottom: 2rem;
    border-radius: var(--card-border-radius) var(--card-border-radius) 0 0;
  }
}

.modal-account__right {
  padding: var(--gutter-content-y) 2.6667rem;
}

@media (max-width: 1199px) {
  .modal-account__right {
    padding: var(--gutter-content-y) 1.6rem;
  }
}

.modal-account__upload {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: flex-start;
  width: 260px;
  height: 220px;
  margin-bottom: 4.8rem;
  cursor: pointer;
}

.modal-account__upload .profile-upload__thumbnail {
  position: relative;
  z-index: 1;
  margin-top: -4px;
  width: 100%;
  height: 100%;
  min-height: 270px;
  pointer-events: none;
}

.modal-account__tabs {
  align-self: stretch;
  display: flex;
  flex-direction: column;
}

.modal-account__tab {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 3.7333rem;
  padding: 1rem 1.3333rem;
  font-weight: 500;
  font-size: 1.1333rem;
  background-color: var(--background-primary-color);
}

.modal-account__tab:hover {
  color: inherit;
}

.modal-account__tab::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  border-radius: 0 5px 5px 0;
  background-color: var(--primary-color);
  opacity: 0;
  transition: var(--t-base);
}

.modal-account__tab.active {
  background-color: var(--background-secondary-color);
}

.modal-account__tab.active::before {
  opacity: 1;
}

.modal-account__tab svg[class*="icon"] {
  width: 28px;
  height: 22px;
  margin-right: 8px;
}

.modal-account__pane-header {
  margin-bottom: 2.2rem;
}

.modal-account__pane-header h2 {
  margin-bottom: 0;
}

.modal-account__form-submit {
  display: flex;
  justify-content: flex-end;
}

/* Cart */
.cart {
  --cart-item-grid: 40% 30% 20% auto;
  --cart-item-gutter-x: 2.6667rem;
  padding: 0 0 1.3333rem;
}

@media (max-width: 1199px) {
  .cart {
    --cart-item-gutter-x: 1.6rem;
  }
}

@media (max-width: 767px) {
  .cart {
    --cart-item-grid: repeat(3, 1fr);
  }
}

@media (max-width: 575px) {
  .cart {
    --cart-item-gutter-x: 1.1333rem;
    padding-top: 1.3333rem;
  }
}

.cart__header {
  display: grid;
  grid-template-columns: var(--cart-item-grid);
  padding: 24px var(--cart-item-gutter-x) 1.8667rem;
  border-bottom: 1px solid var(--border-grey-color);
}

@media (max-width: 575px) {
  .cart__header {
    display: none;
  }
}

.cart__header-title {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.2em;
  text-transform: uppercase;
}

.cart__header-title:first-child {
  padding-left: 44px;
}

@media (max-width: 1023px) {
  .cart__header-title:first-child {
    padding-left: 0;
    text-align: center;
  }
}

.product-cart {
  display: grid;
  grid-template-columns: var(--cart-item-grid);
  padding: 17px var(--cart-item-gutter-x);
  background-color: var(--background-primary-color);
  border-bottom: 1px solid var(--border-grey-color);
}

.product-cart--light {
  background-color: var(--background-secondary-color);
}

@media (max-width: 575px) {
  .product-cart:first-child {
    border-top: 1px solid var(--border-grey-color);
  }
}

.product-cart__column {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 767px) {
  .product-cart__column--item-1 {
    grid-column: span 4;
  }
}

.product-cart__item {
  display: grid;
  grid-template-columns: repeat(1, 148px 1fr);
  width: 100%;
}

@media (max-width: 1023px) {
  .product-cart__item {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
  }
}

@media (min-width: 768px) {
  .product-cart__item {
    margin-right: auto;
  }
}

.product-cart__image {
  position: relative;
  width: 100%;
  min-height: 170px;
  background-image: url(../img/content/product/placeholder-thumbnail.jpg);
  background-size: cover;
  background-position: center center;
  overflow: hidden;
}

.product-cart__image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: var(--white-color);
  object-position: 50% 50%;
}

.product-cart__params {
  list-style: none;
  padding: 1.25rem 1.6rem;
  margin: 0;
  text-align: center;
}

.product-cart__params b {
  font-weight: 500;
}

.product-cart__params .label {
  margin-top: 6px;
}

@media (min-width: 768px) {
  .product-cart__params {
    text-align: left;
  }
}

@media (min-width: 1024px) {
  .product-cart__params {
    padding: 0 1.0667rem 0 1.6rem;
  }
}

.product-cart__quantity {
  width: auto;
}

.product-cart__quantity .input, .product-cart__quantity .input-group .select2-container .select2-selection--single, .input-group .select2-container .product-cart__quantity .select2-selection--single, .product-cart__quantity .input-group--white .select2-container .select2-selection--single, .input-group--white .select2-container .product-cart__quantity .select2-selection--single, .product-cart__quantity .input-group--append-lg .select2-container .select2-selection--single, .input-group--append-lg .select2-container .product-cart__quantity .select2-selection--single {
  display: inline-block;
  width: auto;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
}

.product-cart__remove {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: var(--t-base);
}

@media (min-width: 768px) {
  .product-cart__remove {
    margin-left: auto;
  }
}

.product-cart__remove svg[class*="icon"] {
  width: 22px;
  height: 22px;
}

.product-cart__remove:hover {
  background-color: var(--control-background-hover-light);
}

/* Calendar Full */
.calendar-toolbox {
  margin: 2.6667rem auto 1.6rem;
}

@media (max-width: 991px) {
  .calendar-toolbox {
    margin-top: 1.6rem;
  }
}

@media (max-width: 575px) {
  .calendar-toolbox__row {
    display: grid;
    grid-template-columns: 50px 1fr 50px;
  }
}

.calendar-toolbox__date {
  position: relative;
  display: flex;
}

.calendar-toolbox__month {
  margin: 0 0.56em 0 0;
  font-weight: 700;
  font-size: 1.6667rem;
  line-height: 1.16em;
}

.calendar-toolbox__month.is-previous {
  animation: calendarMonthPrevious 200ms ease-in forwards, calendarMonthPreviousEnd 200ms ease-out 300ms forwards;
}

.calendar-toolbox__month.is-next {
  animation: calendarMonthNext 200ms ease-in forwards, calendarMonthNextEnd 200ms ease-out 300ms forwards;
}

@media (max-width: 575px) {
  .calendar-toolbox__month {
    font-size: 1.34rem;
  }
}

.calendar-toolbox__year {
  align-self: flex-end;
  margin-top: 1rem;
  font-weight: 700;
  line-height: 1.2rem;
  color: var(--text-secondary-color);
}

@media (max-width: 991px) {
  .calendar-toolbox .switcher-date {
    position: absolute;
    top: -4.2rem;
    right: 10px;
  }
}

@media (max-width: 575px) {
  .calendar-toolbox .switcher-date {
    top: -4rem;
  }
  .calendar-toolbox .switcher-date__input-group,
  .calendar-toolbox .switcher-date .select2-dropdown {
    display: none;
  }
}

@keyframes calendarMonthPrevious {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(50%);
    opacity: 0;
  }
}

@keyframes calendarMonthPreviousEnd {
  0% {
    transform: translateY(-50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes calendarMonthNext {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-50%);
    opacity: 0;
  }
}

@keyframes calendarMonthNextEnd {
  0% {
    transform: translateY(50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.calendar {
  --calendar-items-height: calc(100vh - 284px - 3.7333rem);
  --calendar-left-panel-width: 70px;
  position: relative;
  padding: 0;
}

@media (max-width: 991px) {
  .calendar {
    --calendar-items-height: calc(100vh - 253px - 3.7333rem);
  }
}

@media (max-width: 767px) {
  .calendar {
    --calendar-items-height: calc(100vh - 92px - 3.7333rem);
    --calendar-left-panel-width: 54px;
  }
  .calendar--day {
    --calendar-items-height: auto;
  }
}

@media (max-width: 767px) and (min-height: 700px) {
  .calendar {
    --calendar-items-height: calc(100vh - 230px - 3.7333rem);
  }
}

@media (max-width: 991px) and (orientation: landscape) {
  .calendar {
    --calendar-items-height: 130vh;
  }
}

.calendar__wrapper {
  display: grid;
  grid-template-rows: auto 1fr;
}

.calendar__content {
  display: grid;
  grid-template-rows: auto 1fr;
  max-height: 100%;
}

.calendar__top-week, .calendar__days {
  flex-grow: 1;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  min-width: 540px;
}

.calendar--timeline .calendar__top-week, .calendar--timeline .calendar__days {
  grid-template-columns: var(--calendar-left-panel-width) 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  min-width: 790px;
}

.calendar--day .calendar__top-week, .calendar--day .calendar__days {
  grid-template-columns: var(--calendar-left-panel-width) 1fr;
  min-width: 100%;
}

.calendar__top-week {
  margin-bottom: -1px;
  border-bottom: 1px solid var(--border-grey-color);
}

.calendar__top-week-hours {
  display: grid;
  justify-content: center;
  align-items: center;
  border-right: 1px solid var(--border-grey-color);
  color: var(--text-secondary-color);
}

.calendar__top-week-hours svg[class*="icon"] {
  width: 32px;
  height: 32px;
}

.calendar__top-week-day {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(0, auto));
  justify-content: center;
  align-items: center;
  height: 3.7333rem;
  padding: 0.6667rem;
  font-weight: 500;
  color: var(--text-secondary-color);
  text-align: center;
}

.calendar__top-week-day span {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.calendar__content-wrapper {
  height: var(--calendar-items-height);
  overflow-x: hidden;
}

.calendar__items, .calendar__timeline {
  display: grid;
  height: var(--calendar-items-height);
  margin-right: -1px;
}

.calendar__day {
  position: relative;
  border: 1px solid var(--border-grey-color);
  border-width: 1px 1px 0 0;
  min-height: 115px;
}

@media (max-width: 767px) {
  .calendar__day {
    min-height: 76px;
  }
}

.calendar__day .day {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2.6667rem;
  height: 2.6667rem;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.15em;
  border-radius: 50%;
  filter: drop-shadow(0px 8px 16px rgba(0, 129, 255, 0.2));
}

@media (max-width: 767px) {
  .calendar__day .day {
    font-size: 13px;
    width: 1.6rem;
    height: 1.6rem;
  }
}

.calendar__day--prev, .calendar__day--next {
  background: repeating-linear-gradient(45deg, transparent 0px, transparent 15px, var(--background-primary-color) 15px, var(--background-primary-color) 17px);
}

.calendar__day--prev .day, .calendar__day--next .day {
  color: var(--text-secondary-color);
}

.calendar__day--active {
  background-color: var(--background-primary-color);
}

.calendar__day--active .day {
  background-color: var(--primary-color);
  color: var(--white-color);
}

.calendar__timeline {
  flex-grow: 1;
  grid-template-columns: repeat(1, var(--calendar-left-panel-width) 1fr);
  min-height: 500px;
  overflow: hidden;
}

@media (max-width: 767px) {
  .calendar--day .calendar__timeline {
    min-height: 420px;
  }
}

.calendar__timeline-left {
  display: grid;
}

.calendar__timeline-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.calendar--day .calendar__timeline-grid {
  grid-template-columns: 1fr;
}

.calendar__timeline-time, .calendar__timeline-cell {
  position: relative;
  border: 1px solid var(--border-grey-color);
  border-width: 1px 1px 0 0;
}

.calendar__timeline-time {
  display: grid;
  justify-content: center;
  align-items: center;
  color: var(--text-secondary-color);
  font-weight: 500;
  font-size: 13px;
  line-height: 1.15385em;
}

.calendar__timeline-cell::before {
  content: "";
  position: absolute;
  top: -2000%;
  left: 0;
  z-index: -2;
  width: 100%;
  height: 4000%;
  pointer-events: none;
  opacity: 0;
  background-color: var(--background-primary-color);
}

.calendar--day .calendar__timeline-cell::before {
  display: none;
}

.calendar__timeline-cell:hover::before {
  opacity: 1;
}

.calendar__timeline-cell-divider {
  position: absolute;
  top: 50%;
  height: 2px;
  width: 100%;
  transform: translateY(-50%);
}

.calendar__reminder {
  grid-column: span 7;
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto;
  grid-gap: 1.5333rem;
  padding: 11px 2.6667rem;
  line-height: 1.2em;
  border-top: 1px solid var(--border-grey-color);
  background-color: var(--background-primary-color);
}

@media (max-width: 767px) {
  .calendar__reminder {
    padding: 12px 1rem;
  }
}

.calendar__reminder-time {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 1.5333rem;
  font-weight: 500;
  font-size: 1.3333rem;
  line-height: 1.15em;
}

.calendar__reminder-time::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  width: 5px;
  border-radius: 12px;
}

.calendar__reminder.color-orange .calendar__reminder-time::before {
  background-color: var(--orange-color-2);
}

.calendar__reminder.color-green .calendar__reminder-time::before {
  background-color: var(--green-color);
}

.calendar__reminder.color-red .calendar__reminder-time::before {
  background-color: var(--red-color-2);
}

.calendar__reminder.color-blue .calendar__reminder-time::before {
  background-color: var(--blue-color);
}

.calendar__reminder.color-teal .calendar__reminder-time::before {
  background-color: var(--teal-color);
}

.calendar__reminder-time svg[class*="icon"] {
  width: 26px;
  height: 26px;
}

.calendar__reminder-date {
  font-size: 13px;
  line-height: 1.15385em;
  color: var(--text-secondary-color);
}

.c-events {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  pointer-events: none;
}

.calendar__timeline-cell .c-events {
  top: 0;
  bottom: auto;
  height: 100%;
}

.c-event {
  position: relative;
  z-index: 2;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 0 8px;
  align-items: center;
  width: min-content;
  min-height: 1.9333rem;
  padding: 7px 1rem;
  margin: 6px 8px;
  border-radius: var(--radius-base);
  font-size: 13px;
  line-height: 1.15385em;
  pointer-events: all;
}

.c-event--time {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  margin: 4px;
}

.c-event--empty {
  pointer-events: none;
}

.c-event--draggable {
  cursor: url("__static__content/move.svg"), auto;
}

.c-event--draggable::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 3px;
  z-index: 5;
  width: 9px;
  height: 21px;
  margin: auto;
  background: url(__static__content/dot.svg) no-repeat center center/cover;
  pointer-events: none;
}

@media (max-width: 767px) {
  .c-event {
    min-height: 18px;
    padding: 3px 1rem;
    font-size: 11px;
  }
}

.c-event, .c-event:hover {
  color: #fff;
}

.c-event:hover {
  box-shadow: 0px 8px 16px transparent !important;
}

.c-event.color-orange {
  box-shadow: 0px 8px 16px rgba(253, 191, 94, 0.2);
}

.c-event.color-green {
  box-shadow: 0px 8px 16px rgba(9, 182, 109, 0.2);
}

.c-event.color-red {
  box-shadow: 0px 8px 16px rgba(255, 61, 87, 0.2);
}

.c-event.color-blue {
  box-shadow: 0px 8px 16px rgba(0, 129, 255, 0.2);
}

.c-event.color-teal {
  box-shadow: 0px 8px 16px rgba(34, 204, 226, 0.2);
}

.c-event__tools {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(0, auto));
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: var(--radius-base);
  pointer-events: none;
  opacity: 1;
}

.c-event__tools .button-icon {
  width: 32px;
  height: 32px;
  background-color: transparent;
}

.c-event__tools .button-icon:hover {
  opacity: 0.7;
}

.c-event--active:hover .c-event__tools {
  opacity: 1;
}

.c-event--active:hover .c-event__tools .button-icon {
  pointer-events: all;
}

.c-event__name {
  display: grid;
  font-weight: 500;
}

.c-event__name span {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.c-event__time {
  text-align: right;
}

.c-event-drop {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  margin: 4px;
  z-index: -1;
  border: 1px dashed var(--grey-color-4);
  border-radius: var(--radius-base);
  background-color: var(--background-primary-color);
  pointer-events: none;
}

.dropdown-c-event {
  --dropdown-c-event-gutter-x: 2rem;
  position: absolute;
  top: 4.2667rem;
  right: 6px;
  left: auto;
  z-index: 1000;
  display: none;
  max-width: 20.6667rem;
  padding: 0;
  border: none;
  border-radius: var(--card-border-radius);
  background-color: transparent;
  box-shadow: var(--dropdown-shadow);
}

.dropdown-c-event.show {
  display: block;
  animation: calendarEventShow 300ms ease-in-out forwards;
}

@media (max-width: 575px) {
  .dropdown-c-event {
    --dropdown-c-event-gutter-x: 1.2rem;
  }
}

.dropdown-c-event::before {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: auto;
  left: 0;
  transform: none;
  content: "";
  width: 100%;
  height: 100%;
  border: none;
  border-radius: var(--card-border-radius);
  background-color: var(--background-secondary-color);
}

.dropdown-c-event::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  transform: none;
  width: 80%;
  height: 26px;
  margin: auto;
  border: none;
  box-shadow: var(--dropdown-shadow-shape);
}

.dropdown-c-event__arrow {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 30px;
  height: 30px;
  border-top-left-radius: var(--card-border-radius);
}

.dropdown-c-event.color-orange .dropdown-c-event__arrow {
  background: repeating-linear-gradient(-45deg, transparent 0%, transparent 50%, var(--orange-color-2) 50%, var(--orange-color-2) 100%);
}

.dropdown-c-event.color-green .dropdown-c-event__arrow {
  background: repeating-linear-gradient(-45deg, transparent 0%, transparent 50%, var(--green-color) 50%, var(--green-color) 100%);
}

.dropdown-c-event.color-red .dropdown-c-event__arrow {
  background: repeating-linear-gradient(-45deg, transparent 0%, transparent 50%, var(--red-color-2) 50%, var(--red-color-2) 100%);
}

.dropdown-c-event.color-blue .dropdown-c-event__arrow {
  background: repeating-linear-gradient(-45deg, transparent 0%, transparent 50%, var(--blue-color) 50%, var(--blue-color) 100%);
}

.dropdown-c-event.color-teal .dropdown-c-event__arrow {
  background: repeating-linear-gradient(-45deg, transparent 0%, transparent 50%, var(--teal-color) 50%, var(--teal-color) 100%);
}

.dropdown-c-event__content {
  position: relative;
  z-index: 2;
  padding: 2rem var(--dropdown-c-event-gutter-x) 0;
}

@media (max-width: 575px) {
  .dropdown-c-event__content {
    padding: 1.5rem var(--dropdown-c-event-gutter-x) 0;
  }
}

.dropdown-c-event__top {
  display: grid;
  grid-template-columns: 60px 1fr;
  grid-gap: 0 1.9333rem;
  align-items: flex-start;
  margin-bottom: 2.4667rem;
}

@media (max-width: 575px) {
  .dropdown-c-event__top {
    grid-gap: 0 14px;
  }
}

.dropdown-c-event__date {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: var(--radius-lg);
  color: var(--grey-color-3);
  background-color: var(--white-color);
  box-shadow: 0px 12px 16px rgba(169, 194, 209, 0.15), 0px 4px 4px rgba(169, 194, 209, 0.1);
}

.dropdown-c-event__month {
  padding: 6px 9px 5px;
  border-radius: var(--radius-lg) var(--radius-lg) 0 0;
  font-weight: 500;
  font-size: 8px;
  line-height: 1;
  text-transform: uppercase;
  box-shadow: 0px 4px 4px rgba(169, 194, 209, 0.05), 0px 8px 16px rgba(169, 194, 209, 0.1);
  background-color: var(--background-secondary-reverse);
  color: var(--white-color);
}

.dropdown-c-event__day {
  margin: 3px 5px 10px;
  font-weight: 700;
  font-size: 2rem;
  line-height: 1;
  text-transform: uppercase;
}

.dropdown-c-event__name {
  margin-bottom: 0.32em;
  font-weight: 700;
  font-size: 1.6667rem;
  line-height: 1.16em;
}

@media (max-width: 575px) {
  .dropdown-c-event__name {
    font-size: 1.2rem;
  }
}

.dropdown-c-event__time {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 1.5333rem;
  line-height: 1;
  color: var(--text-secondary-color);
}

.dropdown-c-event__time svg[class*="icon"] {
  margin-right: 4px;
  width: 25px;
  height: 25px;
}

.dropdown-c-event__title {
  margin-bottom: 6px;
  font-size: 15px;
  line-height: 1.2em;
}

.dropdown-c-event__text {
  color: var(--text-secondary-color);
}

.dropdown-c-event__footer {
  margin: 1.6667rem calc(var(--dropdown-c-event-gutter-x) * -1) 0;
  padding: 1.6667rem var(--dropdown-c-event-gutter-x) 1.6667rem;
  border-top: 1px solid var(--border-grey-color);
  border-radius: 0 0 var(--card-border-radius) var(--card-border-radius);
  background-color: var(--background-primary-color);
}

@keyframes calendarEventShow {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

.input-events {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
}

.input-events__item {
  position: relative;
  height: 1.6rem;
  background-color: transparent;
  cursor: pointer;
}

.input-events__item input[type="radio"] {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

.input-events__item input[type="radio"]:checked ~ .input-events__item-marker {
  z-index: 1;
  transform: scale(1.075, 1.2);
  box-shadow: 0px 0px 24px rgba(169, 194, 209, 0.3), 0px 0px 4px rgba(169, 194, 209, 0.2);
}

.input-events__item-marker {
  position: relative;
  display: block;
  height: 100%;
  transition: var(--t-base);
}

.input-events__item.color-orange .input-events__item-marker {
  background-color: var(--orange-color-2);
}

.input-events__item.color-green .input-events__item-marker {
  background-color: var(--green-color);
}

.input-events__item.color-red .input-events__item-marker {
  background-color: var(--red-color);
}

.input-events__item.color-teal .input-events__item-marker {
  background-color: var(--teal-color);
}

.input-events__item.color-grey-light .input-events__item-marker {
  background-color: var(--border-grey-color);
}

/* Inbox */
.inbox-grid {
  --inbox-top-height: 4.2rem;
  --inbox-details-bottom-height: 5.7333rem;
  position: relative;
  display: grid;
  grid-template-columns: 240px 380px 1fr;
  background-color: var(--background-secondary-color);
}

@media (max-width: 1365px) {
  .inbox-grid {
    grid-template-columns: minmax(340px, 36%) 1fr;
  }
  .inbox-grid .sidebar-panel {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 3;
    height: auto;
    margin: 10px;
    border-right: none;
    border-radius: var(--card-border-radius);
    transform: translateX(-100%) translateX(-1.3333rem);
    opacity: 0;
    box-shadow: var(--card-shadow);
    transition: var(--t-base);
    transition-property: transform, opacity;
    transition-timing-function: linear;
  }
  .inbox-grid .sidebar-panel.active {
    transform: translateX(0px);
    opacity: 1;
  }
  .inbox-grid .sidebar-panel__top {
    border-radius: var(--card-border-radius) var(--card-border-radius) 0 0;
  }
}

@media (max-width: 1023px) {
  .inbox-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 767px) {
  .inbox-grid {
    --inbox-top-height: 3.2rem;
    --inbox-details-bottom-height: 54px;
  }
}

.inbox-mails {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: var(--background-primary-color);
  border-right: 1px solid var(--border-grey-color);
}

@media (max-width: 1023px) {
  .inbox-mails {
    border-right: none;
  }
}

.inbox-mails .toggle-sidebar {
  align-self: center;
  height: calc(var(--inbox-top-height) - 4px);
  width: calc(var(--inbox-top-height) - 4px);
  margin: 0 10px 0 8px;
}

@media (min-width: 768px) {
  .inbox-mails .toggle-sidebar {
    height: calc(var(--inbox-top-height) - 14px);
    width: calc(var(--inbox-top-height) - 14px);
    margin: 0 0 0 5px;
  }
}

@media (min-width: 1366px) {
  .inbox-mails .toggle-sidebar {
    display: none;
  }
}

@media (min-width: 576px) {
  .inbox-mails .toggle-search {
    display: none;
  }
}

.inbox-mails__top {
  position: relative;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: var(--inbox-top-height);
  padding-right: 1.1rem;
  border-bottom: 1px solid var(--border-grey-color);
}

.inbox-mails .module-search {
  flex-grow: 1;
  display: flex;
  margin-right: 8px;
}

@media (min-width: 576px) {
  .inbox-mails .module-search {
    margin-left: 8px;
  }
}

@media (max-width: 575px) {
  .inbox-mails .module-search {
    position: absolute;
    top: 0;
    right: 0;
    left: 48px;
    z-index: 1;
    display: none;
    height: 100%;
    background-color: var(--background-secondary-color);
  }
  .inbox-mails .module-search.show {
    display: block;
  }
}

.inbox-mails .module-search .input-group__prepend {
  left: 12px;
}

.inbox-mails .module-search .input-group__prepend svg[class*="icon"] {
  width: 18px;
  height: 18px;
}

@media (max-width: 575px) {
  .inbox-mails .module-search .input-group__prepend {
    display: none;
  }
}

.inbox-mails .module-search .input, .inbox-mails .module-search .input-group .select2-container .select2-selection--single, .input-group .select2-container .inbox-mails .module-search .select2-selection--single, .inbox-mails .module-search .input-group--white .select2-container .select2-selection--single, .input-group--white .select2-container .inbox-mails .module-search .select2-selection--single, .inbox-mails .module-search .input-group--append-lg .select2-container .select2-selection--single, .input-group--append-lg .select2-container .inbox-mails .module-search .select2-selection--single {
  height: 100%;
}

@media (min-width: 576px) {
  .inbox-mails .module-search .input, .inbox-mails .module-search .input-group .select2-container .select2-selection--single, .input-group .select2-container .inbox-mails .module-search .select2-selection--single, .inbox-mails .module-search .input-group--white .select2-container .select2-selection--single, .input-group--white .select2-container .inbox-mails .module-search .select2-selection--single, .inbox-mails .module-search .input-group--append-lg .select2-container .select2-selection--single, .input-group--append-lg .select2-container .inbox-mails .module-search .select2-selection--single {
    padding-left: 2.4rem;
  }
}

.inbox-mails__sort {
  align-self: center;
  position: relative;
  margin-left: auto;
  white-space: nowrap;
}

.inbox-mails__sort .dropdown-menu {
  top: 100% !important;
  right: -14px;
  min-width: auto;
  transform: none !important;
}

.inbox-mails__sort-arrow {
  display: inline-block;
  vertical-align: middle;
  width: 22px;
  height: 22px;
}

.inbox-mails__sort-arrow svg {
  display: block;
  width: 100%;
  height: 100%;
}

.inbox-mails__content {
  height: calc(100vh - var(--header-height) - var(--inbox-top-height));
}

.inbox-mails__list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.inbox-mails__list-item:not(:last-child) {
  border-bottom: 1px solid var(--border-grey-color);
}

.inbox-mails__item {
  display: flex;
  padding: 1.3333rem 1.6rem;
}

@media (max-width: 767px) {
  .inbox-mails__item {
    padding: 10px;
  }
}

.inbox-mails__item.active {
  background-color: var(--background-secondary-color);
}

.inbox-mails__item-avatar {
  flex-shrink: 0;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.6667rem;
  height: 2.6667rem;
  border-radius: 50%;
  margin-right: 0.8rem;
  font-size: 14px;
  line-height: 1;
  color: var(--white-color);
}

.inbox-mails__item-avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.inbox-mails__item-avatar-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
}

.inbox-mails__item-right {
  flex-grow: 1;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
}

.inbox-mails__item-right-group {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.inbox-mails__item-attachment {
  vertical-align: middle;
  width: 20px;
  height: 20px;
  color: var(--text-secondary-color);
}

.inbox-mails__item-attachment svg[class*="icon"] {
  display: block;
  width: 100%;
  height: 100%;
}

.inbox-mails__item-name {
  display: grid;
  grid-template-columns: auto auto;
  margin: 0;
  font-weight: 500;
}

.inbox-mails__item-name span:first-child {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.inbox-mails__item-name .marker-item {
  width: 8px;
  height: 8px;
  margin-left: 7px;
}

.inbox-mails__item-time {
  margin-left: 0.6667rem;
  font-size: 0.8667rem;
  color: var(--text-secondary-color);
  white-space: nowrap;
}

.inbox-mails__item-message {
  grid-column: span 2;
  margin-top: 0.6667rem;
}

.inbox-mails__item-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 0;
  font-size: 13px;
  line-height: 1.53846em;
  color: var(--text-secondary-color);
  overflow: hidden;
}

.inbox-details {
  --inbox-details-gutter-x: 3.3333rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: var(--background-secondary-color);
}

@media (max-width: 767px) {
  .inbox-details {
    --inbox-details-gutter-x: 1rem;
  }
}

@media (max-width: 1023px) {
  .inbox-details {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: none;
    height: auto;
    width: 100%;
  }
  .inbox-grid--show-details .inbox-details {
    display: flex;
  }
}

.inbox-details__dismiss {
  width: calc(var(--inbox-top-height) - 8px) !important;
  height: calc(var(--inbox-top-height) - 8px) !important;
  border-radius: var(--radius-base);
}

@media (min-width: 1024px) {
  .inbox-details__dismiss {
    display: none;
  }
}

.inbox-details__dismiss svg[class*="icon"] {
  transform: rotate(-90deg);
}

.inbox-details__top {
  flex-shrink: 0;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  justify-content: space-between;
  align-items: center;
  height: var(--inbox-top-height);
  padding: 0 calc(var(--inbox-details-gutter-x) - 0.6667rem);
  border-bottom: 1px solid var(--border-grey-color);
}

@media (max-width: 575px) {
  .inbox-details__top {
    grid-template-columns: 1fr auto auto;
  }
  .inbox-details__top .button-icon {
    width: 32px;
    height: 32px;
  }
}

.inbox-details__top-right {
  margin-left: auto;
}

.inbox-details__pagination {
  display: flex;
  align-items: center;
}

.inbox-details__pagination .button-icon__icon {
  width: 6px;
  height: 10px;
}

.inbox-details__content {
  height: calc(100vh - var(--header-height) - var(--inbox-top-height) - var(--inbox-details-bottom-height));
  padding: 1.3333rem var(--inbox-details-gutter-x) 1.0667rem;
}

.inbox-details__header {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.6667rem;
}

.inbox-details__header .media-item__icon {
  margin-right: 1.6rem;
}

@media (max-width: 767px) {
  .inbox-details__header .media-item__icon {
    margin-right: 1rem;
  }
}

.inbox-details__header .media-item__title {
  margin-bottom: 7px;
  font-weight: 500;
}

.inbox-details__text-message {
  margin-bottom: 1.3333rem;
  font-size: inherit;
  line-height: 2.13333em;
  font-family: inherit;
  white-space: pre-line;
  color: inherit;
}

.inbox-details__files {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16.6667rem, max-content));
  grid-gap: 1.0667rem;
  margin-bottom: 2rem;
}

.inbox-details__bottom {
  height: var(--inbox-details-bottom-height);
  padding: 1.0667rem var(--inbox-details-gutter-x) 0 calc(var(--inbox-details-gutter-x) - 0.6667rem);
}

@media (max-width: 767px) {
  .inbox-details__bottom {
    display: flex;
    align-items: center;
    padding-top: 0;
  }
  .inbox-details__bottom .row {
    flex-grow: 1;
  }
}

/* Chat */
.chat-grid {
  --chat-top-height: 4.2rem;
  --chat-details-bottom-height: 4rem;
  position: relative;
  display: grid;
  grid-template-columns: 380px 1fr;
  background-color: var(--background-secondary-color);
}

@media (max-width: 1199px) {
  .chat-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 767px) {
  .chat-grid {
    --chat-top-height: 3.2rem;
  }
}

.chat-users {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: var(--background-primary-color);
  border-right: 1px solid var(--border-grey-color);
}

@media (max-width: 1199px) {
  .chat-users {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    max-width: 320px;
    height: auto;
    transform: translateX(-100%) translateX(-1.3333rem);
    transition: all 200ms linear;
    transition-property: transform, opacity;
  }
  .chat-grid--show-users .chat-users {
    transform: translateX(0px);
  }
}

.chat-users__top {
  position: relative;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: var(--chat-top-height);
  padding-right: 1.1rem;
  border-bottom: 1px solid var(--border-grey-color);
}

.chat-users__search {
  flex-grow: 1;
  display: flex;
  margin-right: 8px;
  margin-left: 8px;
}

.chat-users__search .input-group__prepend {
  left: 12px;
}

.chat-users__search .input-group__prepend svg[class*="icon"] {
  width: 18px;
  height: 18px;
}

.chat-users__search .input, .chat-users__search .input-group .select2-container .select2-selection--single, .input-group .select2-container .chat-users__search .select2-selection--single, .chat-users__search .input-group--white .select2-container .select2-selection--single, .input-group--white .select2-container .chat-users__search .select2-selection--single, .chat-users__search .input-group--append-lg .select2-container .select2-selection--single, .input-group--append-lg .select2-container .chat-users__search .select2-selection--single {
  height: 100%;
  padding-right: 8px;
  padding-left: 2.6667rem;
}

.chat-users__button-add-users .button-icon__icon:last-child {
  display: none;
  transform: rotate(90deg);
}

.chat-users__button-add-users.active .button-icon__icon:first-child {
  display: none;
}

.chat-users__button-add-users.active .button-icon__icon:last-child {
  display: block;
}

.chat-users__add-content {
  position: absolute;
  top: var(--chat-top-height);
  bottom: 0;
  width: 100%;
  z-index: 1;
  background-color: var(--background-primary-color);
  transform: translateX(-100%);
  transition: all 200ms linear;
  transition-property: transform, opacity;
}

.chat-grid--show-users-add .chat-users__add-content {
  transform: translateX(0px);
}

.chat-users__content {
  height: calc(100vh - var(--header-height) - var(--chat-top-height));
}

.chat-grid--show-users-add .chat-users__content .chat-users__list {
  transition: all 500ms linear;
  transform: translateX(100%);
}

.chat-users__list {
  list-style: none;
  padding: 0;
  margin: 0;
  transform: translateX(0px);
  transition: all 200ms linear;
  transition-property: transform, opacity;
}

.chat-users__list-header {
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 3px 1.6rem;
  border-bottom: 1px solid var(--border-grey-color);
  background-color: var(--background-secondary-color);
}

.chat-users__list-item:not(:last-child) {
  border-bottom: 1px solid var(--border-grey-color);
}

.chat-users__item {
  display: flex;
  padding: 1.3333rem 1.6rem;
}

@media (max-width: 767px) {
  .chat-users__item {
    padding: 10px;
  }
}

.chat-users__item.active {
  background-color: var(--background-secondary-color);
}

.chat-users__item-avatar {
  flex-shrink: 0;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.6667rem;
  height: 2.6667rem;
  border-radius: 50%;
  margin-right: 0.8rem;
  font-size: 14px;
  line-height: 1;
  color: var(--white-color);
}

.chat-users__item-avatar .badge-signal {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 10px;
  height: 10px;
  border-width: 2px;
  z-index: inherit;
}

.chat-users__item-avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.chat-users__item-avatar-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
}

.chat-users__item-right {
  flex-grow: 1;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
}

.chat-users__add-content .chat-users__item-right {
  grid-template-columns: 1fr auto;
}

.chat-users__item-right-group {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.chat-users__item-name {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin: 0;
  font-weight: 500;
  overflow: hidden;
}

.chat-users__add-content .chat-users__item-name {
  grid-column: span 2;
}

.chat-users__item-time {
  margin-left: 0.6667rem;
  font-size: 0.8667rem;
  color: var(--text-secondary-color);
  white-space: nowrap;
}

.chat-users__item-message {
  margin-top: 6px;
  grid-column: span 2;
}

.chat-users__item-text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin: 0;
  font-size: 13px;
  line-height: 1.53846em;
  color: var(--text-secondary-color);
  overflow: hidden;
}

.chat-users__item-badge {
  float: right;
  margin-left: 8px;
  line-height: 1.33333em;
}

.chat-details {
  --chat-details-gutter-x: 3.3333rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: var(--background-secondary-color);
}

@media (max-width: 767px) {
  .chat-details {
    --chat-details-gutter-x: 1rem;
  }
}

.chat-details__backdrop {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: var(--backdrop-color);
  opacity: 0;
  pointer-events: none;
  transition: var(--t-base);
  transition-timing-function: linear;
}

@media (min-width: 1200px) {
  .chat-details__backdrop {
    display: none;
  }
}

.chat-grid--show-users .chat-details__backdrop {
  opacity: var(--backdrop-opacity);
  pointer-events: all;
}

.chat-details__top {
  position: relative;
  flex-shrink: 0;
  display: grid;
  grid-template-columns: 1fr auto;
  justify-content: space-between;
  align-items: center;
  height: var(--chat-top-height);
  padding: 0 var(--chat-details-gutter-x);
  border-bottom: 1px solid var(--border-grey-color);
}

@media (max-width: 1199px) {
  .chat-details__top {
    padding-left: 4px;
  }
}

@media (max-width: 767px) {
  .chat-details__top {
    grid-template-columns: auto auto;
  }
}

.chat-details__top-left {
  display: flex;
  align-items: center;
}

.chat-details__top-right {
  margin-left: auto;
}

.chat-details__toggle {
  align-self: center;
  width: calc(var(--chat-top-height) - 4px);
  height: calc(var(--chat-top-height) - 4px);
  margin-right: 8px;
  color: var(--text-secondary-color);
}

@media (min-width: 768px) {
  .chat-details__toggle {
    width: calc(var(--chat-top-height) - 14px);
    height: calc(var(--chat-top-height) - 14px);
  }
}

@media (min-width: 1200px) {
  .chat-details__toggle {
    display: none;
  }
}

.chat-details__toggle svg {
  transform: rotate(-90deg);
}

.chat-details__content {
  height: calc(100vh - var(--header-height) - var(--chat-top-height) - var(--chat-details-bottom-height));
  padding: 2rem var(--chat-details-gutter-x) 1.0667rem;
}

.chat-details__date {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-gap: 16px;
  align-items: center;
  margin-bottom: 2rem;
  color: var(--text-secondary-color);
}

.chat-details__date::before, .chat-details__date::after {
  flex-grow: 1;
  content: "";
  height: 0;
  border-top: 1px solid var(--border-grey-color);
}

.chat-details__bottom {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 8px 8px;
  align-items: center;
  height: var(--chat-details-bottom-height);
  padding: 0 var(--chat-details-gutter-x);
  border-top: 1px solid var(--border-grey-color);
}

@media (max-width: 575px) {
  .chat-details__bottom {
    --chat-details-gutter-x: 5px;
  }
}

.chat-details__input-group {
  align-items: center;
}

.chat-details__input-prepend {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 6px;
  z-index: 1;
  display: flex;
  align-items: center;
}

.chat-details__input-prepend .button-icon {
  width: 32px;
  height: 32px;
}

.chat-details__emoji .dropdown-emoji {
  top: auto !important;
  bottom: calc(100% + 8px);
  left: 0 !important;
  transform: translateX(-17px) !important;
}

@media (min-width: 768px) {
  .chat-details__emoji .dropdown-emoji {
    left: 50% !important;
    transform: translateX(-50%) !important;
  }
}

@media (max-width: 767px) {
  .chat-details__emoji .dropdown-emoji::before, .chat-details__emoji .dropdown-emoji::after {
    left: 0;
    transform: translateX(25px);
  }
  .chat-details__emoji .dropdown-emoji::after {
    left: 2px;
  }
}

.chat-details__input {
  padding-right: 8px;
  padding-left: 72px;
}

.chat-details__input::placeholder {
  color: var(--text-secondary-color);
}

.chat-message {
  width: max-content;
  max-width: 100%;
  margin-bottom: 2rem;
}

.chat-message:last-of-type {
  margin-bottom: 0;
}

.chat-message--answer {
  margin-left: auto;
}

.chat-message__content {
  display: flex;
}

.chat-message__icon {
  align-self: flex-end;
  flex-shrink: 0;
  position: relative;
  z-index: 0;
  min-width: 2rem;
  width: 30px;
  height: 30px;
  margin-right: 16px;
  font-weight: 500;
  font-size: 11px;
  line-height: 1;
  color: var(--white-color);
  text-transform: uppercase;
  border-radius: 50%;
}

.chat-message--answer .chat-message__icon {
  order: 1;
  margin-right: 0;
  margin-left: 16px;
}

.chat-message__icon img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.chat-message__right {
  flex-grow: 1;
}

.chat-message__icon-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
}

.chat-message__messages {
  display: grid;
  grid-gap: 4px;
}

.chat-message__message-group {
  position: relative;
}

.chat-message__tools {
  position: absolute;
  top: 0;
  right: calc(100% + 13px);
  bottom: 0;
  display: flex;
  align-items: center;
}

@media (max-width: 767px) {
  .chat-message__tools {
    position: static;
    margin-top: 4px;
  }
}

.chat-message__tools.active .items-more {
  display: none;
}

.chat-message__tools .button-icon {
  width: 32px;
  height: 32px;
  margin: 2px;
}

.chat-message__tools-item {
  display: none;
}

.chat-message__tools.active .chat-message__tools-item {
  display: block;
}

.chat-message__message-item {
  max-width: 28.1333rem;
  padding: 11px 24px 5px;
  border-radius: var(--radius-lg) var(--radius-lg) var(--radius-lg) 0;
  background-color: var(--primary-color);
  color: var(--white-color);
}

.chat-message__message-item .media-file {
  min-width: 10.5333rem;
  padding: 0 0 6px 0;
  background-color: transparent;
}

.chat-message--answer .chat-message__message-item {
  border-radius: var(--radius-lg) var(--radius-lg) 0 var(--radius-lg);
  background-color: var(--background-primary-color);
  color: inherit;
}

.chat-message__item-text {
  margin-bottom: 0;
}

.chat-message__bottom {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1rem;
  justify-content: space-between;
  margin-top: 13px;
  margin-left: 46px;
}

.chat-message--answer .chat-message__bottom {
  margin-left: 0;
  margin-right: 46px;
}

.chat-message__time {
  margin-left: auto;
  font-size: 13px;
  white-space: nowrap;
  order: 1;
  color: var(--text-secondary-color);
}

.chat-message--answer .chat-message__time {
  order: -1;
}

.chat-message__files {
  display: flex;
  grid-template-columns: 1fr auto;
  align-items: center;
  width: 100%;
  max-width: 250px;
  margin-left: auto;
}

.chat-message__files .button-add {
  margin-left: 1.0667rem;
}

.chat-message__files-items {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(40px, auto));
  grid-gap: 1.0667rem;
  width: 100%;
}

@media (max-width: 374px) {
  .chat-message__files-items {
    grid-template-columns: repeat(auto-fill, minmax(32px, auto));
    grid-gap: 8px;
  }
}

.chat-message__file {
  border-radius: var(--radius-lg);
  box-shadow: 0px 4px 4px rgba(169, 194, 209, 0.05), 0px 8px 16px rgba(169, 194, 209, 0.3);
  cursor: pointer;
  transition: var(--t-base);
}

.chat-message__file:hover {
  box-shadow: 0px 4px 4px rgba(169, 194, 209, 0), 0px 8px 16px rgba(169, 194, 209, 0);
}

.chat-message__file img {
  width: 100%;
  border-radius: var(--radius-lg);
}

/* Contacts */
.contacts-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 6.6667rem 0;
  padding: 6rem 0 1.6rem;
  margin: auto;
}

@media (min-width: 768px) {
  .contacts-grid {
    grid-gap: 6.6667rem 2rem;
    max-width: 750px;
  }
}

@media (min-width: 1200px) {
  .contacts-grid {
    max-width: 100%;
  }
}

@media (min-width: 2540px) {
  .contacts-grid {
    grid-template-columns: repeat(15, 1fr);
  }
}

.contact-card {
  grid-column: span 12;
  padding-top: 0;
}

@media (min-width: 768px) {
  .contact-card {
    grid-column: span 6;
  }
}

@media (min-width: 1200px) {
  .contact-card {
    --card-gutter-x: 2rem;
    grid-column: span 4;
  }
}

@media (min-width: 1920px) {
  .contact-card {
    grid-column: span 3;
  }
}

.contact-card .card__tools-more {
  position: absolute;
  top: 1.0667rem;
  right: 1.0667rem;
}

.contact-card .card__tools-more .dropdown-items {
  right: 0;
  transform: none;
}

.contact-card .card__body,
.contact-card .card__footer .card__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-card .card__body {
  padding-bottom: 1.6rem;
}

.contact-card__avatar {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: flex-start;
  width: 200px;
  height: 170px;
  margin-top: -4.6667rem;
  margin-bottom: 1.6rem;
}

.contact-card__avatar svg {
  width: 100%;
  height: 100%;
  min-height: 210px;
  margin-top: -4px;
  pointer-events: none;
}

.contact-card__title {
  margin-bottom: 0.33333em;
  font-weight: 500;
  font-size: 2rem;
  line-height: 1.16667em;
}

.contact-card__address {
  display: grid;
  grid-gap: 16px;
  list-style: none;
  width: 100%;
  padding: 0;
  margin: 0;
}

.contact-card__address-left {
  display: grid;
  align-items: center;
  grid-gap: 8px;
  grid-template-columns: repeat(1, 22px 1fr);
}

.contact-card__address-left svg[class*="icon"] {
  width: 22px;
  height: 22px;
  fill: var(--text-secondary-color);
}

.todo-grid {
  --todo-top-height: 4.2rem;
  position: relative;
  display: grid;
  grid-template-columns: 240px 1fr;
}

@media (max-width: 1199px) {
  .todo-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 767px) {
  .todo-grid {
    --todo-top-height: 3.2rem;
  }
}

@media (max-width: 1199px) {
  .todo-grid .sidebar-panel {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 3;
    height: auto;
    margin: 10px;
    border-right: none;
    border-radius: var(--card-border-radius);
    transform: translateX(-100%) translateX(-1.3333rem);
    opacity: 0;
    box-shadow: var(--card-shadow);
    transition: var(--t-base);
    transition-property: transform, opacity;
    transition-timing-function: linear;
  }
  .todo-grid .sidebar-panel.active {
    transform: translateX(0px);
    opacity: 1;
  }
  .todo-grid .sidebar-panel__top {
    border-radius: var(--card-border-radius) var(--card-border-radius) 0 0;
  }
}

.todo-grid .sidebar-panel__top {
  min-height: var(--todo-top-height);
}

.todo-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.todo-content__top {
  flex-shrink: 0;
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto;
  justify-content: space-between;
  align-items: center;
  height: var(--todo-top-height);
  padding: 0 var(--todo-details-gutter-x);
  border-bottom: 1px solid var(--border-grey-color);
  background-color: var(--background-secondary-color);
}

@media (max-width: 1199px) {
  .todo-content__top .container {
    padding-left: 6px;
  }
}

@media (max-width: 575px) {
  .todo-content__top {
    grid-template-columns: auto auto;
  }
}

.todo-content__top .toggle-sidebar {
  align-self: center;
  height: calc(var(--todo-top-height) - 4px);
  width: calc(var(--todo-top-height) - 4px);
  margin: 0 10px 0 3px;
}

@media (min-width: 768px) {
  .todo-content__top .toggle-sidebar {
    height: calc(var(--todo-top-height) - 14px);
    width: calc(var(--todo-top-height) - 14px);
    margin: 0 10px 0 0;
  }
}

@media (min-width: 1200px) {
  .todo-content__top .toggle-sidebar {
    display: none;
  }
}

.todo-content__top .toggle-search {
  margin-right: 1.2rem;
}

@media (min-width: 576px) {
  .todo-content__top .toggle-search {
    display: none;
  }
}

@media (min-width: 768px) {
  .todo-content__top .module-search {
    width: 290px;
  }
}

@media (max-width: 575px) {
  .todo-content__top .module-search {
    position: absolute;
    top: 0;
    right: 10px;
    left: 54px;
    z-index: 1;
    display: none;
    height: 100%;
    background-color: var(--background-secondary-color);
  }
  .todo-content__top .module-search.show {
    display: block;
  }
}

@media (max-width: 575px) {
  .todo-content__top .module-search .input-group__prepend {
    display: none;
  }
}

@media (max-width: 575px) {
  .todo-content__top .module-search .input, .todo-content__top .module-search .input-group .select2-container .select2-selection--single, .input-group .select2-container .todo-content__top .module-search .select2-selection--single, .todo-content__top .module-search .input-group--white .select2-container .select2-selection--single, .input-group--white .select2-container .todo-content__top .module-search .select2-selection--single, .todo-content__top .module-search .input-group--append-lg .select2-container .select2-selection--single, .input-group--append-lg .select2-container .todo-content__top .module-search .select2-selection--single {
    padding-left: 35px;
  }
}

.todo-content__items {
  height: calc(100vh - var(--header-height) - var(--todo-top-height));
  padding-bottom: 2rem;
}

.todo-content__items-header {
  display: grid;
  grid-template-columns: 88px 1fr;
  align-items: center;
  min-height: 3.3333rem;
  padding: 4px 2rem 4px 2.6667rem;
}

@media (max-width: 991px) {
  .todo-content__items-header {
    grid-template-columns: 82px 1fr;
    padding: 4px 1rem;
  }
}

.todo-content__items-header-column {
  display: flex;
  cursor: pointer;
  user-select: none;
  transition: var(--t-base);
  transition-property: color;
  white-space: nowrap;
}

.todo-content__list {
  list-style: none;
  display: grid;
  grid-gap: 8px;
  margin: 0;
  padding: 0;
}

.todo-panel {
  position: relative;
  display: grid;
  grid-template-columns: auto minmax(240px, 1fr) minmax(150px, auto) 110px;
  grid-gap: 1.6rem;
  min-height: 4rem;
  padding: 6px 2rem 6px 2.6667rem;
  border-radius: var(--card-border-radius);
  background-color: var(--background-secondary-color);
  box-shadow: var(--card-shadow);
}

@media (max-width: 991px) {
  .todo-panel {
    padding: 6px 1rem;
  }
}

.todo-panel::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 80%;
  height: 40px;
  margin: auto;
  box-shadow: var(--card-shadow-shape);
}

.todo-panel__button-move {
  width: 32px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 14px;
  color: var(--text-secondary-color);
}

@media (max-width: 991px) {
  .todo-panel__button-move {
    margin-right: 8px;
  }
}

.todo-panel__button-move svg {
  width: 24px;
  height: 24px;
}

.todo-panel__column {
  display: flex;
  align-items: center;
}

.todo-panel__text {
  line-height: 1.2em;
}

.todo-panel--checked .todo-panel__text > * {
  text-decoration: line-through;
}

.todo-panel__text > * {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.todo-panel__text span {
  margin-top: 2px;
}

.todo-panel__tags {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin: 0 0 -8px -8px;
}

.todo-panel__tags .tag {
  margin: 0 0 8px 8px;
}

.todo-panel__detail {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin: 0 2px;
  color: var(--text-secondary-color);
  cursor: pointer;
  user-select: none;
}

.todo-panel__detail svg {
  width: 22px;
  height: 22px;
}

.todo-panel__detail-marker {
  transition: all 120ms ease-in-out;
}

.todo-panel__detail--detail input:checked ~ .todo-panel__detail-marker {
  color: var(--red-color);
}

.todo-panel__detail--star input:checked ~ .todo-panel__detail-marker {
  color: var(--orange-color-2);
}

/* File Manager */
.file-manager {
  --files-top-height: 4.2rem;
  display: grid;
  grid-template-columns: 240px 1fr;
}

@media (max-width: 1199px) {
  .file-manager {
    grid-template-columns: 0 1fr;
    padding-top: 2rem;
  }
}

@media (max-width: 767px) {
  .file-manager {
    padding-top: 1.5rem;
  }
}

.file-manager .sidebar-panel {
  position: fixed;
  top: var(--header-height);
}

@media (max-width: 1199px) {
  .file-manager .sidebar-panel {
    position: fixed;
    top: calc(var(--header-height) + var(--subheader-height));
    bottom: 0;
    z-index: 3;
    height: auto;
    margin: 10px;
    border-right: none;
    border-radius: var(--card-border-radius);
    transform: translateX(-100%) translateX(-1.3333rem);
    opacity: 0;
    box-shadow: var(--card-shadow);
    transition: var(--t-base);
    transition-property: transform, opacity;
    transition-timing-function: linear;
  }
  .file-manager .sidebar-panel.active {
    transform: translateX(0px);
    opacity: 1;
  }
  .file-manager .sidebar-panel__top {
    border-radius: var(--card-border-radius) var(--card-border-radius) 0 0;
  }
}

.file-manager .sidebar-panel__top {
  min-height: var(--files-top-height);
}

@media (max-width: 1199px) {
  .file-manager .page-header {
    display: none;
  }
}

.file-manager__content {
  grid-column-start: 2;
  grid-column-end: 3;
}

.file-manager__section:not(:last-child) {
  margin-bottom: 5rem;
}

@media (max-width: 767px) {
  .file-manager__section:not(:last-child) {
    margin-bottom: 2rem;
  }
}

.file-manager__section-title {
  margin-bottom: 1.3333rem;
}

.file-manager__section-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(190px, auto));
  grid-gap: 1.6rem;
}

.file-manager__table .colgroup-1 {
  width: 70px;
}

.file-manager__table .colgroup-2 {
  width: 400px;
}

.files-card {
  --gutter: 16px;
  position: relative;
  display: flex;
  padding: 17px var(--gutter) 12px;
  border-radius: var(--card-border-radius);
  box-shadow: var(--card-shadow);
}

.files-card::before {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  background-color: var(--background-secondary-color);
  border-radius: var(--card-border-radius);
}

.files-card::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 76%;
  height: 50px;
  margin: auto;
  box-shadow: var(--dropdown-shadow-shape);
}

.files-card__content {
  position: relative;
  z-index: 2;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.files-card__checkbox {
  position: absolute;
  top: 0;
  left: 0;
}

.files-card__more {
  position: absolute;
  top: 0;
  right: 0;
}

.files-card__icon {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 90px;
  margin: auto;
  color: var(--orange-color-2);
}

.files-card__icon--file svg {
  width: 3.6667rem;
  height: 4.6667rem;
}

.files-card__title {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-top: 7px;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  overflow: hidden;
}

.files-card__bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 1.6rem;
  font-size: 13px;
  line-height: 1.15385em;
  color: var(--text-secondary-color);
}

/* Timeline */
.page-timeline {
  --timeline-items-spacer-x: 37px;
  --timeline-item-gutter-bottom: 2.4rem;
  position: relative;
  width: 100%;
  max-width: 750px;
  margin: auto;
}

.page-timeline::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  content: "";
  border-right: 1px solid var(--border-grey-color);
  pointer-events: none;
}

@media (min-width: 1024px) {
  .page-timeline {
    max-width: 100%;
  }
}

.page-timeline__section {
  position: relative;
  z-index: 1;
  margin-bottom: calc(var(--timeline-item-gutter-bottom) * -1);
}

.page-timeline__date {
  text-align: center;
}

.page-timeline__items {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 2.4rem 0;
}

.page-timeline__section:last-child .page-timeline__items {
  margin-bottom: 0;
}

.page-timeline__items-size {
  width: 100%;
}

@media (min-width: 1024px) {
  .page-timeline__items-size {
    width: calc(50% - var(--timeline-items-spacer-x));
  }
}

.page-timeline__items-spacer {
  width: calc(var(--timeline-items-spacer-x) * 2);
}

.timeline-item {
  --timeline-item-gutter-x: 15px;
  position: relative;
  display: flex;
  margin-top: 4rem;
  margin-bottom: var(--timeline-item-gutter-bottom);
  border: 1px solid var(--border-grey-color);
  border-radius: var(--radius-base);
  box-shadow: var(--control-shadow);
}

@media (min-width: 576px) {
  .timeline-item {
    --timeline-item-gutter-x: 1.6rem;
  }
}

@media (min-width: 1024px) {
  .timeline-item {
    margin-top: 0;
    width: calc(50% - var(--timeline-items-spacer-x));
  }
}

@media (min-width: 1024px) {
  .timeline-item:nth-of-type(2) {
    min-height: 46.6667rem;
    margin-top: 5rem;
  }
}

.timeline-item::before {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  background-color: var(--background-secondary-color);
  border-radius: var(--radius-base);
}

.timeline-item__arrow {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: calc(var(--tooltip-arrow-size));
  height: calc(var(--tooltip-arrow-size));
  z-index: 1;
}

@media (min-width: 1024px) {
  .timeline-item__arrow {
    left: auto;
    right: 0;
    transform: translateX(50%);
    margin-top: 15px;
  }
}

@media (min-width: 1024px) {
  .timeline-item:nth-of-type(2n) .timeline-item__arrow {
    right: auto;
    left: 0;
    transform: translateX(-50%);
  }
}

.timeline-item__arrow::before, .timeline-item__arrow::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  transform-origin: center center !important;
  transform: rotate(-45deg);
  border: none;
  border-radius: 2px;
  background-color: var(--background-secondary-color);
}

.timeline-item__arrow::before {
  z-index: 1;
  margin-top: 2px;
}

@media (min-width: 1024px) {
  .timeline-item__arrow::before {
    right: 2px;
    margin-top: 0;
  }
}

@media (min-width: 1024px) {
  .timeline-item:nth-of-type(2n) .timeline-item__arrow::before {
    right: auto;
    left: 2px;
  }
}

.timeline-item__arrow::after {
  background-color: var(--border-grey-color);
}

.timeline-item__wrapper {
  position: relative;
  z-index: 2;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.timeline-item__container {
  padding: 0 var(--timeline-item-gutter-x);
}

.timeline-item__avatar {
  position: absolute;
  bottom: calc(100% + 20px);
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 11px;
  color: var(--white-color);
}

@media (min-width: 1024px) {
  .timeline-item__avatar {
    top: 6px;
    bottom: auto;
    left: calc(100% + 23px);
    transform: none;
    width: 30px;
    height: 30px;
  }
}

@media (min-width: 1024px) {
  .timeline-item:nth-of-type(2n) .timeline-item__avatar {
    left: auto;
    right: calc(100% + 23px);
  }
}

.timeline-item__avatar-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
}

.timeline-item__avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.timeline-item__top {
  display: flex;
  min-height: 4.2667rem;
  padding: 8px 0;
}

.timeline-item__top .timeline-item__container {
  flex-grow: 1;
  display: grid;
  align-content: center;
  align-items: center;
  grid-gap: 0 8px;
}

@media (min-width: 576px) {
  .timeline-item__top .timeline-item__container {
    grid-template-columns: 1fr auto;
  }
}

.timeline-item__title {
  margin: 0;
  font-weight: 400;
}

@media (max-width: 575px) {
  .timeline-item__title {
    font-size: 15px;
  }
}

.timeline-item__date {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: var(--text-secondary-color);
  white-space: nowrap;
}

@media (min-width: 576px) {
  .timeline-item__date {
    font-size: 1rem;
  }
}

.timeline-item__date svg[class*="icon"] {
  width: 22px;
  height: 22px;
  margin: 0 2px 0 -3px;
}

@media (min-width: 576px) {
  .timeline-item__date svg[class*="icon"] {
    display: block;
    margin-right: 8px;
  }
}

.timeline-item__thumbs {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 1.6rem;
  margin-bottom: 20px;
}

@media (max-width: 575px) {
  .timeline-item__thumbs {
    grid-gap: 1rem;
    margin-bottom: 1rem;
  }
}

.timeline-item__thumbs-item, .timeline-item__image {
  position: relative;
  overflow: hidden;
}

.timeline-item__thumbs-item::before, .timeline-item__image::before {
  display: block;
  content: '';
}

.timeline-item__thumbs-item img, .timeline-item__image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s linear;
}

.timeline-item__thumbs-item img:hover, .timeline-item__image img:hover {
  transform: scale(1.2);
}

.timeline-item__thumbs-item::before {
  padding-top: 69%;
}

.timeline-item__image {
  max-height: 300px;
  margin: 0 calc(var(--timeline-item-gutter-x) * -1) 12px;
}

.timeline-item__image::before {
  padding-top: 56%;
}

@media (min-width: 576px) {
  .timeline-item__image {
    margin-bottom: 1.8rem;
  }
}

@media (max-width: 575px) {
  .timeline-item__top-description, .timeline-item__description {
    font-size: 13px;
  }
}

.timeline-item__top-description p:last-of-type, .timeline-item__description p:last-of-type {
  margin-bottom: 0;
}

.timeline-item__top-description {
  margin: -8px 0 1.2rem;
}

.timeline-item__description {
  margin-bottom: 0.8667rem;
}

.timeline-item__stat {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  font-size: 12px;
}

.timeline-item__stat-item {
  margin-right: 1.4rem;
  white-space: nowrap;
}

.timeline-item__stat-item svg[class*="icon"] {
  width: 22px;
  height: 22px;
  margin-right: 5px;
  color: var(--text-secondary-color);
}

.timeline-item__stat-like:not(.empty) svg[class*="icon"] {
  color: var(--red-color);
}

.timeline-item__bottom {
  flex-grow: 1;
  display: flex;
  padding: 15px 0;
  margin-top: 1.4rem;
  border-top: 1px solid var(--border-grey-color);
  border-radius: 0 0 var(--radius-base) var(--radius-base);
  background-color: var(--background-primary-color);
}

@media (min-width: 576px) {
  .timeline-item__bottom {
    padding: 1.6rem 0;
  }
}

.timeline-item__bottom .timeline-item__container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.timeline-item__comments {
  flex-grow: 1;
  margin-bottom: 1.7333rem;
}

.timeline-item__comments .review-list__item:last-child {
  margin-bottom: 0;
}

.timeline-item__comments .review-list__avatar {
  width: 30px;
  height: 30px;
  margin-right: 12px;
  font-size: 11px;
}

.timeline-item__comments .review-list__message {
  margin-top: 6px;
}

.timeline-item__comments .review-list__text {
  line-height: 1.84615em;
}

.timeline-item__input-group .input-group__prepend {
  left: 6px;
  pointer-events: all;
}

.timeline-item__input-group .dropdown-emoji {
  top: auto !important;
  bottom: calc(100% + 8px);
  left: 76px !important;
  transform: translateX(-50%) !important;
}

.timeline-item__input-group .dropdown-emoji::before, .timeline-item__input-group .dropdown-emoji::after {
  left: 42px;
}

.timeline-item__input-group .input, .timeline-item__input-group .input-group .select2-container .select2-selection--single, .input-group .select2-container .timeline-item__input-group .select2-selection--single, .timeline-item__input-group .input-group--white .select2-container .select2-selection--single, .input-group--white .select2-container .timeline-item__input-group .select2-selection--single, .timeline-item__input-group .input-group--append-lg .select2-container .select2-selection--single, .input-group--append-lg .select2-container .timeline-item__input-group .select2-selection--single {
  height: 4rem;
  padding: 1.4rem 1rem 1.4rem 3.2rem;
}

/* Authentication */
.page-auth {
  flex-grow: 1;
  display: flex;
  width: 100%;
  overflow: hidden;
}

.page-auth__center {
  position: relative;
  z-index: 1;
  flex-grow: 1;
  display: flex;
  min-height: 100vh;
  padding: 80px 15px 30px;
}

@media (min-width: 768px) {
  .page-auth__center {
    padding: 15px;
  }
}

.page-auth__bg, .page-auth__screen, .page-auth__gradient {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.page-auth__bg .auth-logo, .page-auth__screen .auth-logo, .page-auth__gradient .auth-logo {
  position: absolute;
  top: 1rem;
  left: 10px;
  z-index: 1;
  display: none;
}

@media (max-width: 767px) {
  .page-auth__bg .auth-logo, .page-auth__screen .auth-logo, .page-auth__gradient .auth-logo {
    display: flex;
  }
}

.page-auth__bg::before, .page-auth__screen::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  background-color: var(--backdrop-color);
  opacity: var(--backdrop-opacity);
}

.page-auth__screen {
  display: none;
}

@media (max-width: 767px) {
  .page-auth__screen {
    display: block;
  }
}

.page-auth__screen-bg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.page-auth__gradient {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

@media (max-width: 767px) {
  .page-auth__gradient {
    grid-template-columns: 1fr;
  }
}

.page-auth__gradient-shape {
  position: absolute;
  width: 50%;
  max-width: 800px;
  border-radius: 50%;
  z-index: 1;
}

@media (max-width: 767px) {
  .page-auth__gradient-shape {
    max-width: 50vh;
  }
}

.page-auth__gradient-shape::before {
  display: block;
  content: "";
  padding-top: 100%;
}

.page-auth__gradient-shape:nth-of-type(1) {
  top: 0;
  left: 0;
  transform: translate(-15%, -15%);
  background: linear-gradient(144.83deg, rgba(255, 255, 255, 0.5) -18.71%, #22CCE2 76.92%);
}

.page-auth__gradient-shape:nth-of-type(2) {
  right: 0;
  bottom: 0;
  transform: translate(15%, 15%);
  background: linear-gradient(143.42deg, rgba(255, 255, 255, 0) -12.92%, #EAEDF0 77.04%);
}

@media (max-width: 767px) {
  .page-auth__gradient-shape:nth-of-type(2) {
    display: none;
  }
}

.page-auth__gradient-column {
  flex-grow: 1;
  background-color: var(--teal-color);
}

.page-auth__bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.page-auth__content {
  position: relative;
  flex-grow: 1;
  display: grid;
  grid-template-columns: 1fr 33.3333rem;
}

@media (max-width: 767px) {
  .page-auth__content {
    grid-template-columns: 1fr;
  }
}

.page-auth__left, .page-auth__right {
  display: flex;
  flex-direction: column;
}

.page-auth__left {
  position: relative;
  justify-content: flex-end;
  padding: 0 40px 6rem;
  color: var(--white-color);
}

@media (max-width: 1023px) {
  .page-auth__left {
    padding: 20px 15px;
  }
}

@media (max-width: 767px) {
  .page-auth__left {
    display: none;
  }
}

.page-auth__title {
  margin-bottom: 2rem;
  font-size: 3.3333rem;
  line-height: 1.2;
  letter-spacing: 0.1em;
}

@media (max-width: 1199px) {
  .page-auth__title {
    font-size: 36px;
  }
}

.page-auth__text {
  margin-bottom: 0;
  line-height: 2.13333em;
}

.auth-card .input::placeholder, .auth-card .input-group .select2-container .select2-selection--single::placeholder, .input-group .select2-container .auth-card .select2-selection--single::placeholder, .auth-card .input-group--white .select2-container .select2-selection--single::placeholder, .input-group--white .select2-container .auth-card .select2-selection--single::placeholder, .auth-card .input-group--append-lg .select2-container .select2-selection--single::placeholder, .input-group--append-lg .select2-container .auth-card .select2-selection--single::placeholder,
.auth-md-card .input::placeholder,
.auth-md-card .input-group .select2-container .select2-selection--single::placeholder,
.input-group .select2-container .auth-md-card .select2-selection--single::placeholder,
.auth-md-card .input-group--white .select2-container .select2-selection--single::placeholder,
.input-group--white .select2-container .auth-md-card .select2-selection--single::placeholder,
.auth-md-card .input-group--append-lg .select2-container .select2-selection--single::placeholder,
.input-group--append-lg .select2-container .auth-md-card .select2-selection--single::placeholder,
.auth-panel .input::placeholder,
.auth-panel .input-group .select2-container .select2-selection--single::placeholder,
.input-group .select2-container .auth-panel .select2-selection--single::placeholder,
.auth-panel .input-group--white .select2-container .select2-selection--single::placeholder,
.input-group--white .select2-container .auth-panel .select2-selection--single::placeholder,
.auth-panel .input-group--append-lg .select2-container .select2-selection--single::placeholder,
.input-group--append-lg .select2-container .auth-panel .select2-selection--single::placeholder {
  color: var(--text-primary-color);
}

.auth-card,
.auth-md-card {
  --auth-card-shape-offset: -37px;
}

.auth-card__shape,
.auth-md-card__shape {
  position: absolute;
  content: "";
  width: 230px;
  height: 150px;
  background-image: url(__static__content/auth-dots.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

@media (max-width: 767px) {
  .auth-card__shape,
  .auth-md-card__shape {
    width: 150px;
  }
}

.auth-card__shape:nth-of-type(1),
.auth-md-card__shape:nth-of-type(1) {
  left: var(--auth-card-shape-offset);
  bottom: var(--auth-card-shape-offset);
  background-position: bottom left;
}

.auth-card__shape:nth-of-type(2),
.auth-md-card__shape:nth-of-type(2) {
  top: var(--auth-card-shape-offset);
  right: var(--auth-card-shape-offset);
  background-position: top right;
}

@media (max-width: 767px) {
  .auth-card__shape:nth-of-type(2),
  .auth-md-card__shape:nth-of-type(2) {
    opacity: 0.3;
  }
}

.auth-card {
  padding: 0;
  max-width: 430px;
  margin: auto;
}

[data-theme="light"] .auth-card .input-group__prepend {
  color: var(--text-primary-color);
}

@media (min-width: 768px) {
  .auth-card {
    max-width: 860px;
  }
}

.auth-card .card__wrapper {
  display: grid;
  grid-template-columns: 0 1fr;
}

@media (min-width: 768px) {
  .auth-card .card__wrapper {
    grid-template-columns: 1fr 430px;
  }
}

.auth-card__left {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.auth-card__logo {
  position: absolute;
  top: 27px;
  left: 34px;
  z-index: 1;
}

.auth-card__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--card-border-radius) 0 0 var(--card-border-radius);
  object-fit: cover;
}

.auth-card__right {
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
}

@media (min-width: 576px) {
  .auth-card__right {
    padding: 24px 40px 30px;
  }
}

@media (min-width: 768px) {
  .auth-card__right {
    min-height: 37.3333rem;
  }
}

.auth-card__top {
  margin-bottom: 2rem;
}

.auth-card__title {
  margin-bottom: 2rem;
  font-size: 2rem;
}

@media (max-width: 575px) {
  .auth-card__title {
    margin-bottom: 1rem;
    font-size: 1.6rem;
  }
}

.auth-card__text {
  margin-bottom: 0;
  color: var(--text-secondary-color);
}

.auth-card__body {
  margin: auto 0;
}

.auth-card__bottom {
  margin-top: 2rem;
}

.auth-card__buttons {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: var(--container-gutter-sm);
  justify-content: flex-end;
}

@media (max-width: 575px) {
  .auth-card__buttons {
    grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
  }
}

.auth-card__buttons .button {
  min-width: 110px;
}

.auth-card__submit {
  margin-top: 2rem;
}

.auth-card__sign {
  text-align: center;
}

.auth-md-card {
  --container-gutter-x: 40px;
  width: 100%;
  max-width: 430px;
  padding: 2rem 0 1.7333rem;
  margin: auto;
  text-align: center;
}

@media (max-width: 575px) {
  .auth-md-card {
    --container-gutter-x: 1.6rem;
  }
}

[data-theme="light"] .auth-md-card .input-group__prepend {
  color: var(--text-primary-color);
}

.auth-md-card__avatar {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: flex-start;
  width: 200px;
  height: 170px;
  margin: -6.6667rem auto 1.6rem;
}

.auth-md-card__avatar svg {
  width: 100%;
  height: 100%;
  min-height: 210px;
  margin-top: -4px;
  pointer-events: none;
}

.auth-md-card__icon {
  margin-bottom: 1.0667rem;
}

.auth-md-card__icon--lock svg {
  stroke: var(--text-primary-color);
}

.auth-md-card__container {
  padding: 0 var(--container-gutter-x);
}

.auth-md-card__title {
  margin-bottom: 0.33333em;
  font-weight: 500;
}

.auth-md-card__line {
  margin: 16px calc(var(--container-gutter-x) * -1) 32px;
  border-top: 1px solid var(--border-grey-color);
}

.auth-md-card__submit {
  margin: 1.6rem 0 3.0667rem;
}

.auth-md-card__link {
  margin-bottom: 0;
  font-size: 13px;
  line-height: 1.15385em;
}

.auth-panel {
  position: relative;
  display: flex;
  flex-grow: 1;
  padding-bottom: 1.6rem;
  filter: drop-shadow(-4px 0px 8px rgba(68, 86, 108, 0.05)) drop-shadow(-16px 0px 32px rgba(68, 86, 108, 0.15));
}

.auth-panel::before {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  background-color: var(--background-secondary-color);
}

.auth-panel__wrapper {
  position: relative;
  z-index: 2;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.auth-panel__container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0 40px;
}

@media (max-width: 767px) {
  .auth-panel__container {
    padding: 0 15px;
  }
}

.auth-panel__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 420px;
  padding: 40px 0;
  margin: auto;
}

.auth-panel__logotype {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.auth-panel__logotype .auth-logo__text {
  color: var(--text-primary-color);
}

.auth-panel__text {
  margin: 40px 0;
}

@media (max-width: 767px) {
  .auth-panel__text {
    margin-bottom: 20px;
  }
}

.auth-panel__title {
  margin-bottom: 2.4rem;
  text-align: center;
}

.auth-panel__submit {
  margin-top: 1.6rem;
}

.auth-panel__divider {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-gap: 11px;
  align-items: center;
  margin: 40px 0;
  color: var(--border-grey-color);
  text-transform: uppercase;
}

@media (max-width: 767px) {
  .auth-panel__divider {
    margin: 18px 0;
  }
}

.auth-panel__divider::after, .auth-panel__divider::before {
  content: "";
  border-top: 1px solid var(--border-grey-color);
}

.auth-panel__social {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(40px, auto));
  grid-gap: 2rem;
}

@media (max-width: 767px) {
  .auth-panel__social {
    grid-template-columns: repeat(auto-fit, minmax(30px, auto));
    grid-gap: 1rem;
  }
}

.auth-panel__social-link img {
  transition: var(--t-base);
}

@media (max-width: 767px) {
  .auth-panel__social-link img {
    max-width: 30px;
  }
}

.auth-panel__social-link:hover img {
  transform: translateY(-4px);
}

@media (max-width: 767px) {
  .auth-panel__sign {
    text-align: center;
  }
}

[data-theme="light"] .auth-panel .input-group__prepend {
  color: var(--text-primary-color);
}

[data-theme="light"] .auth-panel__sign {
  color: var(--text-secondary-color);
}

.auth-logo {
  display: flex;
  align-items: center;
  font-family: var(--font-family-secondary);
  font-weight: 700;
  font-size: 1.875rem;
  line-height: 1;
  letter-spacing: 0.1em;
  white-space: nowrap;
}

.auth-logo__icon {
  flex-shrink: 0;
  min-width: 43px;
  width: 43px;
  margin-right: 5px;
}

.auth-logo__text {
  color: var(--white-color);
}

.auth-bg-image-light,
.auth-bg-image-dark {
  display: none;
}

[data-theme='light'] .auth-bg-image-light {
  display: block;
}

[data-theme='dark'] .auth-bg-image-dark {
  display: block;
}

/**
* Colors theme { selector: html[data-theme="light"], html[data-theme="dark"] }
*/
html[data-theme='dark'] .dropdown-menu__item:not(.dropdown-menu__top):not(.dropdown-menu__footer).active, html[data-theme='dark'] .select2-container .select2-results__option:not(.dropdown-menu__top):not(.dropdown-menu__footer).active, .select2-container html[data-theme='dark'] .select2-results__option:not(.dropdown-menu__top):not(.dropdown-menu__footer).active, html[data-theme='dark'] .dropdown-menu__item:not(.dropdown-menu__top):not(.dropdown-menu__footer):hover, html[data-theme='dark'] .select2-container .select2-results__option:not(.dropdown-menu__top):not(.dropdown-menu__footer):hover, .select2-container html[data-theme='dark'] .select2-results__option:not(.dropdown-menu__top):not(.dropdown-menu__footer):hover {
  background-color: var(--background-primary-color);
}

html[data-theme='dark'] .dropdown-menu__item:not(.dropdown-menu__top):not(.dropdown-menu__footer):active, html[data-theme='dark'] .select2-container .select2-results__option:not(.dropdown-menu__top):not(.dropdown-menu__footer):active, .select2-container html[data-theme='dark'] .select2-results__option:not(.dropdown-menu__top):not(.dropdown-menu__footer):active {
  background-color: var(--control-background-active);
}

html[data-theme='dark'] .dropdown-items__link:hover {
  background-color: var(--background-primary-color);
}

html[data-theme='dark'] .dropdown-items__link:active {
  background-color: var(--control-background-active);
}

html[data-theme='dark'] .profile-dropdown__icon {
  color: var(--text-secondary-color);
}

html[data-theme='dark'] .profile-dropdown__item:hover .profile-dropdown__icon, html[data-theme='dark'] .profile-dropdown__item.active .profile-dropdown__icon {
  color: var(--text-primary-color);
}

@media (max-width: 767px) {
  html[data-theme='dark'] .header__search {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15), 0px 8px 16px rgba(0, 0, 0, 0.3);
  }
}

html[data-theme='dark'] .chart-tooltip-custom {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 8px 16px rgba(0, 0, 0, 0.2);
}

html[data-theme='dark'] .tippy-content {
  color: var(--white-color);
  background-color: var(--background-secondary-color);
  border: 1px solid var(--border-grey-color);
}

html[data-theme='dark'] .tippy-arrow::before {
  background-color: var(--background-secondary-color);
}

html[data-theme='dark'] .tippy-arrow::after {
  background: repeating-linear-gradient(-45deg, transparent 0%, transparent 50%, var(--border-grey-color) 50%, var(--border-grey-color) 100%);
}

html[data-theme='dark'] .button--primary:hover {
  border-color: var(--blue-color-control-2);
  background-color: var(--blue-color-control-2);
}

html[data-theme='dark'] .button--primary:active {
  border-color: var(--primary-color);
  background-color: var(--primary-color);
}

html[data-theme='dark'] .button--secondary {
  border-color: var(--border-grey-color);
  background-color: var(--black-color-2);
  color: var(--white-color);
}

html[data-theme='dark'] .button--secondary:hover {
  border-color: var(--border-grey-color);
  background-color: var(--control-background-hover);
}

html[data-theme='dark'] .button--secondary:active {
  border-color: var(--border-grey-color);
  background-color: var(--control-background-active);
}

html[data-theme='dark'] .switcher-button__float {
  box-shadow: 0px 8px 16px rgba(0, 55, 250, 0.3);
}

html[data-theme='dark'] .switcher-button__item:not(:last-of-type)::after {
  border-color: var(--black-color-4);
}

html[data-theme='dark'] .button-icon {
  background-color: var(--black-color-2);
}

html[data-theme='dark'] .button-icon--grey {
  color: var(--text-primary-color);
  background-color: transparent;
}

html[data-theme='dark'] .button-icon--grey:hover {
  color: inherit;
}

html[data-theme='dark'] .button-icon--transparent {
  color: var(--text-secondary-color);
  background-color: transparent;
}

html[data-theme='dark'] .button-icon:hover {
  color: var(--text-primary-color);
  background-color: var(--control-background-hover);
}

html[data-theme='dark'] .button-icon:active {
  color: var(--text-primary-color);
  background-color: var(--control-background-active);
}

html[data-theme='dark'] .button-icon.active {
  color: var(--white-color);
  background-color: var(--primary-color);
}

html[data-theme='dark'] .button-add--blue {
  box-shadow: 0px 8px 16px rgba(0, 55, 250, 0.3);
}

html[data-theme='dark'] .button-add--blue:active {
  background-color: var(--blue-color-control-2);
  box-shadow: 0px 8px 16px rgba(0, 55, 250, 0);
}

html[data-theme='dark'] .widget .badge,
html[data-theme='dark'] .card-widget .badge {
  color: var(--black-color-2);
}

html[data-theme='dark'] .widget__chart-caption {
  color: var(--text-secondary-color);
}

html[data-theme='dark'] .progressbar__items {
  background-color: var(--black-color-3);
}

html[data-theme='dark'] .sidebar__link-signal::before {
  background-color: var(--background-primary-color);
  border-color: var(--text-secondary-color);
}

html[data-theme='dark'] .sidebar__link.active .sidebar__link-signal::before {
  background-color: var(--text-primary-color);
  border-color: var(--text-primary-color);
}

html[data-theme='dark'] .sidebar-panel__nav-title, html[data-theme='dark'] .sidebar-panel__section-title {
  color: var(--text-primary-color);
}

html[data-theme='dark'] .input-group--white .input, .input-group .select2-container html[data-theme='dark'] .input-group--white .select2-selection--single, html[data-theme='dark'] .input-group--white .select2-container .select2-selection--single, .input-group--append-lg .select2-container html[data-theme='dark'] .input-group--white .select2-selection--single {
  filter: none;
}

html[data-theme='dark'] .select2-container .select2-results__option[aria-selected="true"], html[data-theme='dark'] .select2-container .select2-results__option--highlighted[aria-selected] {
  color: var(--text-primary-color);
  background-color: var(--background-primary-color);
}

html[data-theme='dark'] .select2-container .select2-selection--multiple .select2-selection__choice {
  background-color: var(--black-color-4);
}

html[data-theme='dark'] .tag--default {
  background-color: var(--black-color-4);
}

html[data-theme='dark'] .input-editor .ql-snow.ql-toolbar {
  color: var(--text-primary-color);
}

html[data-theme='dark'] .image-upload__drop-text {
  color: var(--grey-color-6);
}

html[data-theme='dark'] .image-upload__progress::before {
  background-image: url(__static__content/upload-progress-dark.svg);
}

html[data-theme='dark'] .rating .jq-star-svg [class*="svg-empty"] {
  fill: var(--grey-color-6);
}

html[data-theme='dark'] .checkbox__marker,
html[data-theme='dark'] .radio__marker {
  border-color: var(--black-color-4);
  background-color: var(--black-color);
}

html[data-theme='dark'] .checkbox--circle .checkbox__marker {
  border-color: transparent;
  background-color: transparent;
}

html[data-theme='dark'] .checkbox--circle .checkbox__marker::before {
  background-image: url("__static__/content/checkbox-dark.svg");
}

html[data-theme='dark'] .checkbox--circle .checkbox__marker-icon {
  background-image: url("__static__content/checkbox-dark-checked.svg");
}

html[data-theme='dark'] .checkbox-toggle:not(.is-active) {
  background-color: var(--black-color-4);
}

html[data-theme='dark'] .table-wrapper__content.table-collapse {
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.05)) drop-shadow(0px 12px 16px rgba(0, 0, 0, 0.2));
}

html[data-theme='dark'] .table-wrapper__content.table-collapse::before {
  box-shadow: 0px 32px 32px rgba(0, 0, 0, 0.05), 0px 8px 16px rgba(0, 0, 0, 0.05);
}

html[data-theme='dark'] .pagination {
  filter: none;
}

html[data-theme='dark'] .pagination__arrow {
  border-color: var(--black-color);
}

html[data-theme='dark'] .pagination__arrow:hover {
  color: var(--text-primary-color);
}

html[data-theme='dark'] .pagination__arrow:active {
  background-color: var(--blue-color-control-2);
  border-color: var(--blue-color-control-2);
}

html[data-theme='dark'] .pagination__item.active .pagination__link {
  filter: drop-shadow(0px 8px 16px rgba(0, 55, 250, 0.3));
}

html[data-theme='dark'] .pagination__arrow, html[data-theme='dark'] .pagination__item--dots, html[data-theme='dark'] .pagination__link {
  color: var(--text-secondary-color);
}

html[data-theme='dark'] .modal__content {
  box-shadow: -4px 0px 8px rgba(0, 0, 0, 0.1), -16px 0px 32px rgba(0, 0, 0, 0.15);
}

html[data-theme='dark'] .modal-compact .modal__content {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1), 0px 16px 32px rgba(0, 0, 0, 0.25);
}

html[data-theme='dark'] .card-order .card__date {
  color: var(--text-primary-color);
}

html[data-theme='dark'] .card-order__customer-list svg[class*="icon"] {
  fill: var(--text-secondary-color);
}

html[data-theme='dark'] .card-order__status-panel .form-label {
  color: var(--text-primary-color);
}

html[data-theme='dark'] .card-order__list {
  color: var(--text-secondary-color);
}

html[data-theme='dark'] .order-invoice__address {
  color: var(--text-primary-color);
}

html[data-theme='dark'] .order-invoice__logo {
  background-image: url("__static__content/logo-dark.svg");
}

html[data-theme='dark'] .order-status__form .form-label {
  color: var(--text-primary-color);
}

html[data-theme='dark'] .order-timeline__date, html[data-theme='dark'] .order-timeline__group-left {
  color: var(--text-secondary-color);
}

html[data-theme='dark'] .order-note__button-push:hover {
  color: #fff;
}

html[data-theme='dark'] .order-note__description p {
  color: var(--text-primary-color);
}

html[data-theme='dark'] .order-messages__input::placeholder {
  color: var(--white-color);
}

html[data-theme='dark'] .order-messages__input-button {
  color: var(--white-color);
}

html[data-theme='dark'] .order-messages__user-address {
  color: var(--white-color);
}

html[data-theme='dark'] .calendar__top-week-day, html[data-theme='dark'] .calendar__top-week-hours, html[data-theme='dark'] .calendar__timeline-time {
  color: var(--text-primary-color);
}

html[data-theme='dark'] .calendar__day--prev, html[data-theme='dark'] .calendar__day--next {
  background: repeating-linear-gradient(45deg, transparent 0px, transparent 15px, var(--black-color-4) 15px, var(--black-color-4) 17px);
}

html[data-theme='dark'] .c-event__tools .button-icon {
  background-color: transparent;
}

html[data-theme='dark'] .c-event-drop {
  border-color: var(--grey-color-6);
  background: rgba(51, 62, 76, 0.5);
}

html[data-theme='dark'] .dropdown-c-event__date {
  color: var(--black-color);
  box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.1);
}

html[data-theme='dark'] .dropdown-c-event__month {
  color: var(--white-color);
  background-color: var(--primary-color);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 8px 16px rgba(0, 0, 0, 0.2);
}

html[data-theme='dark'] .dropdown-c-event__time {
  color: var(--text-primary-color);
}

html[data-theme='dark'] .dropdown-c-event__footer {
  background-color: var(--background-secondary-color);
}

html[data-theme='dark'] .media-file__append .button-icon {
  color: var(--text-secondary-color);
}

html[data-theme='dark'] .media-file__append .button-icon:hover {
  color: inherit;
}

html[data-theme='dark'] .media-file__upload-dismiss {
  background-color: var(--grey-color-6);
}

html[data-theme='dark'] .inbox-mails__search .input-group__prepend,
html[data-theme='dark'] .inbox-mails__item-attachment,
html[data-theme='dark'] .inbox-mails__item-time,
html[data-theme='dark'] .inbox-details__bottom .button-icon,
html[data-theme='dark'] .inbox-add .modal__footer .button-icon {
  color: var(--text-primary-color);
}

html[data-theme='dark'] .chat-users__item-time,
html[data-theme='dark'] .chat-details__date,
html[data-theme='dark'] .chat-details__input-group .button-icon {
  color: var(--text-primary-color);
}

html[data-theme='dark'] .chat-users .badge-signal--green,
html[data-theme='dark'] .chat-details .badge-signal--green {
  border-color: var(--white-color);
}

html[data-theme='dark'] .chat-message__file {
  box-shadow: none;
}

html[data-theme='dark'] .todo-panel__detail {
  color: var(--black-color-4);
}

html[data-theme='dark'] .todo-panel__detail:hover {
  color: var(--text-secondary-color);
}

html[data-theme='dark'] .timeline-item {
  box-shadow: var(--card-shadow);
}

html[data-theme='dark'] .timeline-item__bottom {
  background-color: var(--background-secondary-color);
}

html[data-theme='dark'] .timeline-item__comments .review-list__text {
  color: var(--text-primary-color);
}

html[data-theme='dark'] .timeline-item__input-group .input, html[data-theme='dark'] .timeline-item__input-group .input-group .select2-container .select2-selection--single, .input-group .select2-container html[data-theme='dark'] .timeline-item__input-group .select2-selection--single, html[data-theme='dark'] .timeline-item__input-group .input-group--white .select2-container .select2-selection--single, .input-group--white .select2-container html[data-theme='dark'] .timeline-item__input-group .select2-selection--single, html[data-theme='dark'] .timeline-item__input-group .input-group--append-lg .select2-container .select2-selection--single, .input-group--append-lg .select2-container html[data-theme='dark'] .timeline-item__input-group .select2-selection--single {
  background-color: var(--background-primary-color);
}

html[data-theme='dark'] .page-auth__gradient-shape:nth-of-type(1) {
  background: linear-gradient(144.83deg, rgba(255, 255, 255, 0.4) -18.71%, rgba(0, 55, 250, 0.5) 76.92%);
}

html[data-theme='dark'] .page-auth__gradient-shape:nth-of-type(2) {
  background: linear-gradient(143.42deg, rgba(255, 255, 255, 0.02) 16.06%, rgba(21, 35, 46, 0.1) 77.04%);
}

html[data-theme='dark'] .page-auth__gradient-column {
  background-color: var(--primary-color);
}

html[data-theme='dark'] .auth-card__shape:nth-of-type(2),
html[data-theme='dark'] .auth-md-card__shape:nth-of-type(2) {
  opacity: 0.1;
}

html[data-theme='dark'] .auth-panel,
html[data-theme='dark'] .auth-md-card--blur {
  filter: drop-shadow(-4px 0px 8px rgba(0, 0, 0, 0.15)) drop-shadow(-16px 0px 32px rgba(0, 0, 0, 0.25));
  backdrop-filter: blur(24px);
}

@media (max-width: 767px) {
  html[data-theme='dark'] .auth-panel,
  html[data-theme='dark'] .auth-md-card--blur {
    backdrop-filter: blur(12px);
  }
}

html[data-theme='dark'] .auth-panel::before,
html[data-theme='dark'] .auth-md-card--blur::before {
  background-color: rgba(0, 0, 0, 0.5);
}

@media (max-width: 767px) {
  html[data-theme='dark'] .auth-panel::before,
  html[data-theme='dark'] .auth-md-card--blur::before {
    background-color: rgba(0, 0, 0, 0.25);
  }
}

html[data-theme='dark'] .auth-panel .input, html[data-theme='dark'] .auth-panel .input-group .select2-container .select2-selection--single, .input-group .select2-container html[data-theme='dark'] .auth-panel .select2-selection--single, html[data-theme='dark'] .auth-panel .input-group--white .select2-container .select2-selection--single, .input-group--white .select2-container html[data-theme='dark'] .auth-panel .select2-selection--single, html[data-theme='dark'] .auth-panel .input-group--append-lg .select2-container .select2-selection--single, .input-group--append-lg .select2-container html[data-theme='dark'] .auth-panel .select2-selection--single,
html[data-theme='dark'] .auth-panel .checkbox__marker,
html[data-theme='dark'] .auth-md-card--blur .input,
html[data-theme='dark'] .auth-md-card--blur .input-group .select2-container .select2-selection--single,
.input-group .select2-container html[data-theme='dark'] .auth-md-card--blur .select2-selection--single,
html[data-theme='dark'] .auth-md-card--blur .input-group--white .select2-container .select2-selection--single,
.input-group--white .select2-container html[data-theme='dark'] .auth-md-card--blur .select2-selection--single,
html[data-theme='dark'] .auth-md-card--blur .input-group--append-lg .select2-container .select2-selection--single,
.input-group--append-lg .select2-container html[data-theme='dark'] .auth-md-card--blur .select2-selection--single,
html[data-theme='dark'] .auth-md-card--blur .checkbox__marker {
  background-color: rgba(31, 41, 53, 0.1);
}

html[data-theme='dark'] .auth-panel .input:hover, html[data-theme='dark'] .auth-panel .input-group .select2-container .select2-selection--single:hover, .input-group .select2-container html[data-theme='dark'] .auth-panel .select2-selection--single:hover, html[data-theme='dark'] .auth-panel .input-group--white .select2-container .select2-selection--single:hover, .input-group--white .select2-container html[data-theme='dark'] .auth-panel .select2-selection--single:hover, html[data-theme='dark'] .auth-panel .input-group--append-lg .select2-container .select2-selection--single:hover, .input-group--append-lg .select2-container html[data-theme='dark'] .auth-panel .select2-selection--single:hover, html[data-theme='dark'] .auth-panel .input:focus, html[data-theme='dark'] .auth-panel .input-group .select2-container .select2-selection--single:focus, .input-group .select2-container html[data-theme='dark'] .auth-panel .select2-selection--single:focus, html[data-theme='dark'] .auth-panel .input-group--white .select2-container .select2-selection--single:focus, .input-group--white .select2-container html[data-theme='dark'] .auth-panel .select2-selection--single:focus, html[data-theme='dark'] .auth-panel .input-group--append-lg .select2-container .select2-selection--single:focus, .input-group--append-lg .select2-container html[data-theme='dark'] .auth-panel .select2-selection--single:focus,
html[data-theme='dark'] .auth-md-card--blur .input:hover,
html[data-theme='dark'] .auth-md-card--blur .input-group .select2-container .select2-selection--single:hover,
.input-group .select2-container html[data-theme='dark'] .auth-md-card--blur .select2-selection--single:hover,
html[data-theme='dark'] .auth-md-card--blur .input-group--white .select2-container .select2-selection--single:hover,
.input-group--white .select2-container html[data-theme='dark'] .auth-md-card--blur .select2-selection--single:hover,
html[data-theme='dark'] .auth-md-card--blur .input-group--append-lg .select2-container .select2-selection--single:hover,
.input-group--append-lg .select2-container html[data-theme='dark'] .auth-md-card--blur .select2-selection--single:hover,
html[data-theme='dark'] .auth-md-card--blur .input:focus,
html[data-theme='dark'] .auth-md-card--blur .input-group .select2-container .select2-selection--single:focus,
.input-group .select2-container html[data-theme='dark'] .auth-md-card--blur .select2-selection--single:focus,
html[data-theme='dark'] .auth-md-card--blur .input-group--white .select2-container .select2-selection--single:focus,
.input-group--white .select2-container html[data-theme='dark'] .auth-md-card--blur .select2-selection--single:focus,
html[data-theme='dark'] .auth-md-card--blur .input-group--append-lg .select2-container .select2-selection--single:focus,
.input-group--append-lg .select2-container html[data-theme='dark'] .auth-md-card--blur .select2-selection--single:focus {
  background-color: rgba(31, 41, 53, 0.3);
}

html[data-theme='dark'] .auth-md-card--blur::before {
  background-color: rgba(0, 0, 0, 0.1);
}

html[data-theme='dark'] .auth-md-card--blur .auth-md-card__line {
  border-color: transparent;
}

html[data-theme='dark'] .page-error {
  background: radial-gradient(circle at 50%, rgba(0, 55, 250, 0.32), rgba(0, 55, 250, 0.32) 50%, rgba(0, 55, 250, 0.16) 50%, rgba(0, 55, 250, 0.16) 66%, rgba(0, 55, 250, 0.08) 66%, rgba(0, 55, 250, 0.08) 82%, rgba(0, 55, 250, 0.04) 82%, rgba(0, 55, 250, 0.04) 100%);
}

html[data-theme='dark'] .page-error__type {
  text-shadow: 0px 8px 16px rgba(0, 55, 250, 0.3);
}
