/* Cart */

.cart {
    --cart-item-grid: 40% 30% 20% auto;
    --cart-item-gutter-x: 2.6667rem;
    padding: 0 0 1.3333rem;

    @include media('<lg') {
        --cart-item-gutter-x: 1.6rem;
    }

    @include media('<sm-tablet') {
        --cart-item-grid: repeat(3, 1fr);
    }

    @include media('<sm') {
        --cart-item-gutter-x: 1.1333rem;
        padding-top: 1.3333rem;
    }

    &__header {
        display: grid;
        grid-template-columns: var(--cart-item-grid);
        padding: 24px var(--cart-item-gutter-x) 1.8667rem;
        border-bottom: 1px solid var(--border-grey-color);

        @include media('<sm') {
            display: none;
        }
    }

    &__header-title {
        margin-bottom: 0;
        font-weight: 500;
        font-size: 1rem;
        line-height: 18/15*1em;
        text-transform: uppercase;

        &:first-child {
            padding-left: 44px;

            @include media('<lg-tablet') {
                padding-left: 0;
                text-align: center;
            }
        }
    }
}

.product-cart {
    display: grid;
    grid-template-columns: var(--cart-item-grid);
    padding: 17px var(--cart-item-gutter-x);
    background-color: var(--background-primary-color);
    border-bottom: 1px solid var(--border-grey-color);

    &--light {
        background-color: var(--background-secondary-color);
    }

    @include media('<sm') {
        &:first-child {
            border-top: 1px solid var(--border-grey-color);
        }
    }

    &__column {
        display: flex;
        align-items: center;
        justify-content: center;

        &--item-1 {
            @include media('<sm-tablet') {
                grid-column: span 4;
            }
        }
    }

    &__item {
        display: grid;
        grid-template-columns: repeat(1, 148px 1fr);
        width: 100%;

        @include media('<lg-tablet') {
            grid-template-columns: repeat(1, 1fr);
            justify-items: center;
        }

        @include media('>sm-tablet') {
            margin-right: auto;
        }
    }

    &__image {
        position: relative;
        width: 100%;
        min-height: 170px;
        background-image: url(../img/content/product/placeholder-thumbnail.jpg);
        background-size: cover;
        background-position: center center;
        overflow: hidden;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
            background-color: var(--white-color);
            object-position: 50% 50%;
        }
    }

    &__params {
        list-style: none;
        padding: 1.25rem 1.6rem;
        margin: 0;
        text-align: center;

        b {
            font-weight: 500;
        }

        .label {
            margin-top: 6px;
        }

        @include media('>sm-tablet') {
            text-align: left;
        }

        @include media('>lg-tablet') {
            padding: 0 1.0667rem 0 1.6rem;
        }
    }

    &__quantity {
        width: auto;

        .input {
            display: inline-block;
            width: auto;
            padding-right: 0;
            padding-left: 0;
            text-align: center;
        }
    }

    &__remove {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        transition: var(--t-base);

        @include media('>sm-tablet') {
            margin-left: auto;
        }

        svg[class*="icon"] {
            width: 22px;
            height: 22px;
        }

        &:hover {
            background-color: var(--control-background-hover-light);
        }
    }
}