/* Contacts */

.contacts-grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 6.6667rem 0;
    padding: 6rem 0 1.6rem;
    margin: auto;

    @include media('>sm-tablet') {
        grid-gap: 6.6667rem 2rem;
        max-width: 750px;
    }

    @include media('>lg') {
        max-width: 100%;
    }

    @include media('>2540px') {
        grid-template-columns: repeat(15, 1fr);
    }
}

.contact-card {
    grid-column: span 12;
    padding-top: 0;

    @include media('>sm-tablet') {
        grid-column: span 6;
    }

    @include media('>lg') {
        --card-gutter-x: 2rem;
        grid-column: span 4;
    }

    @include media('>1920px') {
        grid-column: span 3;
    }

    .card__tools-more {
        position: absolute;
        top: 1.0667rem;
        right: 1.0667rem;

        .dropdown-items {
            right: 0;
            transform: none;
        }
    }

    .card__body,
    .card__footer .card__container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .card__body {
        padding-bottom: 1.6rem;
    }

    &__avatar {
        position: relative;
        z-index: 1;
        display: flex;
        align-items: flex-start;
        width: 200px;
        height: 170px;
        margin-top: -4.6667rem;
        margin-bottom: 1.6rem;

        svg {
            width: 100%;
            height: 100%;
            min-height: 210px;
            margin-top: -4px;
            pointer-events: none;
        }
    }

    &__title {
        margin-bottom: 10/30*1em;
        font-weight: 500;
        font-size: 2rem;
        line-height: 35/30*1em;
    }

    &__address {
        display: grid;
        grid-gap: 16px;
        list-style: none;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    &__address-left {
        display: grid;
        align-items: center;
        grid-gap: 8px;
        grid-template-columns: repeat(1, 22px 1fr);

        svg[class*="icon"] {
            width: 22px;
            height: 22px;
            fill: var(--text-secondary-color);
        }
    }
}